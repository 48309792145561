import React, {useRef, useEffect} from "react";
import cx from 'classnames';
import classes from './HomePageOverlay.module.scss'
import HomePageButton from "./HomePageButton/HomePageButton";
import { isMobile } from 'react-device-detect';

const HomePageOverlay = (props: any) => {
    const refs = useRef<HTMLDivElement[]>([]);

    const resizeButtons = () => {
        let leftButton = document.getElementById('0') as HTMLElement;
        let rightButton = document.getElementById('1') as HTMLElement;
        let firstButtonHeight = refs.current[0].getBoundingClientRect().height;

        let secondButtonHeight = refs.current[1].getBoundingClientRect().height;

        let biggerHeight = firstButtonHeight > secondButtonHeight ? firstButtonHeight : secondButtonHeight;

        leftButton.style.height = String(biggerHeight);
        rightButton.style.height = String(biggerHeight);
    }

    useEffect(() => {

        if (window.location.pathname === ('/')) {
            let body = document.body;
            let main = document.getElementsByTagName('main')[0]
            !isMobile ? main.style.setProperty('margin-top', '110px'): main.style.setProperty('margin-top', '40px');
            body.style.setProperty('background', 'linear-gradient(179.78deg, #DD4C18 0.21%, rgba(0, 159, 218, 0) 99.83%), linear-gradient(359.61deg, #FED100 0.32%, rgba(254, 209, 0, 0) 99.68%), #FFFFFF');
        }


        if(!isMobile) {
            resizeButtons()
        }

    }, [])

    return(
        <div className="container">
            <div {...(!isMobile && {className: cx(classes.container ,"row justify-content-center")})}>
                {props.buttons.map((el: any, index: number) => (
                    <HomePageButton key={index} elementRef={(ref:HTMLDivElement) => refs.current.push(ref)} index={index} illustration={el.illustration} link={el.link} logo={el.logo} subTitle={el.subTitle} title={el.title}></HomePageButton>
                ))}
            </div>
        </div>
    )
}

export default HomePageOverlay;