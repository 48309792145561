import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { KidOverviewService } from '../KidOverview/services/KidOverviewService';
import KidsPreview from '../UI/KidsPreview/KidsPreview';
import classes from './KidsResult.module.scss';

const KidsResult = ({ totalAmountOfMoney, totalAmountOfOrders, illustration, addPaperSalesLink }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<{ amount: number; count: number }>();

  useEffect(() => {
    KidOverviewService.getOverview().then((result) => {
      if (result) {
        setOrders({
          amount: result.turnover,
          count: result.orders?.length,
        });
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <div className={classes.component}>
      <div className='container'>
        <KidsPreview
          isLoading={isLoading}
          total={orders?.amount}
          totalAmountOfMoney={totalAmountOfMoney}
          totalAmountOfOrders={totalAmountOfOrders}
          itemsLength={orders?.count}
          illustration={illustration.umbracoFile}
          salesLink={addPaperSalesLink}
          showArrowButton={true}
        />
      </div>
    </div>
  );
};

export default KidsResult;
