import React from 'react';
import cx from 'classnames';
import classes from './Input.module.scss';

const Input = ({ style, inputRef, maxLength=50, ...props}: any) => {
    return (
        <input
            className={cx(classes.input, style)}
            maxLength={maxLength}
            ref={inputRef}
            {...props}
        />
    )
}

export default Input
