import classes from './ModalInstance.module.scss';
import Modal from 'react-modal';

const ModalInstance = ({ closeModal, ...props }: any) => {
  return (
    <Modal
      {...props}
      overlayClassName={classes.overlay}
      className={classes.modal}
      bodyOpenClassName={classes.bodyOpenClassName}
      ariaHideApp={false}>
      <button onClick={closeModal} className={classes.modalClose}>
        <i className='icon-close'></i>
      </button>
      <div>{props.children}</div>
    </Modal>
  );
};

export default ModalInstance;
