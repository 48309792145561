import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export interface IKidOverview {
    code: string,
    name: string,
    orders: IKidOverviewItem[],
    turnover: number
}

export interface IKidOverviewItem {
    IsPaperOrder: boolean;
    agentCode: string;
    customerName: string;
    customerNameWithStars: string;
    totalOrders: number;
    turnover: number;
    id: number;
}

export interface IAddPaperOrder {
    personalCode: string;
    customer: string,
    profit: number,
}

export interface IEditPaperOrder extends IAddPaperOrder {
    id: string
}

export class KidOverviewService {

    static getOverview(): Promise<IKidOverview> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/student/getorders').then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static addPaperOrder(data: IAddPaperOrder): Promise<IKidOverview> {
        return new Promise((resolve, reject) => {
            AxiosInstance.post('/umbraco/api/student/addorder', data).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        })
    }

    static deleteOrder(id: number): Promise<IKidOverview> {
        return new Promise((resolve, reject) => {
            AxiosInstance.delete('/umbraco/api/student/deleteorder', { id }).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        })
    }

    static editOrder(data: IEditPaperOrder): Promise<IKidOverview> {
        return new Promise((resolve, reject) => {
            AxiosInstance.post('/umbraco/api/student/updateorder', data).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        })
    }
}