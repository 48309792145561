import React, { useRef } from 'react';

import classes from  './ParagraphWithCards.module.scss';

import Heading from '../UI/Heading/Heading';
import Card from '../UI/Card/Card';
import Button from '../UI/Button/Button';
import Blob from '../UI/Blob/Blob';
import GtmLink from '../UI/GtmLink/GtmLink';


import cx from 'classnames'; 

type ParagraphWithCards = {
    text: string,
    styling: string,
    icon: string,
}


function ParagraphWithCards(props: any) {
    const textRef = useRef(null);

    const getImages = (index: number) => {
        let images = null;

        if (props.images && props.images.length !== 0 && props.images[index]) {
            images = props.images[index]; 
        }

        return images;
    }

    let numberOfCards = props.straightCardItem.length;
    let cards = props.straightCardItem.map((card: any, index: number) => {
        const images = getImages(index);

        return (
            <div key={index} className={cx(classes['card-wrapper'], numberOfCards === 3 ? "col-lg-4" : "col-lg-6" )}>
                <Card 
                    threeCars={numberOfCards === 3} 
                    styling={cx({'card--without-image': !images})} 
                    link={card.Link ? card.Link.Url: ''} 
                    heading={card.header} 
                    paragraph={card.body} 
                    butonText={card.Link ? card.Link.Name: ''} 
                    target={card.Link ? card.Link.Target: ''} 
                    image={images}  
                    type="buttonWithLink"></Card>
            </div>
            )
    })

  return (
      <React.Fragment> 
        <div id={props.linkId} className={classes.lightRedBackground}>
            <div className="container position-relative">
                <Blob className={classes.blob} type='paragraphWithCards'></Blob>
                <div className={classes['paragraph-with-cards-content']}>
                <img className={classes.illustration} src={props.headerIllustration?.umbracoFile}></img>
                    <div className="row justify-content-lg-center position-relative">
                        <div className="col-lg-8 cold-md-12">
                            <Heading className={classes.lightPink} text={props.header} type="heading-2" backgroundColor={props.backgroundRectangleColor.Label} colorOfText="white" isSkewed={true} ></Heading>
                            <div ref={textRef} className={classes['paragraph-with-cards-paragraph']} dangerouslySetInnerHTML={{__html:props.text}}/>
                            {props.button ? <GtmLink gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={props.button.Name} href={props.button.Url}>
                                <Button text={props.button.Name} styling="" icon="arrow" isDisabled={false}></Button> 
                            </GtmLink>: null}
                            <img src={props.rowIllustration?.umbracoFile} className={cx(classes.rowIllustration, 'd-lg-none')} />
                        </div>
                    </div>
                    <div className={cx(classes['paragraph-with-cards-cards'], "row")}>
                        {cards}
                    </div>
                </div>
                <img src={props.rowIllustration?.umbracoFile} className={cx(classes.rowIllustration, 'd-none d-lg-block')} />
            </div>
        </div>
    </React.Fragment>
    )
}

export default ParagraphWithCards;
