import Heading from '../UI/Heading/Heading';
import Button from '../UI/Button/Button';
import List from './List/List';

import classes from './SideBlockParagraph.module.scss';
import Blob from '../UI/Blob/Blob';
import cx from 'classnames';
import GtmLink from '../UI/GtmLink/GtmLink';

function SideBlockPragraph(props: any) {
  let typeOfAlignment = '';

  switch (props.sideblockView) {
    case 'Ordered list':
      typeOfAlignment = 'orderedList';
      break;
    case 'Unordered list':
      typeOfAlignment = 'unorderedList';
      break;
    case 'Link list':
      typeOfAlignment = 'linkList';
      break;
    case 'Image only':
      typeOfAlignment = 'imageOnly';
      break;
    default:
      typeOfAlignment = '';
  }

  return (
    <div className={classes.lightRedBackground} id={props.linkId}>
      <div className='container position-relative'>
        {typeOfAlignment && <Blob className={classes.blob} type='paragraphWithSideBlock'></Blob>}
        <div className='row'>
          <div className={typeOfAlignment ? 'col-lg-6 col-md-6' : 'col-lg-10 col-md-10'}>
            <div className={classes['sideblock-paragraph-wrapper']}>
              <Heading
                text={props.header}
                type='heading-2'
                backgroundColor={props.backgroundRectangleColor.Label}
                colorOfText='white'
                isSkewed={true}></Heading>
              <div
                className={classes['sideblock-paragraph-paragraph']}
                dangerouslySetInnerHTML={{ __html: props.text }}
              />
              {props.button && (
                <GtmLink
                  gtmCategory='User Interaction'
                  gtmAction='CTA'
                  gtmLabel={props.button.Name}
                  href={props.button.Url}
                  target={props.button.Target}>
                  <Button text={props.button.Name} styling='' icon='arrow' isDisabled={false}></Button>
                </GtmLink>
              )}
            </div>
          </div>
          {typeOfAlignment ? (
            <div className='col-lg-6 col-md-12'>
              <div
                className={cx(
                  typeOfAlignment === 'imageOnly'
                    ? classes['sideblock-paragraph-list-image']
                    : classes['sideblock-paragraph-list']
                )}>
                <List
                  type={typeOfAlignment}
                  linkListHeader={props.linkListHeader}
                  items={typeOfAlignment === 'linkList' ? props.linkListItems : props.listItems}
                  image={props.sideblockImage}
                  sideblockLink={props.sideblockButtonLink}
                  button={props.button}
                  illustration={props.headerIllustration}></List>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default SideBlockPragraph;
