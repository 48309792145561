import cx from 'classnames';
import Button from '../../UI/Button/Button';
import GtmLink from '../../UI/GtmLink/GtmLink';
import StepCardHeading from '../StepCardHeading/StepCardHeading';
import classes from './StepCard.module.scss';

interface StepCardProps {
  anchorTitle: string;
  button: {
    Name: string;
    Target: null;
    Type: number;
    Udi: string;
    Url: string;
  };
  cardImage: {
    umbracoFile: string;
    umbracoWidth: number;
    umbracoHeight: number;
    umbracoBytes: number;
    umbracoExtension: string;
  } | null;
  description: string;
  subtitle: string;
  title: string;
}
export const StepCard = (props: StepCardProps) => {
  return (
    <div className={cx(classes.card)} id={props.anchorTitle}>
      {props.cardImage && (
        <div className={cx(classes['card-image-wrapper'])}>
          <img className={classes['card-image']} src={props?.cardImage?.umbracoFile} alt={''} />
        </div>
      )}
      <div
        className={cx(classes['card-content'], {
          [classes.rounded]: !props?.cardImage,
        })}>
        <StepCardHeading
          isWithPicture={props?.cardImage}
          title={props?.title}
          optionalSubtitle={props?.subtitle}></StepCardHeading>
        <div className={classes['card-paragraph']} dangerouslySetInnerHTML={{ __html: props.description }}></div>
        {props.button && (
          <div className='d-flex'>
            <GtmLink
              gtmCategory='User Interaction'
              gtmAction='CTA'
              gtmLabel={props.button.Name}
              className={classes.link}
              href={props.button.Url}>
              <div className={classes['card-button-left']}>
                <Button text='' styling='noTextArrowDarkOrange' icon='' isDisabled={false}></Button>
                <span className={cx(classes['card-link'], 'color-dark-orange')}>{props.button.Name}</span>
              </div>
            </GtmLink>
          </div>
        )}
      </div>
    </div>
  );
};
