import classes from './FunnelWrapper.module.scss';

import Heading from '../UI/Heading/Heading';
import Blob from '../UI/Blob/Blob';

import USP from './USP/USP';
import StepOne from './Shared/StepOne/StepOne';
import TeacherEnd from './Teacher/TeacherEnd/TeacherEnd';
import StepTwoKid from './Kids/StepTwoKid/StepTwoKid';
import StepThreeKid from './Kids/StepThreeKid/StepThreeKid';
import Steps from './Steps/Steps';
import GtmLink from '../UI/GtmLink/GtmLink';

import cx from 'classnames';

function FunnelWrapper(props:any) {
  let currentStep = props.steps.findIndex((element: any) => element.value==props.step) + 1;
  const isTeacher = props.numberOfAllLoginSteps == 2;
  return (
      <div className={classes.Wrapper}>
        <div className="container">
          <Steps allSteps={props.numberOfAllLoginSteps} stepsNames={props.steps} activeStep={currentStep}></Steps>
          <div className={cx("row", classes.Content)}>
            <div className="col-lg-6 cold-md-12">
              <div className={classes.Card}>
                <img className={classes.Illustration} src={props.illustration.umbracoFile}></img>
                <div className={classes.CardContent}>
                  <div className={cx(classes.HeaderWrapper, 'position-relative')}>
                    <Blob className={classes.blobs} type='loginBlob'></Blob>
                    <div className={classes.SubHeader}>
                      <Heading className={cx(classes.headingPadding, classes.noneBoxShadow)} text={props.subHeader} type="heading-4" backgroundColor="dark-orange" colorOfText="white" isSkewed={true} ></Heading>
                    </div>
                    <Heading className={classes.headingPadding} text={props.header} type="heading-2" backgroundColor="white" colorOfText="dark-orange" isSkewed={true} ></Heading>
                  </div>
                  {props.step === "Mijn school" ?
                   <StepOne nextStep={props.nextStep} isTeacher={isTeacher} url={props.webapiendpoint} button={props.buttonLink} autoCompleteDropDown={props.dropdown1Label} dropDownDescription={props.dropdown2Description} dropDownLabel={props.dropdown2Label} dropdownPlaceholder1={props.dropdown1Placeholder} dropdownPlaceholder2={props.dropdown2Placeholder}></StepOne> : 
                   props.step === "Mijn groep" && isTeacher ? <TeacherEnd  nextStep={props.nextStep} url={props.webapiendpoint} errorMessage={props.loginErrorMessage} button={props.buttonLink} autoCompleteDropDown={props.dropdown1Label} dropDownDescription={props.dropdown2Description} dropDownLabel={props.dropdown2Label} keepSignedInLabel={props?.keepSignedInLabel}></TeacherEnd> :
                   props.step === "Mijn groep" ? <StepTwoKid nextStep={props.nextStep} autoCompleteDropDown={props.dropdown1Label} url={props.webapiendpoint}  button={props.buttonLink}></StepTwoKid> : <StepThreeKid  nextStep={props.nextStep} url={props.webapiendpoint} errorMessage={props.loginErrorMessage}  button={props.buttonLink} autoCompleteDropDown={props.dropdown1Label} dropDownDescription={props.dropdown2Description} dropDownLabel={props.dropdown2Label} keepSignedInLabel={props?.keepSignedInLabel} ></StepThreeKid>}
                </div>
              </div>
              <div className={classes.linkWrapper}><GtmLink href={props.link.Url} className={classes.linkForLogin} gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={props.link.Name}>{props.link.Name}</GtmLink></div>
            </div>
            <div className="col-lg-4 cold-md-12 d-lg-flex align-items-center"> <USP data={props.uSPBlock}></USP></div>
          </div>
        </div>
      </div>
    ) 
}

export default FunnelWrapper;
