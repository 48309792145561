import {useEffect, useState, useRef} from 'react';
import React from 'react';
import classes from  './GeneralPermission.module.scss';
import Button from '../../UI/Button/Button';
import Blob from '../../UI/Blob/Blob';
import { Status, VideoUploadService  } from '../services/VideoUploadService';
import { useForm } from 'react-hook-form';
import GtmLink from '../../UI/GtmLink/GtmLink';

function GeneralPermission(props: any) {
  const { handleSubmit, formState: { errors }, register } = useForm();
  const [typeOfComponent, setTypeOfComponent]=useState<any>();
  const [waitingForApproval, setWaitingForApproval]=useState<string>("");
  const [shouldInputBeVisible, setSholudInputBeVisible]=useState<boolean>(true);
  const [buttonLoading, setButtonLoading]=useState<boolean>(false);
  const [email, setEmail]=useState<string>("");

  useEffect(()=>{
    VideoUploadService.getStatus().then((response:any )=>{
      if(parseInt(Status[response])===1){
        VideoUploadService.getEmail().then(response=> {
          const newText = props.waitingForApprovalResultText.replace("<parentemail>", response)
          setWaitingForApproval(newText);
          setEmail(response);
        })
      }
      setTypeOfComponent(Status[response]);
      
    })
  }, [])

  const onInputChange = (event: any) => {
    setEmail(event.target.value);
  }

  const sendEmailHandler = (data: any) => {
      setButtonLoading(true);
      const object = {
        email: data.email
      }
      VideoUploadService.sendEmail(object).then((response)=>{
        const newText = props.waitingForApprovalResultText.replace("<parentemail>", data.email)
        setWaitingForApproval(newText);
        setTypeOfComponent(Status["mailSend"]);
        setSholudInputBeVisible(false);
        setButtonLoading(false);

        const gtmObject: any = {
          'event': 'User Interaction',
          'category': 'User Interaction',
          'action': 'CTA',
          'label': props.sendButton,
        };
    
        (window as any).dataLayer.push(gtmObject);
      })
  }
  return (
    <div className={classes.wrapper}>
        <Blob className={classes.blob} type='video-upload'></Blob>
        <div className={classes.title}>{props.title}</div>
        <div className={classes.content} dangerouslySetInnerHTML={{ __html: props.text }}></div>
        {((typeOfComponent===0 || typeOfComponent===1) && shouldInputBeVisible) && 
         <form onSubmit={handleSubmit(result => sendEmailHandler(result))}>
            <div className={classes.emailForm}>
            <div className={classes.inputWrapper}>
              <input
                value={email}
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,24}$/
                })}  
                className={classes.input} 
                onChange={(event) => onInputChange(event)}
                placeholder={props.eMailPlaceholder}>
              </input>
            </div>
              <Button  text={props.sendButton}  styling="" icon="" isDisabled={buttonLoading} isLoading={buttonLoading}></Button>
          </div>
          {(errors.email?.type === 'required' || errors.email?.type === 'pattern') && <div className={classes.error}>{props.errorMessageEmail}</div>}
        </form>}
        {typeOfComponent===1 &&
          <div className={classes.wrapperEmail}>
          <div className={classes.mailSend}>
            <div className={classes.mailContent}>
              <div className={classes.mailTitle}>{props.waitingForApprovalResultTitle}</div>
              <div className={classes.mailParagraph}>{waitingForApproval}</div>
            </div>
          </div>
          <div className={classes.sendEmailAgain} dangerouslySetInnerHTML={{ __html: props.sendEmailAgainText }}></div>
          </div>
        }
        {typeOfComponent===2 &&
          <React.Fragment>
          <div className={classes.approved}>
            <div className={classes.mailContent}>
              <div className={classes.mailTitle}>{props.recordingIsApprovedResultTitle}</div>
              <div className={classes.mailParagraph}>{props.recordingIsApprovedResultText}</div>
            </div>
          </div>
          <GtmLink href={props.nextButton.Url} gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={props.nextButton.Name}>
            <Button text={props.nextButton.Name}  styling="" icon="arrow" isDisabled={false}></Button>
          </GtmLink>
          </React.Fragment>
        }
        {(typeOfComponent===3 || typeOfComponent===4) &&
        <React.Fragment>
          <div className={classes.rejected}>
            <div className={classes.mailContent}>
              <div className={classes.mailTitle}>{props.recordingIsForbiddenResultTitle}</div>
              <div className={classes.mailParagraph}>{props.recordingIsForbiddenResultText}</div>
            </div>
          </div>
          <GtmLink gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={props.dashboardButton.Name} href={props.dashboardButton.Url}><Button text={props.dashboardButton.Name}  styling="" icon="arrow" isDisabled={false}></Button></GtmLink>
        </React.Fragment>
        }
        
    </div>
  );
}

export default GeneralPermission;
