import React, { useEffect, useState, Fragment } from 'react';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';
import { FcInfo } from 'react-icons/fc';
import classes from './KidsNavigation.module.scss';
import DropDown from '../UI/DropDown/DropDown';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import Button from '../UI/Button/Button';
import { NickNameService } from './services/NickNameService';
import { UserServices } from '../Navigation/services/UserServices';
import Loader from '../UI/Loader/Loader';
import GtmLink from '../UI/GtmLink/GtmLink';
import ReactTooltip from 'react-tooltip';
import { AxiosError } from 'axios';
import { PopupService } from '../Popup/PopupService';

const KidsNavigation = ({
  title,
  links,
  claims,
  editNickname,
  editNicknameButton,
  missingNickName,
  nicknameInformation,
}: any) => {
  const [activeLink, setActiveLink] = useState<any>();
  const [userName, setUserName] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(editNickname);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm();
  const [formValue, setFormValue] = useState<any>();

  useEffect(() => {
    let active = {};

    if (typeof window !== 'undefined') {
      const path = window.location.pathname;

      links.some((link: any) => {
        if (link.link.Url === path || (link.link.Url.slice(-1) === '/' && path === link.link.Url.slice(0, -1))) {
          active = { label: link.link.Name, value: link.link.Url };
        }

        return false;
      });
    }

    setActiveLink(active);
  }, [links, setActiveLink]);

  useEffect(() => {
    if (editNickname) {
      UserServices.getUser().then((result) => {
        if (!result.nickName) {
          startEdit();
          setIsLoading(false);
          return;
        }

        setUserName(result.nickName);
        reset({
          userName: result.nickName,
        });

        setIsLoading(false);
      });
    }
  }, []);

  const onChangeMenu = (value: any) => {
    const url = window.location.pathname.split('/').pop();
    if (url === 'dashboard' && !userName) {
      PopupService.open('1');
    } else {
      window.location.href = value;
    }
  };

  const startEdit = () => {
    setEditMode(true);
  };

  const openModal = () => {
    PopupService.open('1');
  };

  const openConfirmationPopup = (value: any) => {
    setFormValue(value);
    setIsModalOpened(true);
    PopupService.open('2');
  };

  const submit = (value: any) => {
    NickNameService.setNickName(value.userName)
      .then((result: string) => {
        setEditMode(false);
        setUserName(result);
        UserServices.setUserNickName(result);
        const gtmObject: any = {
          event: 'Portal',
          category: 'Portal',
          action: 'Edit nickname',
        };

        (window as any).dataLayer.push(gtmObject);
      })
      .catch((error: AxiosError) => {
        var inputErrorElement = document.getElementById('input_error_message');

        if (inputErrorElement != null) {
          if (typeof error.response?.data === 'string') {
            inputErrorElement.innerText = error.response?.data;
          } else {
            inputErrorElement.innerText = 'Er is iets fout gegaan.';
          }
        }
      });
  };

  useEffect(() => {
    if (isModalOpened) {
      const interval = setInterval(() => {
        const newNickNameConfirmed = localStorage.getItem('kpz-nickname-confirm');
        if (newNickNameConfirmed) {
          setIsModalOpened(false);
          submit(formValue);
          localStorage.removeItem('kpz-nickname-confirm');
        }
      }, 250);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isModalOpened]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className='container'>
      <div className={classes.header}>
        <OverviewHeading title={title} titleClassName={classes.title} />
      </div>
      <div className={classes.navigation}>
        {!editMode && activeLink && !editNickname && <h2 className={classes.navigationTitle}>{activeLink.label}</h2>}
        {!isLoading && editNickname && (
          <div className={classes.userNameContainer}>
            {!editMode && (
              <Fragment>
                {nicknameInformation && (
                  <div className={classes.tooltip}>
                    <FcInfo data-tip={nicknameInformation} className={classes.infoIcon} />
                    <ReactTooltip />
                  </div>
                )}
                <h2 className={classes.navigationTitle}>{userName}</h2>
                <div className={classes.editButton} onClick={startEdit}>
                  <i className='icon-edit'></i>
                </div>
              </Fragment>
            )}
            {editMode && (
              <form className={classes.removeMargin} onSubmit={handleSubmit(openConfirmationPopup)}>
                <div className={classes.form}>
                  {nicknameInformation && (
                    <div className={classes.tooltip}>
                      <FcInfo data-tip={nicknameInformation} className={classes.infoIcon} />
                      <ReactTooltip />
                    </div>
                  )}
                  <div>
                    <input
                      placeholder={missingNickName}
                      className={classes.input}
                      type='text'
                      maxLength={30}
                      {...register('userName', { required: true })}
                    />
                    <div id='input_error_message' className={classes.inputErrorMessage}></div>
                  </div>
                  <Button className={classes.button} isDisabled={false} text={editNicknameButton} />
                </div>
              </form>
            )}
          </div>
        )}
        <div className={cx('d-lg-none')}>
          <DropDown
            type='kidsNavigation'
            isSearchable={false}
            options={links.map((l: any) => ({
              label: l.link.Name,
              value: l.link.Url,
            }))}
            onChange={(e: any) => onChangeMenu(e.value)}
            value={activeLink}
          />
        </div>
        <div className={classes.links}>
          {links.map((nav: any, index: number) => (
            <GtmLink
              isNickNameInputted={userName ? true : false}
              clickHandler={openModal}
              gtmCategory='User Interaction'
              gtmAction='CTA'
              gtmLabel={nav.link.Name}
              key={index}
              className={cx(classes.link, {
                [classes.active]: activeLink && nav.link.Url === activeLink.value,
              })}
              href={nav.link.Url}
              target={nav.link.Target}>
              {nav.link.Name}
            </GtmLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KidsNavigation;
