import React from 'react';
import classes from './ActionButton.module.scss';
import cx from 'classnames';

const ActionButton = ({ children, type, ...props }: any) => {
    return (
        <button className={cx(classes.actionButton, { [classes.edit]: type === 'edit', [classes.delete]: type === 'delete' })} {...props}>
            {children}
        </button>
    )
}

export default ActionButton
