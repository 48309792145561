import { useState } from 'react';
import Modal from '../Modal/Modal';
import { PopupService } from './PopupService';

type PopupType = {
  ComponentId?: string;
  body?: string;
  homeButton?: any;
  illustration?: any;
  title?: string;
};

const Popup = (props: any) => {
  const [open, setOpen] = useState<boolean>();
  const popupArray: PopupType[] = Object.values(props);
  const [openPopup, setOpenPopup] = useState<PopupType>();

  PopupService.onOpen((popupId: string) => {
    const popup = popupArray?.find((popup) => popup.ComponentId === popupId);
    if (popup) {
      setOpen(true);
      setOpenPopup(popup);
    }
  });

  const closeModal = () => {
    setOpen(false);
    setOpenPopup({});
  };

  return openPopup?.ComponentId ? (
    <Modal
      componentId={openPopup.ComponentId}
      closeModal={closeModal}
      isOpen={open}
      body={openPopup.body}
      title={openPopup.title}
      illustration={openPopup.illustration}
      homeButton={openPopup.homeButton}></Modal>
  ) : null;
};

export default Popup;
