import React, { useEffect, useRef } from 'react';
import classes from './OrderInput.module.scss';
import cx from 'classnames';
import NumberFormat from 'react-number-format';
import { useController } from 'react-hook-form';
import Input from '../../UI/Input/Input';

const OrderInput = ({
    label,
    inputClass,
    withFormating,
    name,
    control,
    defaultValue,
    rules,
    selectOnClick,
    ...props
}: any) => {
    const inputRef = useRef<any>(null);

    const {
        field: { ref, onChange, ...inputProps }
    } = useController({
        name,
        control,
        defaultValue: defaultValue,
        rules: rules
    })

    const markOnFocus = (event: Event) => {
        if (event.target !== null && (event.target as any).value === defaultValue.toString()) {
            (event.target as any).select();
            return;
        }

        if (selectOnClick) {
            (event.target as any).select();
        }
    }

    useEffect(() => {
        inputRef.current?.addEventListener('focus', markOnFocus)

        return () => {
            inputRef.current?.removeEventListener('focus', markOnFocus);
        }
    }, [inputRef])

    return (
        <div>
            {
                label &&
                <label className={classes.label}>{label}</label>
            }
            <div className={inputClass}>
                {
                    withFormating ?
                        <NumberFormat
                            {...inputProps}
                            className={cx(classes.input)} 
                            {...props}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            getInputRef={(e: HTMLInputElement) => {
                                ref(e);
                                inputRef.current = e;
                            }}
                            onValueChange={(values) => {
                                onChange(values.floatValue)
                            }}
                        /> :
                        <Input
                            inputRef={(e: any) => {
                                ref(e);
                                inputRef.current = e;
                            }}
                            onChange={onChange}
                            {...inputProps}
                            {...props} 
                        />
                }
            </div>
        </div>
    )
}

export default OrderInput
