import React, { useEffect, useState } from 'react';
import { PersonalVideoWidgetService, ChildStatuses, VideoStatuses } from './services/PersonalVideoWidgetService';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';
import classes from './PersonalVideoWidget.module.scss';
import Button from '../UI/Button/Button';
import Loader from '../UI/Loader/Loader';
import GtmLink from '../UI/GtmLink/GtmLink';

const PersonalVideoWidget = ({
    title,
    ...props
}: any) => {
    const [childStatusContent, setChildStatusContent] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getContent = (value: string) => {
        const contentArray = props[value];

        if (contentArray && contentArray.length !== 0) {
            return contentArray[0];
        }

        return {};
    }

    const setContnet = (result: ChildStatuses) => {
        switch (result) {
            case ChildStatuses.mailNontSend:
                setChildStatusContent(getContent('startStatus'));
                break;
            case ChildStatuses.mailSend:
                setChildStatusContent(getContent('waitingForGeneralPermission'));
                break;
            case ChildStatuses.approved:
                setChildStatusContent(getContent('generalPermissionGotten'));
                break;
            case ChildStatuses.rejected:
                setChildStatusContent(getContent('generalPermissionRejected'));
                break;
            case ChildStatuses.blocked:
                setChildStatusContent(getContent('videoHasBeenBlockedByAModerator'));
                break;
            default:
                setChildStatusContent(null);
                break;
        }
    }

    const setVideoContent = (result: VideoStatuses) => {
        switch (result) {
            case VideoStatuses.uploaded:
                setChildStatusContent(getContent('waitingForApprovalOfAPersonalVideo'));
                break;
            case VideoStatuses.approved:
                setChildStatusContent(getContent('aVideoIsApproved'));
                break;
            case VideoStatuses.rejected:
                setChildStatusContent(getContent('aVideoIsRejected'));
                break;
            case VideoStatuses.reported:
            case VideoStatuses.rejectedByModerator:
                setChildStatusContent(getContent('videoHasBeenMarkedAsInappropriate'));
                break;
            default:
                setChildStatusContent(null);
                break;
        }
    }

    useEffect(() => {
        PersonalVideoWidgetService.getChildStatus().then(result => {
            if (result === ChildStatuses.approved) {
                Promise.all([PersonalVideoWidgetService.GetVideoStatus('Welcome'), PersonalVideoWidgetService.GetVideoStatus('ThankYou')]).then(([welcome, thankYou]) => {
                    switch (true) {
                        //Rejected
                        case welcome === VideoStatuses.rejected || thankYou === VideoStatuses.rejected:
                            setVideoContent(VideoStatuses.rejected);
                            break;

                        //NotSet
                        case welcome === VideoStatuses.uploaded && thankYou === VideoStatuses.uploaded:
                            setContnet(ChildStatuses.approved);
                            break;

                        //Approved
                        case welcome === VideoStatuses.approved && thankYou === VideoStatuses.approved:
                            setVideoContent(VideoStatuses.approved);
                            break;

                        //NotSet and Approved
                        case welcome === VideoStatuses.uploaded && thankYou === VideoStatuses.approved:
                            setVideoContent(VideoStatuses.uploaded);
                            break;
                        case welcome === VideoStatuses.approved && thankYou === VideoStatuses.uploaded:
                            setVideoContent(VideoStatuses.uploaded);
                            break;

                        //Approved and Notuploaded
                        case welcome === VideoStatuses.approved && thankYou === VideoStatuses.notuploaded:
                            setVideoContent(VideoStatuses.approved);
                            break;
                        case welcome === VideoStatuses.notuploaded && thankYou === VideoStatuses.approved:
                            setVideoContent(VideoStatuses.approved);
                            break;

                        //Notuploaded
                        case welcome === VideoStatuses.notuploaded && thankYou === VideoStatuses.notuploaded:
                            setContnet(ChildStatuses.approved);
                            break;

                        //Notuploaded and Uploaded
                        case welcome === VideoStatuses.notuploaded && thankYou === VideoStatuses.uploaded:
                            setVideoContent(VideoStatuses.uploaded);
                            break;
                        case welcome === VideoStatuses.uploaded && thankYou === VideoStatuses.notuploaded:
                            setVideoContent(VideoStatuses.uploaded);
                            break;

                        case welcome === VideoStatuses.rejectedByModerator || thankYou === VideoStatuses.rejectedByModerator:
                            setVideoContent(VideoStatuses.rejectedByModerator);
                            break;

                        case welcome === VideoStatuses.reported || thankYou === VideoStatuses.reported:
                            setVideoContent(VideoStatuses.reported);
                            break;

                        default:
                            setChildStatusContent(null);
                            break;
                    };

                    setIsLoading(false)
                }, () => setIsLoading(false))
            } else {
                setContnet(result);
                setIsLoading(false)
            }
        }, () => setIsLoading(false))
    }, [])

    return (
        <div className='container'>
            <div className={classes.component}>
                <div className={classes.titleContainer}>
                    <OverviewHeading title={title} titleClassName={classes.title} />
                </div>
                {
                    isLoading ?
                        <Loader /> :
                        <div className='row'>
                            <div className='offset-lg-1 col-lg-10'>
                                {
                                    childStatusContent &&
                                    <div className={classes.panel}>
                                        <div className={classes.panelRow}>
                                            <div className={classes.panelColumn}>
                                                <div className={classes.imageWrapper}>
                                                    <img className={classes.image} src={childStatusContent?.image?.umbracoFile} alt='images' />
                                                </div>
                                            </div>
                                            <div className={classes.panelColumn}>
                                                <h2 className={classes.panelColumnTitle}>{childStatusContent.title}</h2>
                                                <div className={classes.text} dangerouslySetInnerHTML={{ __html: childStatusContent.text }}></div>
                                                <GtmLink gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={childStatusContent.link.Name} href={childStatusContent.link.Url}>
                                                    <Button text={childStatusContent.link.Name} isDisabled={false} icon='arrow' />
                                                </GtmLink>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default PersonalVideoWidget
