import React, { useEffect, useState } from "react";

const MoveDragThreshold = 10;

export const useDragDetection = (): {
    handleMouseDown: () => void;
    dragging: boolean;
} => {
    const [mouseDown, setMouseDown] = useState(false);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        let mouseMove = 0;

        const handleMouseUp = (): void => {
            setMouseDown(false);
        }

        const handleMouseMove = (e: MouseEvent): void => {
            mouseMove += Math.abs(e.movementX) + Math.abs(e.movementY);
            setDragging(mouseMove > MoveDragThreshold);
        }

        if (mouseDown) {
            document.addEventListener("mouseup", handleMouseUp);
            document.addEventListener("mousemove", handleMouseMove);
        }

        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, [mouseDown]);

    const handleMouseDown = (): void => {
        setMouseDown(true);
        setDragging(false);
    }

    return {
        handleMouseDown,
        dragging,
    };
}