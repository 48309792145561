import React from 'react';
import classes from  './Introduction.module.scss';
import Button from '../../UI/Button/Button';
import Blob from '../../UI/Blob/Blob';
import GtmLink from '../../UI/GtmLink/GtmLink';

function Introduction(props: any) {
  return (
    <div className={classes.wrapper}>
      <Blob className={classes.blob} type='video-upload'></Blob>
      <div className={classes.title}>{props.title}</div>
      <div className={classes.content} dangerouslySetInnerHTML={{ __html: props.text }}></div>
      <GtmLink gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={props.startVideoRecordingButton}  href={props.nextStep.Url}>
        <Button text={props.startVideoRecordingButton} styling="" icon="arrow" isDisabled={false}></Button>
      </GtmLink>
    </div>
  );
}

export default Introduction;
