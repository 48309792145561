import React, { useState, useRef, useEffect } from 'react';
import classes from './VideoCarousel.module.scss';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDragDetection } from './useDragDetection';
import cx from 'classnames';
import Blob from '../UI/Blob/Blob';
import YouTube from 'react-youtube';
import Vimeo from '../UI/Vimeo/Vimeo';

const VideoCarousel = ({
    title,
    text,
    videos,
    images,
    selectedLabel,
    illustration,
    illustrationBottom
}: any) => {
    const [selectedVideo, setSelectedVideo] = useState(0);
    const slider = useRef<any>(null);

    const {
        handleMouseDown,
        dragging,
    } = useDragDetection();

    useEffect(() => {
        const setActiveSlide = () => {
            if (window.outerWidth <= 480) {
                slider.current.slickGoTo(0, false);
            } else {
                slider.current.slickGoTo(1, false);
            }
        }

        setActiveSlide();

        window.addEventListener('resize', setActiveSlide)

        return () => {
            window.removeEventListener('resize', setActiveSlide)
        }
    }, [])

    const getSettings = (count: number) => {
        let slidesToShow = 0;
        switch (count) {
            case 1:
                slidesToShow = 1;
                break;
            case 2:
                slidesToShow = 2;
                break;
            case 3:
                slidesToShow = 3;
                break;
            default:
                slidesToShow = 4;
                break;
        }

        const setting = {
            centerMode: true,
            infinite: true,
            centerPadding: "220px",
            slidesToShow,
            speed: 500,
            arrows: false,
            initialSlide: count === 1 ? 0 : 1,
            variableWidth: true,
            responsive: [{
                breakpoint: 2000,
                settings: {
                    slidesToShow: count === 1 ? 1 : count === 2 ? 2 : 3,
                }
            }, {
                breakpoint: 1700,
                settings: {
                    slidesToShow: count === 1 ? 1 : 2,
                    variableWidth: false,
                }
            }, {
                breakpoint: 1024,
                settings: {
                    centerPadding: "50px",
                    slidesToShow: count === 1 ? 1 : 2,
                    variableWidth: false,
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: count === 1 ? 1 : 1.5,
                    infinite: false,
                    centerMode: false,
                    variableWidth: false,
                }
            }]
        }

        return setting;
    };

    const handleChildClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, video: any): void => {
        if (dragging) {
            e.preventDefault();
            return;
        }

        setSelectedVideo(video)
    }

    const next = () => {
        slider.current.slickNext();
    }

    const previous = () => {
        slider.current.slickPrev();
    }

    const videoPlay = (title : string) => {
        const gtmObject: any = {
            'event': 'Video',
            'category': 'Video',
            'action': 'Start video',
            'label': title
        };

        if ((window as any).dataLayer) {
            (window as any).dataLayer.push(gtmObject);
        }
    }

    let settings = getSettings(videos.length);
    return (
        <div>
            <div className='container position-relative'>
                {
                    illustration && illustration.umbracoFile &&
                    <img className={classes.illustration} src={illustration.umbracoFile} />
                }
                <div className='row'>
                    <div className='col-lg-6 offset-lg-1'>
                        {
                            title &&
                            <div className={classes.titleWrapper}>
                                <OverviewHeading title={title} titleClassName={classes.title} />
                            </div>
                        }
                        {
                            text &&
                            <div className={classes.text}>{text}</div>
                        }
                    </div>
                </div>
                <div className={classes.videoWrapper}>
                    <Blob type='video-carousel' className={classes.blob} />
                    <div className='embed-responsive embed-responsive-16by9'>
                        {
                            videos[selectedVideo].videoType == "Vimeo" ?
                            <Vimeo 
                                className="embed-responsive-item"
                                video={videos[selectedVideo].embedCode}
                                onPlay={() => videoPlay(videos[selectedVideo].title)}
                            /> :
                            <YouTube videoId={videos[selectedVideo].embedCode} className="embed-responsive-item" onPlay={() => videoPlay(videos[selectedVideo].title)} />
                        }
                    </div>
                    {
                        videos[selectedVideo].title &&
                        <div className={classes.videoTitlePosition}>
                            <div className={classes.selectedVideoTitle}>{videos[selectedVideo].title}</div>
                        </div>
                    }
                    {
                        illustrationBottom && illustrationBottom.umbracoFile &&
                        <img className={classes.illustrationBottom} src={illustrationBottom.umbracoFile} />
                    }
                </div>
            </div>
            <div className={cx('video-sarousel', classes.slider)}>
                <Slider {...settings} ref={(c: any) => (slider.current = c)}>
                    {
                        videos.map((video: any, index: number) => (
                            <div key={index} className={cx(classes.slide, { [classes.onlyOne]: videos.length === 1 })}>
                                <div className={cx(classes.imageWrapper, { [classes.active]: index === selectedVideo })} onMouseDownCapture={handleMouseDown} onClickCapture={(e) => handleChildClick(e, index)}>
                                    <div className={classes.playWrapper}>
                                        <img style={{ width: '100%' }} src={images[index]} />
                                        <div className={classes.playButton}>
                                            <i className='icon-play'></i>
                                        </div>
                                    </div>
                                    {
                                        selectedLabel &&
                                        <div className={classes.selectedLabel}>{selectedLabel}</div>
                                    }
                                    {
                                        video.title &&
                                        <div className={classes.videoTitle}>{video.title}</div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
            <div className={classes.buttons}>
                <div className={classes.button} onClick={previous}>
                    <i className='icon-DirectionLeft'></i>
                </div>
                <div className={classes.button} onClick={next}>
                    <i className='icon-DirectionRight'></i>
                </div>
            </div>
        </div>
    )
}

export default VideoCarousel
