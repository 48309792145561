import {useState, useEffect} from 'react';
import {Collapse} from 'react-collapse';

import classes from './Question.module.scss';
import Button from '../../../UI/Button/Button';
import GtmLink from '../../../UI/GtmLink/GtmLink';

import cx from 'classnames';

function Question(props: any) {
    const [isQuestionOpened, setisQuestionOpened] = useState(false);

    const questionTrigger = () => {
        let updatedMenuOpened = !isQuestionOpened;
        setisQuestionOpened(updatedMenuOpened);

        if (updatedMenuOpened) {
            const gtmObject: any = {
                'event': 'User Interaction',
                'category': 'User Interaction',
                'action': 'FAQ',
                'label': props.heading
            };

            (window as any).dataLayer.push(gtmObject);
        }

        if (props.onClickEvent) {
            props.onClickEvent();
        }
    }

    useEffect(() => {
        setisQuestionOpened(props.isOpened);
    }, [props.isOpened])
    

    return (
        <div onClick={questionTrigger} className={classes['question-wrapper']}>
            <div className={cx(isQuestionOpened ? classes['question-content-arrow-rotate']: classes['question-content'])}>
                <div className={classes['question-heading']}>{props.heading}</div>
                <Collapse initialStyle={{height: 0, overflow: 'hidden'}} isOpened={isQuestionOpened}>
                    <div className={cx(isQuestionOpened ? classes['question-paragraph'] : "")} dangerouslySetInnerHTML={{__html:props.body}}></div>
                </Collapse>
                {props.link &&
                <GtmLink href={props.link.Url} gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={props.link.Name}>
                    <div className={cx(isQuestionOpened ? '' : "d-none")}>                    
                        <Button styling="btn-hover-fill" icon="arrow" isDisabled={false} text={props.link.Name} />
                    </div>
                </GtmLink>
                }
            </div>
        </div>
    )
}    

export default Question;