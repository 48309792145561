import classes from  './Heading.module.scss';

import cx from 'classnames';

type HeadingProps = {
    headerType?: boolean;
    text: string,
    type: string,
    backgroundColor: string,
    colorOfText: string,
    isSkewed: boolean,
    isHover?: boolean,
    className?: string
}

function Heading({headerType ,text, type, backgroundColor, colorOfText, isSkewed, isHover, className}: HeadingProps) {

  return (
      <div {...(backgroundColor === 'lightPink' && {style:{backgroundColor:'#E74697'}})} className={cx(className, backgroundColor? classes['heading-padding']: "",backgroundColor == 'dark-green' && classes['bg-darkGreen'] , backgroundColor == 'orange' && classes['bg-orange'], backgroundColor == 'dark orange' && classes['bg-dark-orange'],
      backgroundColor == 'dark-orange' && classes['bg-dark-orange'],
      backgroundColor ? "bg-" + backgroundColor:"", "text-" + colorOfText, (isSkewed && !headerType) ? classes['heading-wrapper'] : classes['heading-wrapper-header'], )} >
        <div className={cx(classes.heading, classes[type], { [classes['isHover']]: isHover })}>{text}</div>
      </div>
    )
}

export default Heading;