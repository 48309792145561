import React, { useState, useEffect, useRef } from 'react';
import classes from './GlobalInformation.module.scss';
import Button from '../UI/Button/Button';
import classNames from 'classnames'

const GlobalInformation = (props: any) => {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if(sessionStorage.getItem('globalInformation') !== 'closed') {
            setTimeout(() => {
                setOpen(true);
            }, 800)
        } 

    }, [])

    const closeModal = () => {
        setOpen(false)
        sessionStorage.setItem('globalInformation', 'closed');
    }

    return (
        <div className={classNames({[classes.wrapper]: true, [classes.wrapperActive]: open })}>
            <div className={classes.innerWrapper}>
                {props.image && (
                    <img
                        className={classes.illustration}
                        src={props.image.umbracoFile}
                        alt=""
                    />
                )}
                <div className={classes.content}>    
                    <h2 style={{color: `#${props.titleTextColor}`}}>{props.title}</h2>
                    <div dangerouslySetInnerHTML={{__html: props.informationText}} />
                    {(props.cta) && (    
                        <a href={props.cta.Url} className={classes.ctaMobile}>
                            <Button isDisabled={false} text={props.cta.Name} />
                        </a>
                    )}
                </div>
                {(props.cta) && (
                    <a href={props.cta.Url} className={classes.ctaDesktop}>
                        <Button isDisabled={false} text={props.cta.Name} />
                    </a>
                )}
                {props.closable && (
                    <div className={classes.sidebar}>
                         <button onClick={closeModal} className={classes.modalClose}>
                            <i className='icon-close'></i>
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default GlobalInformation