import React from 'react';
import classes from  './TimeLineBarLink.module.scss';
import cx from 'classnames';


const TimeLineBarLink = (props: any)  => {
    const scrollToGivenElement = (index: number) => {
        document.getElementById(`container_${index.toString()}`)?.scrollIntoView({
            block: "center",
            behavior: "smooth"
          });
    };
    return (
        <li className={classes['list-item']}>
            <a>
                <div onClick={()=> scrollToGivenElement(props.index)} className={classes['menu-item']}>
                    {props.data.title && <div className={classes.title}>{props.data.title}</div>}
                    <div className={classes.subtitle}>{props.data.subtitle}</div>
                </div>
            </a>
        </li>
    )
}

export default TimeLineBarLink;
