import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export class NickNameService {

    static setNickName(nickName: string) {
        return new Promise<string>((resolve, reject) => {
            AxiosInstance.postWithParameters('/umbraco/api/student/setnickname', null, { nickName }).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        })
    }
}