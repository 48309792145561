import React, { useEffect, useState, useRef, Fragment } from 'react'
import { KidOverviewService, IKidOverviewItem, IAddPaperOrder, IKidOverview, IEditPaperOrder } from './services/KidOverviewService';
import OverviewTable from '../UI/OverviewTable/OverviewTable';
import classes from './KidOverview.module.scss';
import Number from '../UI/Number/Number';
import Loader from '../UI/Loader/Loader';
import PaperSale from './PaperSale/PaperSale';
import OrderRow from './OrderRow/OrderRow';
import cx from 'classnames';
import KidsPreview from '../UI/KidsPreview/KidsPreview';
import { AxiosResponse, AxiosError } from 'axios';

const KidOverview = ({
    tableNickname,
    tableOrders,
    tableAmount,
    labelTotal,
    totalAmountOfMoney,
    totalAmountOfOrders,
    illustration,
    digitalOrder,
    paperOrder,
    submit,
    paperSaleScrollToForm,
    ...props
}: any) => {
    const [items, setItems] = useState<IKidOverviewItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const total = useRef(0);
    const totalOrders = useRef(0);
    const personalCode = useRef('');
    const lastRow = useRef<any>(null);
    const paperSaleElement = useRef<any>(null);

    const getOrdarsResponse = (result: IKidOverview) => {
        let o = 0;
        result.orders.forEach(item => {
            if (item.customerName && item.customerName.length >= 4) {
                item.customerNameWithStars = item.customerName.substr(0, 2) + ("*").repeat(item.customerName.length - 4) + item.customerName.substr(-2);
            }

            o = o + item.totalOrders;
        });
        total.current = result.turnover;
        totalOrders.current = o;
        personalCode.current = result.code;
        setItems(result.orders);
        setIsLoading(false);
    }

    const onSubmit = (result: any) => {
        setIsLoading(true);
        const data: IAddPaperOrder = {
            customer: result.person,
            profit: result.amount,
            personalCode: personalCode.current
        }

        KidOverviewService.addPaperOrder(data).then(result => {
            getOrdarsResponse(result);
            lastRow.current.scrollIntoView(false);

            const gtmObject: any = {
                'event': 'Portal',
                'category': 'Portal',
                'action': 'Add paper purchase'
            };

            (window as any).dataLayer.push(gtmObject);
        }).catch((error: AxiosError) =>
        {
            var inputErrorElement = document.getElementById("input_error_message")

            if (inputErrorElement !== null) {
                if (typeof error.response?.data === 'string') {
                    inputErrorElement.innerText = error.response?.data
                } else {
                    inputErrorElement.innerText = 'Er is iets fout gegaan, probeer het later nog eens.'
                }
            }
        })
    }

    const deleteOrder = (id: number) => {
        setIsLoading(true);
        KidOverviewService.deleteOrder(id).then(result => {
            getOrdarsResponse(result);

            const gtmObject: any = {
                'event': 'Portal',
                'category': 'Portal',
                'action': 'Delete paper purchase'
            };

            (window as any).dataLayer.push(gtmObject);
        })
    }

    const onEdit = (data: IEditPaperOrder) => {
        setIsLoading(true);
        KidOverviewService.editOrder(data).then(result => {
            getOrdarsResponse(result);

            const gtmObject: any = {
                'event': 'Portal',
                'category': 'Portal',
                'action': 'Edit paper purchase'
            };

            (window as any).dataLayer.push(gtmObject);
        })
    }

    const scrollToPaperSale = () => {
        paperSaleElement.current.scrollIntoView(false);
    }

    useEffect(() => {
        KidOverviewService.getOverview().then(result => getOrdarsResponse(result))
    }, [setItems, setIsLoading]);

    useEffect(() => {
        if (typeof window !== "undefined" && !isLoading) {
            if (window.location.href.indexOf('paperSale') !== -1) {
                scrollToPaperSale();
            }
        }
    }, [isLoading])

    return (
        <div className='container position-relative'>
            <div className={classes.componentWrapper}>
                <KidsPreview
                    isLoading={isLoading}
                    total={total.current}
                    totalAmountOfMoney={totalAmountOfMoney}
                    totalAmountOfOrders={totalAmountOfOrders}
                    itemsLength={items.length}
                    illustration={illustration.umbracoFile}
                    scrollToForm={paperSaleScrollToForm}
                    scrollToFormCallback={scrollToPaperSale}
                />
                {
                    isLoading ?
                        <Loader /> :
                        <div className='w-100' ref={lastRow}>
                            <OverviewTable
                                className={classes.table}
                                tableHead={(
                                    <tr className='d-none d-lg-table-row'>
                                        <th className={cx(classes.numberColumn, classes.tableHead, classes.noOpacity)}>#</th>
                                        <th className={classes.tableHead}>{tableNickname}</th>
                                        <th className={classes.tableHead}>{tableOrders}</th>
                                        <th className={classes.tableHead}>{tableAmount}</th>
                                        <th className={classes.actionButtonColumn}></th>
                                    </tr>
                                )}
                                tableBody={
                                    items && items.length !== 0 &&
                                    items.map((item, index) => {
                                        let row = <OrderRow
                                            key={item.id}
                                            item={item}
                                            index={index}
                                            digitalOrder={digitalOrder}
                                            paperOrder={paperOrder}
                                            deleteOrder={deleteOrder}
                                            submit={submit}
                                            onEdit={onEdit}
                                            classes={classes}
                                        />

                                        return row;
                                    })
                                }
                            />
                            <OverviewTable
                                className={classes.totalTable}
                                tableBody={
                                    <tr className={classes.totalRow}>
                                        <td className={cx(classes.numberColumn, classes.totalNumberColumn, classes.noOpacity)}>#{items.length}</td>
                                        <td className={classes.totalLabel}>
                                            {labelTotal}
                                        </td>
                                        <td className={classes.turnOverColumn}>
                                            <Number
                                                value={total.current}
                                            />
                                        </td>
                                    </tr>
                                }
                            />
                        </div>
                }
            </div>
            <PaperSale elementRef={paperSaleElement} {...props} onSubmit={onSubmit} />
        </div>
    )
}

export default KidOverview;
