import { useEffect, useState, Fragment} from 'react';
import classes from  './VideoTablesBase.module.scss';
import { VideoModeratingServices, IVideosResponse } from '../services/VideoModeratingServices';
import OverviewTable from '../../UI/OverviewTable/OverviewTable';
import Loader from '../../UI/Loader/Loader';
import Button from '../../UI/Button/Button';

import cx from 'classnames';

let tableData: IVideosResponse[];
const VideoTablesBase = (props: any) => {
    const [tableDataSearchResults, setTableDataSearchResults] = useState<IVideosResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const redirectToDetails = (studentCode: string) => {
        window.location.href = `./videomoderate?studentCode=${studentCode}`;
    }

    useEffect(()=>{
        VideoModeratingServices.getRandomVideos().then((response: any) => {
            tableData = response.map((data: IVideosResponse)=>{
                return {
                    videodetails : data,
                    nickname: ""
                }
            });
            setTableDataSearchResults(tableData);
            setIsLoading(false);
        })
    }, []) 
    
    return (
        <div className="container">
            <Button onClick={()=>{window.location.href="./videolist"}} text={props.navigateTo} icon="arrow" styling="" isDisabled={false}></Button>
            {isLoading ? <Loader/>: <OverviewTable
                    className={classes.table}
                    tableHead={(
                        <tr className='d-none d-lg-table-row'>
                            <th className={classes.tableHead}>{props.title}</th>
                            <th className={classes.tableHead}>{props.personalCode}</th>
                            <th className={classes.tableHead}>{props.parentEmailColum}</th>
                            <th className={classes.tableHead}>{props.inappropriateReportsWelcomeColumn}</th>
                            <th className={classes.tableHead}>{props.inappropriateReportsThankYouColumn}</th>
                        </tr>
                    )}
                    tableBody={
                        tableDataSearchResults && tableDataSearchResults.length !== 0 &&
                        tableDataSearchResults.map((item) => {
                            let row = (
                                <Fragment key={item.videodetails.personalCode}>
                                    <tr  onClick={() => redirectToDetails(item.videodetails.personalCode)} className={cx(classes.tableRow,'d-none d-lg-table-row')}>
                                        <td className={classes.typeColumn}>
                                            {item.nickname}
                                        </td>
                                        <td className={classes.typeColumn}>
                                            {item.videodetails.personalCode}
                                        </td>
                                        <td className={classes.typeColumn}>
                                            {item.videodetails.videos_ApprovalEmail}
                                        </td>
                                        <td className={classes.typeColumn}>
                                            {item.videodetails.welcomeVideo_InappropriateCount}
                                        </td>
                                        <td className={classes.typeColumn}>
                                            {item.videodetails.thankYouVideo_InappropriateCount}
                                        </td>
                                    </tr>
                                    <tr  className='d-lg-none'>
                                        <td colSpan={3}>
                                            <div className={classes.mobileRow}>
                                                <div className={classes.typeColumn}>
                                                    {item.nickname}
                                                </div>
                                                <div className={classes.typeColumn}>
                                                    {item.videodetails.personalCode}
                                                </div>
                                                <div className={classes.typeColumn}>
                                                    {item.videodetails.videos_ApprovalEmail}
                                                </div>
                                                <div className={classes.typeColumn}>
                                                    {item.videodetails.welcomeVideo_InappropriateCount}
                                                </div>
                                                <div className={classes.typeColumn}>
                                                    {item.videodetails.thankYouVideo_InappropriateCount}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>
                                ) 
                        return row
                        })
                    }
                />
            }
        </div>
    )
}

export default VideoTablesBase;
