import React, { Fragment } from 'react';
import classes from './OrderDetails.module.scss';
import cx from 'classnames'

interface OrderDetailsModel {
    items: { name: string, value: string }[],
    wrongDetailsText?: string,
    className?: string,
    showMaterials?: boolean
}

const OrderDetails = ({ items, wrongDetailsText, className, showMaterials }: OrderDetailsModel) => {
    return (
        <Fragment>
            <div className={cx(classes.details, className)}>
                {
                    items && items.length !== 0 &&
                    items.map((item, index) => (
                        <div key={index} className={classes.detail}>
                            <div className={cx(classes.detailLabel, { [classes.materialsLabel]: showMaterials })}>{item.name}</div>
                            <div className={cx({ [classes.materialsValue]: showMaterials })}>{item.value}</div>
                        </div>
                    ))
                }
            </div>
            {
                wrongDetailsText &&
                <div className={classes.wrongDetailsText}>{wrongDetailsText}</div>
            }
        </Fragment>
    )
}

export default OrderDetails;
