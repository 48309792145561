import React, { useState } from 'react';
import AxiosInstance from '../../apis/axios';
import Modal from '../Modal/Modal';

const GlobalErrorHandler = ({
    body,
    title,
    illustration,
    homeButton
}: any) => {
    const [open, setOpen] = useState<boolean>(false);

    const closeModal = () => {
        setOpen(false);
    }

    AxiosInstance.setResponseInterceptors(
        response => {
            return response;
        }, error => {
            if (error && error.response && error.response.status === 500) {
                setOpen(true);
            }

            return Promise.reject(error);
        }
    )

    return (
        <Modal closeModal={closeModal} isOpen={open} body={body} title={title} illustration={illustration} homeButton={homeButton}></Modal>
    )
}

export default GlobalErrorHandler
