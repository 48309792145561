import cx from 'classnames';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Button from '../UI/Button/Button';
import GtmLink from '../UI/GtmLink/GtmLink';
import Heading from '../UI/Heading/Heading';
import Loader from '../UI/Loader/Loader';
import classes from './MyShop.module.scss';
import { MyShowService } from './services/MyShopService';

const MyShop = ({
  introductionText,
  copyButtonText,
  mailButtonText,
  emailSubject,
  emailBody,
  illustration,
  showShopLink,
  yourPersonalLink,
  whatsappMessage,
}: any) => {
  const [shopUrl, setShopUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const copyToClipboard = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(encodeQueryParamsWithSpace(shopUrl) || '');
    }
  };

  const getEmail = () => {
    const val = getEmailURL;
    return `mailto:?subject=${emailSubject}&body=${emailBody.replace('<link>', `${val}`)}`;
  };

  useEffect(() => {
    Promise.all([MyShowService.getUrl()])
      .then(([urls]) => {
        if (urls) {
          setShopUrl(urls);
        }

        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  const encodeQueryParamsWithEncoding = useCallback((url: string) => {
    const urlObj = new URL(url);
    const gnParam = urlObj.searchParams.get('gn');
    if (gnParam) {
      const encodedGn = gnParam.replace(/ /g, ' ');
      urlObj.searchParams.set('gn', encodedGn);
    }
    return urlObj.toString();
  }, []);

  const encodeQueryParamsWithSpace = useCallback((url: string) => {
    const urlObj = new URL(url);
    return urlObj.toString();
  }, []);

  const getEmailURL = useMemo(() => {
    if (shopUrl) {
      const spaceReplacedUrl = encodeQueryParamsWithEncoding(shopUrl);
      const encodedUrl = encodeURIComponent(spaceReplacedUrl);
      return encodedUrl;
    }
  }, [encodeQueryParamsWithEncoding, shopUrl]);

  const getWhatsappURL = useMemo(() => {
    if (shopUrl) {
      const spaceReplacedUrl = encodeQueryParamsWithSpace(shopUrl);
      const encodedUrl = encodeURIComponent(spaceReplacedUrl);
      return encodedUrl;
    }
  }, [encodeQueryParamsWithSpace, shopUrl]);

  return (
    <div className='container'>
      <div className={classes.myShop}>
        {introductionText && (
          <div className='row'>
            <div className='col-lg-11'>
              <Heading
                className={cx(classes.headingPadding, classes.noneBoxShadow)}
                text={yourPersonalLink || 'Jouw persoonlijke linkje'}
                type='heading-4'
                backgroundColor='dark-orange'
                colorOfText='white'
                isSkewed={true}></Heading>

              <div className={classes.text} dangerouslySetInnerHTML={{ __html: introductionText }}></div>
            </div>
          </div>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            {showShopLink && (
              <>
                <div className={classes.inputContainer}>
                  {copyButtonText && !isMobile && (
                    <div className={classes.buttonColumn}>
                      <Button
                        icon='duplicate'
                        text={copyButtonText}
                        isDisabled={false}
                        className={classes.button}
                        onClick={copyToClipboard}
                      />
                    </div>
                  )}
                  {isMobile && (
                    <div className={classes.buttonColumn}>
                      <a
                        href={`whatsapp://send?text=${whatsappMessage || ''}${' '}${getWhatsappURL}`}
                        data-action='share/whatsapp/share'>
                        <Button
                          text='Stuur via whatsapp'
                          isDisabled={false}
                          icon='whatsapp'
                          className={classes.button}
                          styling='green'
                        />
                      </a>
                    </div>
                  )}
                  {illustration && <img className={classes.illustration} src={illustration.umbracoFile}></img>}
                  {mailButtonText && (
                    <div className={classes.buttonColumn}>
                      <GtmLink
                        gtmCategory='User Interaction'
                        gtmAction='CTA'
                        gtmLabel={mailButtonText}
                        className={classes.link}
                        target='_blank'
                        href={getEmail()}>
                        <Button
                          icon='mail'
                          styling='dark-red'
                          text={mailButtonText}
                          isDisabled={false}
                          className={classes.button}
                        />
                      </GtmLink>
                    </div>
                  )}
                </div>
                <div className={classes.inputColumn}>
                  <div id='copyText' className={classes.urlInput}>
                    {encodeQueryParamsWithSpace(shopUrl)}
                  </div>
                </div>
              </>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default MyShop;
