import { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import Button from '../../../UI/Button/Button';
import MultiInput from '../../Shared/MultiInput/MultiInput';

import { FunnelService } from '../../services/FunnelService';

import classes from './StepThreeKid.module.scss';

import cx from 'classnames';

function StepThreeKid(props: any) {
  const [isCodeWrong, setIsCodeWrong] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const [staySignedIn, setStaySignedIn] = useState<boolean>(true);
  const onSubmit = (data: any) => {
    setIsLoading(true);
    let agentCode: string = '';
    data.multiInput.map((singleValue: any) => {
      agentCode += singleValue.value;
    });
    const city = localStorage?.getItem('city');
    const school = localStorage?.getItem('schoolName');
    const group = localStorage?.getItem('group');
    const location = localStorage?.getItem('location');

    let payLoad = {
      agentCode: agentCode,
      city: city,
      school: school,
      groupname: group,
      location: '',
      keepSignedIn: staySignedIn,
    };

    if (location && location !== 'undefined') {
      payLoad.location = location;
    }

    FunnelService.login(payLoad).then((response: any) => {
      if (response.isSuccessful) {
        localStorage?.removeItem('city');
        localStorage?.removeItem('schoolName');
        localStorage?.removeItem('group');
        localStorage?.removeItem('location');
        setIsLoading(false);

        const gtmObject: any = {
          event: 'Login',
          category: 'Login',
          action: 'Leerlingen',
          label: agentCode,
        };

        if ((window as any).dataLayer) {
          (window as any).dataLayer.push(gtmObject);
          window.location.href = props.nextStep.Url;
          return;
        }

        window.location.href = props.nextStep.Url;
      } else {
        setIsLoading(false);
        setIsCodeWrong(true);
      }
    });
  };

  const restMultiInput = () => {
    setIsCodeWrong(false);
  };

  const onCheckboxClick = useCallback(() => {
    setStaySignedIn(!staySignedIn);
  }, [staySignedIn]);

  return (
    <FormProvider {...methods}>
      <form className={classes.Wrapper} onSubmit={methods.handleSubmit(onSubmit)}>
        <label>{props.autoCompleteDropDown}</label>
        <div className={classes.MultiInput}>
          <MultiInput restMultiInput={() => restMultiInput()} isCodeWrong={isCodeWrong}></MultiInput>
          {isCodeWrong && <div className={classes.errorMessage}>{props.errorMessage}</div>}
          <div className={classes.Description}>{props.dropDownDescription}</div>
        </div>
        <div className={classes.Button}>
          <Button
            type='submit'
            text={props.button.Name}
            icon='arrow'
            styling='btn-wide'
            isDisabled={isLoading}
            isLoading={isLoading}></Button>
        </div>
        <div className={classes.checkboxWrapper}>
          <input
            className={classes.checkbox}
            type='checkbox'
            id='remember-login'
            name='remember-login'
            onClick={onCheckboxClick}
            defaultChecked={staySignedIn}
          />
          <label htmlFor='remember-login'>{props?.keepSignedInLabel}</label>
        </div>
      </form>
    </FormProvider>
  );
}

export default StepThreeKid;
