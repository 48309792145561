import React, {forwardRef, useState} from 'react'
import classes from './HomePageButton.module.scss'
import cx from 'classnames';
import Heading from "../../UI/Heading/Heading";
import {AiOutlineArrowRight} from 'react-icons/ai';

const HomePageButton = forwardRef(({illustration, link, logo, subTitle, title, index, elementRef}: any) => {
    const [isHover, setIsHover] = useState(false)

    const getTitleInChunks = (title: string) => {
        return ((title.replace(/[\s\S]{1,15}(?!\S)/g, '$&\n').split('\n'))) 
    }

    return(
        <div className='col-lg-5 col-md-5 col-sm-12'
             onMouseEnter={() => setIsHover(true)}
             onMouseLeave={() => setIsHover(false)}>

            <div id={index} ref={element => elementRef(element)} className={cx(classes.button, index == 0 ? classes.leftButton: classes.rightButton)} onClick={() => {window.location.href = link.Url}}>
                <img className={cx(classes.illustration, index == 0 ? classes.leftIllustration: classes.rightIllustration)} src={illustration.umbracoFile} alt="" />
                <div className={classes.logoContainer}>
                    <img width='100%' src={logo.umbracoFile} alt="" />
                </div>
                {
                    getTitleInChunks(title).map((el, index) => (
                        <div>
                            <Heading headerType={true} isHover={isHover} key={index} text={el} type="heading-5" backgroundColor="white" colorOfText="red" isSkewed={true}></Heading>
                        </div>
                    ))
                }

                <div className={classes.subTitle} style={{color: index == 1 ? '#D10074': ''}}>
                    {subTitle}
                </div>
                <div>
                    <button className={cx(classes.navigationButton ,index == 0 ? classes.navigationButtonLeft : classes.navigationButtonRight)}>
                        <AiOutlineArrowRight size={20}/>
                    </button>
                </div>
            </div>
        </div>
        
    )
})

export default HomePageButton;