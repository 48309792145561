import { useEffect, useState, useRef } from 'react';
import classes from  './ReportAsInAppropirate.module.scss';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';
import { ReportAsInappropriateService } from './services/ReportAsInappropriate';
import Video from '../UI/Video/Video';
import Blob from '../UI/Blob/Blob';
import Button from '../UI/Button/Button';
import StatusMessage from '../ParentPermission/StatusMessage/StatusMessage';

function ReportAsInappropriate(props: any) {
    const accessToken = useRef<string>('');
    const type = useRef<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const [videoSrc, setVideoSrc] = useState<string>('');
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (urlParams.has('studentAccessToken')) {
                accessToken.current = urlParams.get('studentAccessToken') as string;
            }

            if (urlParams.has('type')) {
                type.current = urlParams.get('type') as string;
            }

            if (accessToken.current && type.current !== 'All') {
                const object = {
                    accessToken: accessToken.current,
                    type: type.current
                }
                ReportAsInappropriateService.getVideoUrls(object).then(result => {
                    if (type.current === 'ThankYou') {
                        setVideoSrc(result.thankYouVideo as string);
                    } else {
                        setVideoSrc(result.welcomeVideo as string);
                    }
                })
            }
        }
    }, [type]);


    const setStatus = () => {
        setDisabled(true);
        const object = {
            accessToken: accessToken.current,
            videoType: type.current
        }
        ReportAsInappropriateService.setReportedStatus(object).then((result: any) => {
            setShowMessage(true);

        }).catch(() => setDisabled(false))
    }

    const closeTab = () => {
        window.close();
    }

    return (
        <div className={classes.component}>
             <div className={classes.header}>
                {
                    props.logo &&
                    <img alt='logo' src={props.logo.umbracoFile} />
                }
            </div>
            <div className={classes.body}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2'>
                            {
                                props.title &&
                                <OverviewHeading title={props.title} titleClassName={classes.heading} />
                            }
                            {
                                props.text &&
                                <div className={classes.text} dangerouslySetInnerHTML={{ __html: props.text }}></div>
                            }
                            {
                                !showMessage &&
                                <div className={classes.buttonContainer}>
                                    <Button onClick={setStatus} text={props.yesButton} isDisabled={disabled} styling='yellow-cyan' className={classes.buttonNo} />
                                    <Button onClick={closeTab} text={props.cancelButton} isDisabled={disabled} />
                                </div>
                            }
                            {
                                showMessage &&
                                <StatusMessage
                                    title={ props.reportedTitle}
                                    text={ props.reportedText }
                                    rejected={true}
                                />
                            }
                        </div>
                        {
                            videoSrc &&
                            <div className={classes.video}>
                                <Blob type='video' className={classes.videoBlob} />
                                <div className={classes.videoInstance}>
                                    {
                                        props.videoIllustrationUpper &&
                                        <img className={classes.illustrationUpper} src={props.videoIllustrationUpper.umbracoFile} alt='illustration' />
                                    }
                                    <Video src={videoSrc} />
                                    {
                                        props.videoIllustrationLower &&
                                        <img className={classes.illustrationLower} src={props.videoIllustrationLower.umbracoFile} alt='illustration' />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportAsInappropriate;
