import ModalInstance from '../UI/ModalInstance/ModalInstance';
import classes from './Modal.module.scss';
import Button from '../UI/Button/Button';
import GtmLink from '../UI/GtmLink/GtmLink';

const Modal = ({ componentId, body, title, illustration, homeButton, isOpen, closeModal }: any) => {
  const closeModalSubmit = () => {
    localStorage.setItem('kpz-nickname-confirm', 'true');
    closeModal();
  };

  return (
    <ModalInstance isOpen={isOpen} closeModal={closeModal}>
      {illustration && illustration.umbracoFile && (
        <div className={classes.illustration}>
          <img src={illustration.umbracoFile} />
        </div>
      )}
      {title && <h3 className={classes.title}>{title}</h3>}
      {body && <div className={classes.body} dangerouslySetInnerHTML={{ __html: body }}></div>}
      {homeButton && componentId === '1' && (
        <div className={classes.button}>
          <GtmLink
            href={homeButton.Url}
            target={homeButton.Target}
            gtmCategory='User Interaction'
            gtmAction='CTA'
            gtmLabel={homeButton.Name}>
            <Button text={homeButton.Name} isDisabled={false} onClick={closeModal} />
          </GtmLink>
        </div>
      )}
      {componentId === '2' && (
        <div className={classes.button}>
          <Button text='Opslaan' isDisabled={false} onClick={closeModalSubmit} />
        </div>
      )}
    </ModalInstance>
  );
};

export default Modal;
