import Cards from './Cards/Cards';
import classes from './TimeLine.module.scss';
import TimeLineBar from './TimeLineBar/TimeLineBar';

const TimeLine = (props: any)  => {
    return (
        <div id={props.linkId} className='container'>
            <div className={classes.wrapper}>
                <TimeLineBar mobileDropdownPlaceholder={props.mobileDropdownPlaceholder} data={props}></TimeLineBar>
                <Cards data={props}></Cards>
            </div>
        </div>
    )
}

export default TimeLine;
