import React, { Fragment, useEffect, useRef, useState } from 'react';
import classes from './ParentPermission.module.scss';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';
import Button from '../UI/Button/Button';
import Question from '../FAQ/Questions/Question/Question'
import { ParentPermissionService } from './services/ParentPermissionService';
import StatusMessage from './StatusMessage/StatusMessage';
import Video from '../UI/Video/Video';
import Blob from '../UI/Blob/Blob';

const ParentPermission = ({
    logo,
    title,
    text,
    yesButton,
    noButton,
    fAQBlocks,
    type,
    thankYouTitle,
    thankYouText,
    rejectedTitle,
    rejectedText,
    videoIllustrationLower,
    videoIllustrationUpper
}: any) => {
    const accessToken = useRef<string>('');
    const [disabled, setDisabled] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [isRejected, setIsRejected] = useState<boolean>(false);
    const [videoSrc, setVideoSrc] = useState<string>('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (urlParams.has('accessToken')) {
                accessToken.current = urlParams.get('accessToken') as string;
            }

            if (accessToken.current && type !== 'All') {
                ParentPermissionService.getVideoUrls(accessToken.current).then(result => {
                    if (type === 'ThankYou') {
                        setVideoSrc(result.thankYouVideo as string);
                    } else {
                        setVideoSrc(result.welcomeVideo as string);
                    }
                })
            }
        }
    }, [type]);

    const setStatus = (approved: boolean) => {
        setDisabled(true);

        ParentPermissionService.setApprovalStatus(accessToken.current, type, approved).then((result: any) => {
            setIsRejected(!approved);
            setShowMessage(true);

            const gtmObject: any = {
                'event': 'User Interaction',
                'category': 'User Interaction',
                'action': 'CTA',
                'label': approved ? yesButton : noButton,
              };
          
              (window as any).dataLayer.push(gtmObject);

        }).catch(() => setDisabled(false))
    }

    return (
        <div className={classes.component}>
            <div className={classes.header}>
                {
                    logo &&
                    <img alt='logo' src={logo.umbracoFile} />
                }
            </div>
            <div className={classes.body}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2'>
                            {
                                title &&
                                <OverviewHeading title={title} titleClassName={classes.heading} />
                            }
                            {
                                text &&
                                <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }}></div>
                            }
                            {
                                !showMessage &&
                                <div className={classes.buttonContainer}>
                                    <Button onClick={() => setStatus(true)} text={yesButton} isDisabled={disabled} styling='yellow-cyan' className={classes.buttonNo} />
                                    <Button onClick={() => setStatus(false)} text={noButton} isDisabled={disabled} />
                                </div>
                            }
                            {
                                showMessage &&
                                <StatusMessage
                                    title={isRejected ? rejectedTitle : thankYouTitle}
                                    text={isRejected ? rejectedText : thankYouText}
                                    rejected={isRejected}
                                />
                            }
                        </div>
                        {
                            videoSrc &&
                            <div className={classes.video}>
                                <Blob type='video' className={classes.videoBlob} />
                                <div className={classes.videoInstance}>
                                    {
                                        videoIllustrationUpper &&
                                        <img className={classes.illustrationUpper} src={videoIllustrationUpper.umbracoFile} alt='illustration' />
                                    }
                                    <Video src={videoSrc} />
                                    {
                                        videoIllustrationLower &&
                                        <img className={classes.illustrationLower} src={videoIllustrationLower.umbracoFile} alt='illustration' />
                                    }
                                </div>
                            </div>
                        }
                        <div className='col-lg-8 offset-lg-2'>
                            {
                                fAQBlocks && fAQBlocks.length !== 0 &&
                                <div className={classes.questionContainer}>
                                    <div className={classes.questions}>
                                        {
                                            fAQBlocks.map((faqs: any, index: number) => (
                                                <Fragment key={index}>
                                                    {
                                                        faqs && faqs.fAQCard && faqs.fAQCard.length !== 0 &&
                                                        faqs.fAQCard.map((faq: any, i: number) => (
                                                            <Question
                                                                key={i}
                                                                heading={faq.header}
                                                                body={faq.body}
                                                                link={faq.link}
                                                            />
                                                        ))
                                                    }
                                                </Fragment>
                                            ))
                                        }
                                    </div>
                                    <Blob type='faqBlob' className={classes.quastionBlob} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ParentPermission
