import React, { Fragment } from 'react';
import Select from 'react-select'

const DropDown = ({ type, ...props }: any) => {
    const customStyles = {
        placeholder: () => {
            const style: any = {
                fontSize: '16px',
                color: '#fff',
                fontWeight: 'bold',
                lineHeight: '18px'
            }

            if (type === 'teacherNavigation') {
                style['color'] = '#ffffff';
            }

            if(type==='timeline'){
                style['color']='rgba(0, 0, 0, 0.6)';
            }

            return style;
        },
        container: () => {
            const style: any = {
                border: '1px solid #F574F',
                minHeight: '56px',
                borderRadius: '8px',
                boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.12)',
                background: '#fff',
                position: 'relative'
            }

            if (type === 'footer') {
                style['height'] = '56px';
                style['backgroundColor'] = '#FED100';
                style['border'] = 'none';
            }

            if (type === 'kidsNavigation') {
                style['height'] = '56px';
                style['backgroundColor'] = '#439ed5';
                style['border'] = 'none';
            }

            if (type === 'teacherNavigation') {
                style['height'] = '48px';
                style['backgroundColor'] = '#439ed5';
                style['border'] = 'none';
            }

            if (type === 'timeline') {
                style['minHeight'] = '48px';
                style['width'] = '100%'
            }

            return style;
        },
        control: () => {
            const style: any = {
                padding: '18px 24px',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                minHeight: '56px',
                alignItems: 'center'
            }

            if (type === 'footer') {
                style['height'] = '56px';
            }

            if (type === 'teacherNavigation') {
                style['height'] = '48px';
            }

            if (type === 'timeline') {
                style['minHeight'] = '48px';
                style['padding']= '13px 20px';
            }

            return style;
        },
        valueContainer: () => ({
            display: 'flex',
            alignItems: 'center'
        }),
        menu: () => ({
            position: 'absolute',
            width: '100%',
            'min-width': '125px',
            zIndex: '2000',
            background: 'white',
            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
            overflow: 'hidden',
            marginTop: '4px',
            border: '1px solid #5F574F'
        }),
        menuList: () => ({
            padding: '24px'
        }),
        option: (provided: any, state: any) => ({
            paddingBottom: '16px',
            fontWeight: 'bold',
            color: state.isSelected ? '#23A5EE' : '#333333',

            '&:last-child': {
                paddingBottom: 0
            },

            '&:hover': {
                color: '#23A5EE',
                cursor: 'pointer'
            }
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        dropdownIndicator: (provided: any, state: any) => {
            const isOpen = state.selectProps.menuIsOpen;
            let transition;
            let transform;
            let color;

            if (isOpen) {
                transition = 'all .25s';
                transform = 'rotateZ(180deg)';
            }

            if (type === 'footer' || type === 'teacherNavigation' || type === 'kidsNavigation') {
                color = '#ffffff';
            }

            return {
                padding: '0',
                transition,
                transform,
                color
            }
        },
        singleValue: () => {
            const style = {
                fontSize: '16px',
                color: '#23A5EE',
                fontWeight: 'bold',
                lineHeight: '18px'
            }

            if (type === 'footer' || type === 'teacherNavigation') {
                style['color'] = '#D52B1E';
                style['fontWeight'] = '900';
            }

            if (type === 'teacherNavigation' || type === 'kidsNavigation') {
                style['color'] = '#ffffff';
                style['fontWeight'] = '900';
            }

            return style;
        }
    };

    return (
        typeof window !== "undefined" ?
            <Select
                styles={customStyles}
                menuPlacement='auto'
                {...props}
            /> :
            <Fragment></Fragment>
    )
}

export default DropDown
