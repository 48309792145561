import React from 'react';
import classes from './Steps.module.scss';

import cx from 'classnames';


function Steps(props:any) {
  const numberOfActiveStep = props.activeStep;
  const allSteps=props.stepsNames.length;
  const steps = props.stepsNames.map((step:any, index:number) => {
    if(index % 2 == 0 && index !==2) {
      return  <div key={index} className="d-flex align-items-center">
                <span className={cx(index+1 > numberOfActiveStep ? classes.CircleWrapperYellow  : null, classes.CircleWrapper, index+1<numberOfActiveStep? classes.CircleWrapperIcon : null )}>
                  {index+1 >= numberOfActiveStep ? index+1 : null}
                </span>
                <div className={cx(index+1 > numberOfActiveStep ? classes.LineYellow : null, classes.Line, props.isWide? classes.LineWide: null)}></div>
              </div>                    
    }
    else if(index ===2){
      return    <div key={index} className="d-flex align-items-center">
                  <div className={cx(index+1 > numberOfActiveStep ? classes.LineYellow : null, classes.Line, props.isWide? classes.LineWide: null)}></div>
                  <span className={cx((index+1 > numberOfActiveStep ? classes.CircleWrapperYellow  : null), classes.CircleWrapper,  index+1<numberOfActiveStep? classes.CircleWrapperIcon : null )}>
                    {index+1 >= numberOfActiveStep ? index+1 : null}
                  </span>
                </div>
    }
    else {
      return ( allSteps === 3 ? <div key={index} className="d-flex align-items-center">
      <div className={cx(index+1 > numberOfActiveStep ? classes.LineYellow : null, classes.Line, props.isWide? classes.LineWide: null)}></div>
      <span className={cx((index+1 > numberOfActiveStep ? classes.CircleWrapperYellow  : null), classes.CircleWrapper,  index+1<numberOfActiveStep? classes.CircleWrapperIcon : null )}>
        {index+1 >= numberOfActiveStep ? index+1 : null}
      </span>
      <div className={cx(index+1 > numberOfActiveStep ? classes.LineYellow : null, classes.Line, props.isWide? classes.LineWide: null)}></div>
    </div> : <div key={index} className="d-flex align-items-center">
                  <div className={cx(index+1 > numberOfActiveStep ? classes.LineYellow : null, classes.Line, props.isWide? classes.LineWide: null)}></div>
                  <span className={cx((index+1 > numberOfActiveStep ? classes.CircleWrapperYellow  : null), classes.CircleWrapper,  index+1<numberOfActiveStep? classes.CircleWrapperIcon : null )}>
                    {index+1 >= numberOfActiveStep ? index+1 : null}
                  </span>
                </div> )
    }
  })
  
  let stepsNames = props.stepsNames.map((step: any, index: number) => {
    return <div key={index} className={cx(classes.Step, numberOfActiveStep==index+1? classes.StepActive: null)}>{step.value}</div>
  })
  
  return (
      <div>
          <div className={classes.Wrapper}>{steps}</div>
          <div className={classes.StepsNames}>{stepsNames}</div>
      </div>
    )
}

export default Steps;
