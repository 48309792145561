import React from 'react';
import classes from  './TimeLineBar.module.scss';
import cx from 'classnames';
import TimeLineBarLink from './TimeLineBarLink/TimeLineBarLink';
import DropDown from '../../UI/DropDown/DropDown';

const TimeLineBar = (props: any)  => {
    const items: any = [];
    items.push(props.data.beginItem)
    props.data.timelineItems.map((item:any)=>{
        items.push(item);
    })
    items.push(props.data.endItem);

    const scrollToGivenElement = (index: number) => {
        document.getElementById(`container_mobile_${(index+1).toString()}`)?.scrollIntoView({
            block: "center",
            behavior: "smooth"
          });
    };

    return (
        <>
            <div className='d-lg-none'>
                <div className={classes.mobileWrapper}>
                <DropDown
                    isSearchable={false}
                    options={items.map((l: any, index: number) => ({ label: l.timelinedata[0].title, value: index }))}
                    type="timeline"
                    placeholder={props.mobileDropdownPlaceholder}
                    onChange={(e: any) => scrollToGivenElement(e.value)}
                />
                </div>
            </div>
            <div className='d-none d-lg-block'>
                <div className={classes.wrapper}>
                    <ul className={classes['menu-list']}>
                        {items.map((link: any, index: number) => {
                            return <TimeLineBarLink index={index} key={index} data={link.timelinedata[0]}></TimeLineBarLink>
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default TimeLineBar;
