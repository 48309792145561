import React from 'react';

import classes from './USP.module.scss';
import Blob from '../../UI/Blob/Blob';

import cx from 'classnames';


function USP(props:any) {
  let uspItems = props.data.uSPItem.map((item:any, index: number)=> {
      return <li key={index} className="d-flex">
       <span className={cx(classes.CircleWrapper,classes.CircleWrapperIcon)}><i ></i></span>
      {item}
  </li>
  })
  
  return (
    <React.Fragment>
      <div className={cx(classes.Wrapper, 'position-relative')}>
          <Blob className={classes.blobs} type='uspBlob'></Blob>
          <div className={classes.Content}>
            <h1 className={classes.Header}>{props.data.header}</h1>
            <ol className={classes.USP}>
              {uspItems}
            </ol>
          </div>
          <img className={classes.Illustration} src={props.data.illustration.umbracoFile}></img>
      </div>
    </React.Fragment>
    )
}

export default USP;
