import React from 'react';
import classes from  './HomePageHeaderButton.module.scss';
import cx from 'classnames';
import GtmLink from '../../UI/GtmLink/GtmLink';


const HomePageHeaderButton = ( props: any) => {
    return (
        <GtmLink href={props.link.Url} gtmCategory='User Interaction' gtmAction='CTA icon' gtmLabel={props.title}>
            <div className={classes.wrapper}>
                <div className={classes.circleWrapper}>
                    <div className={classes.circle}><img className={classes.icon} src={props.icon.umbracoFile}></img></div>
                </div>
                <div className={classes.title}>{props.title}</div>
                <div className={classes.subtitle}>{props.subtitle}</div>
            </div>
        </GtmLink>
    )
}

export default HomePageHeaderButton
