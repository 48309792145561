import { useEffect, useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import classes from './MultiInput.module.scss';

import cx from 'classnames';
import Button from '../../../UI/Button/Button';

const MultiInput = (props: any) => {
  const {
    control,
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const [showCode, setShowCode] = useState(false);

  const { fields, append } = useFieldArray({
    control,
    name: 'test',
  });
  let isRequired: boolean = false;

  useEffect(() => {
    append({});
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const [fieldName, fieldIndex] = name.split('.');
    const fieldIndexNumber = parseInt(fieldIndex, 10);

    if (props.isCodeWrong) {
      props.restMultiInput();
    }
    clearErrors();

    if (event.key === 'Backspace') {
      event.currentTarget.value = '';
      (
        document.querySelector(`input[name=multiInput\\.${parseInt(fieldIndex, 10)}\\.value]`) as HTMLInputElement
      ).classList.remove(classes.EnteredValue);
      const previousInputSelector = `input[name=multiInput\\.${fieldIndexNumber - 1}\\.value]`;
      const previousInput = document.querySelector(previousInputSelector) as HTMLInputElement;

      if (previousInput) {
        previousInput.focus();

        event.preventDefault();
      }
    } else {
      (document.querySelector(`input[name=multiInput\\.${parseInt(fieldIndex, 10)}\\.value]`) as HTMLElement).focus();
      (
        document.querySelector(`input[name=multiInput\\.${parseInt(fieldIndex, 10)}\\.value]`) as HTMLInputElement
      ).value = value;

      if (value === '') {
        (
          document.querySelector(`input[name=multiInput\\.${parseInt(fieldIndex, 10)}\\.value]`) as HTMLInputElement
        ).classList.remove(classes.EnteredValue);
      } else if (value) {
        (
          document.querySelector(`input[name=multiInput\\.${parseInt(fieldIndex, 10)}\\.value]`) as HTMLInputElement
        ).classList.add(classes.EnteredValue);
        const nextSibling = document.querySelector(`input[name=multiInput\\.${parseInt(fieldIndex, 10) + 1}\\.value]`);

        if (nextSibling !== null) {
          (nextSibling as HTMLElement).focus();
        }
      }
    }
  };

  const onPasteHandler = (e: any) => {
    clearErrors();
    isRequired = false;
    let counter = 1;
    const pastedText: string = e.clipboardData.getData('text');

    for (let i = 0; i < pastedText.length; i++) {
      if (counter > 5) {
        return;
      }
      (document.querySelector(`input[name=multiInput\\.${counter}\\.value]`) as HTMLElement).focus();
      (document.querySelector(`input[name=multiInput\\.${counter}\\.value]`) as HTMLInputElement).value = pastedText[i];
      (document.querySelector(`input[name=multiInput\\.${counter}\\.value]`) as HTMLInputElement).classList.add(
        classes.EnteredValue
      );
      counter++;
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <div className={classes.multiInputWrapper} key={index}>
          <input
            type={showCode ? 'text' : 'password'}
            autoComplete='off'
            className={cx(classes.InputField, {
              [classes.error]: props.isCodeWrong,
            })}
            key={field.id + 1} // important to include key with field's id
            {...register(`multiInput.${1}.value` as const, { required: true })}
            defaultValue='' // make sure to include defaultValue
            placeholder='-'
            onPaste={(event) => onPasteHandler(event)}
            maxLength={1}
            onKeyDown={handleKeyDown}
          />
          <input
            type={showCode ? 'text' : 'password'}
            autoComplete='off'
            className={cx(classes.InputField, {
              [classes.error]: props.isCodeWrong,
            })}
            key={field.id + 2} // important to include key with field's id
            {...register(`multiInput.${2}.value` as const, { required: true })}
            defaultValue='' // make sure to include defaultValue
            onPaste={(event) => onPasteHandler(event)}
            placeholder='-'
            maxLength={1}
            onKeyDown={handleKeyDown}
          />
          <input
            type={showCode ? 'text' : 'password'}
            autoComplete='off'
            className={cx(classes.InputField, {
              [classes.error]: props.isCodeWrong,
            })}
            key={field.id + 3} // important to include key with field's id
            {...register(`multiInput.${3}.value` as const, { required: true })}
            defaultValue='' // make sure to include defaultValue
            onPaste={(event) => onPasteHandler(event)}
            placeholder='-'
            maxLength={1}
            onKeyDown={handleKeyDown}
          />
          <input
            type={showCode ? 'text' : 'password'}
            autoComplete='off'
            className={cx(classes.InputField, {
              [classes.error]: props.isCodeWrong,
            })}
            key={field.id + 4} // important to include key with field's id
            {...register(`multiInput.${4}.value` as const, { required: true })}
            defaultValue='' // make sure to include defaultValue
            onPaste={(event) => onPasteHandler(event)}
            placeholder='-'
            maxLength={1}
            onKeyDown={handleKeyDown}
          />
          <input
            type={showCode ? 'text' : 'password'}
            autoComplete='off'
            className={cx(classes.InputField, {
              [classes.error]: props.isCodeWrong,
            })}
            key={field.id + 5} // important to include key with field's id
            {...register(`multiInput.${5}.value` as const, { required: true })}
            defaultValue='' // make sure to include defaultValue
            onPaste={(event) => onPasteHandler(event)}
            placeholder='-'
            maxLength={1}
            onKeyDown={handleKeyDown}
          />
          <Button
            icon={showCode ? 'eye-off' : 'eye'}
            text=''
            ariaLabel={showCode ? 'Verberg code' : 'Toon code'}
            onClick={() => {
              setShowCode(!showCode);
            }}
            isDisabled={false}
            type='button'></Button>
        </div>
      ))}
    </>
  );
};
export default MultiInput;
