import React, { useRef, useEffect, useState } from 'react';

const GtmLink = ({ children, href, gtmAction, gtmCategory, gtmLabel, onClick, isNickNameInputted, clickHandler, ...props }: any) => {
    const link = useRef<any>();

    const navigationClick = (event: any) => {
        const url = window.location.pathname.split("/").pop();
        const isStudent = window.location.pathname.split('/')[1];



        if (!(window as any)['google_tag_manager'] || !gtmCategory || !gtmAction || !gtmLabel) {
          if(href) {
            if (link.current.getAttribute('target') && link.current.getAttribute('target') === '_blank') {
              window.open(href, '_blank')
            }
            else {
               window.location.href = href;
            }
          }

          return;
        };

        if(url === 'dashboard' && !isNickNameInputted && isStudent === 'kids'){
          clickHandler();
        } else {
          const gtmObject: any = {
            'event': gtmCategory,
            'category': gtmCategory,
            'action': gtmAction,
            'label': gtmLabel
          }

          if (href) {
            let windowReference: any;

            if (link.current.getAttribute('target') && link.current.getAttribute('target') === '_blank') {
              windowReference = window.open();
            }

            gtmObject['eventCallback'] = function () {
              if (windowReference) {
                windowReference.location = href;
                return;
              }

              window.location.href = href;
            };
            gtmObject['eventTimeout'] = 2000;

            event.preventDefault();
            (window as any).dataLayer.push(gtmObject);
          } else if (onClick) {
            (window as any).dataLayer.push(gtmObject);
            onClick();
          }
        }

    }

    return (
        <a ref={link} {...props}  onClick={navigationClick}>
            {children}
        </a>
    )
}

export default GtmLink;
