import React, { useState } from "react";

import classes from './Card.module.scss';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import GtmLink from "../GtmLink/GtmLink";

import cx from 'classnames';


type CardProps = {
    heading: string,
    paragraph: string,
    butonText: string,
    link: string,
    image: any,
    type: any,
    styling: string,
    threeCars?: boolean,
    target?: any
}

const CardWithLink = ({ children, link, buttonText, setHover, target }: any) => {
    return (
        <GtmLink
            gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={buttonText}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            href={link} target={target}>
            {children}
        </GtmLink>
    )
}


function Card({ heading, paragraph, butonText, link, image, type, styling, threeCars, target }: CardProps) {
    const [inHover, setHover] = useState(false);

    const content = (
        <div className={cx(classes.card, classes[styling], { [classes.cursorDefault]: !link })}>
            {image ? <div className={classes['card-image-wrapper']}>
                <picture>
                    {image.mobile && <source media="(max-width: 426px)" srcSet={image.mobile}></source>}
                    {image.mobileXL && <source media="(min-width: 426px) and (max-width: 575px)" srcSet={image.mobileXL}></source>}
                    {image.tablet && <source media="(min-width: 576px) and (max-width: 991px)" srcSet={image.tablet}></source>}
                    {image.desktopTwoCards && !threeCars && <source media="(min-width: 992px)" srcSet={image.desktopTwoCards}></source>}
                    {image.desktopThreeCards && threeCars && <source media="(min-width: 992px)" srcSet={image.desktopThreeCards}></source>}
                    <img className={classes['card-image']} src={image.desktopTwoCards} />
                </picture>
            </div> : null}
            <div className={classes['card-content']}>
                <Heading isHover={inHover} text={heading} type="heading-3" backgroundColor="" colorOfText="gray-dark" isSkewed={false} ></Heading>
                <div className={classes['card-paragraph']} dangerouslySetInnerHTML={{ __html: paragraph }}></div>
                {
                    butonText &&
                    <div className='d-flex'>
                        {type == "buttonLink" ? <div className={classes['card-button']}>
                            {inHover ?
                                <Button text={butonText} styling="btn-flat" icon="arrow" isDisabled={false}></Button> :
                                <Button text="" styling="noTextArrow" icon="" isDisabled={false}></Button>}
                        </div> : null}

                        {type == "buttonWithLink" ? <div className={classes['card-button-left']}>
                            {inHover ?
                                <Button text="" styling="noTextPlaceBoard" icon="" isDisabled={false}></Button> :
                                <Button text="" styling="noTextPlaceBoard" icon="" isDisabled={false}></Button>}
                            <span className={classes['card-link']}>
                                {butonText}
                            </span>
                        </div> : null}

                    </div>
                }
            </div>
        </div>
    )


    if (link) {
        return <CardWithLink link={link} buttonText={butonText} setHover={setHover} target={target}>{content}</CardWithLink>
    }

    return content;
}

export default Card;
