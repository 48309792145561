import React, { Fragment } from "react";
import classes from "./ContentPageHeader.module.scss";
import Blob from "../UI/Blob/Blob";
import cx from "classnames";
import Vimeo from "../UI/Vimeo/Vimeo";
import YouTube from "react-youtube";

interface IContentPageHeaderProps {
  title: string;
  subtitle: string;
  leftIllustration: any;
  topRightIllustration: any;
  bottomRightIllustration: any;
  introText: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  video: any;
}

const ContentPageHeader = ({
  title,
  subtitle,
  leftIllustration,
  topRightIllustration,
  bottomRightIllustration,
  introText,
  desktopImageUrl,
  mobileImageUrl,
  video,
}: IContentPageHeaderProps) => {
  console.log(video)
  let titleArray: string[] = [];

  if (title.indexOf("@newline") !== -1) {
    const titles = title.split("@newline");

    titles.forEach((t) => {
      titleArray.push(t.trim());
    });
  } else {
    titleArray.push(title);
  }

  const goToNext = () => {
    let indexOfContentPageHeader = 0;
    const reactComponentIds = document.querySelectorAll(`[id*="react"]`);

    reactComponentIds.forEach((node, index) => {
      if (
        node.children.length !== 0 &&
        node.children[0].id === "contentPageHeader"
      ) {
        indexOfContentPageHeader = index;
      }
    });

    if (indexOfContentPageHeader !== 0) {
      reactComponentIds[indexOfContentPageHeader + 1].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const videoPlay = (data: any) => {
    let title = '';

    if (data.target && data.target.getVideoData) {
      title = data.target.getVideoData().title;
    }

    const gtmObject: any = {
      'event': 'Video',
      'category': 'Video',
      'action': 'Start video',
      'label': title
    };

    if ((window as any).dataLayer) {
      (window as any).dataLayer.push(gtmObject);
    }
  }

  return (
    <div id={`contentPageHeader`}>
      <div className="container">
        <div className={classes.header}>
          <div className="row sm-gap-4">
            <div
              className={cx("col-md-12 col-lg-6", {
                ["col-lg-12"]: !desktopImageUrl && !mobileImageUrl && !video?.[0]?.embedCode,
              })}
            >
              <div className={classes.titleWapper}>
                {leftIllustration && (
                  <img
                    src={leftIllustration.umbracoFile}
                    className={classes.leftIllustration}
                  />
                )}
                {topRightIllustration && (
                  <img
                    src={topRightIllustration.umbracoFile}
                    className={classes.topRightIllustration}
                  />
                )}
                {bottomRightIllustration && (
                  <img
                    src={bottomRightIllustration.umbracoFile}
                    className={classes.bottomRightIllustration}
                  />
                )}
                {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                <div className={classes.title}>
                  <div className={classes.blob}>
                    <Blob
                      type="content-page-header"
                      className={classes.blobScale}
                    />
                  </div>
                  {titleArray.map((title, index) => (
                    <div className={classes.line} key={index}>
                      <h3>{title}</h3>
                    </div>
                  ))}
                </div>
                {introText && (
                  <div className={classes.introText}>
                    <span>{introText}</span>
                    <button onClick={goToNext} className={classes.button}>
                      <i className="icon-arrow-down"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
            {desktopImageUrl && mobileImageUrl && !video?.[0]?.embedCode && (
              <div className="col-md-12 col-lg-6 pt-2 d-none d-sm-block">
                <img src={desktopImageUrl} className={classes.desktopImage} />
              </div>
            )}
            {mobileImageUrl && desktopImageUrl && !video?.[0]?.embedCode && (
              <div className="col-md-12 col-lg-6 pt-2 d-block d-sm-none">
                <img src={mobileImageUrl} className={classes.mobileImage} />
              </div>
            )}
            {video?.[0]?.embedCode && (
              <div className={cx("embed-responsive embed-responsive-16by9 col-md-12 col-lg-6", classes.video)}>
                {
                  <Fragment>
                    {video?.[0]?.videoType == "Vimeo" ? (
                      <Vimeo
                        className="embed-responsive-item"
                        video={video?.[0]?.embedCode}
                        onPlay={videoPlay}
                      />
                    ) : (
                      <YouTube
                        className="embed-responsive-item"
                        videoId={video?.[0]?.embedCode}
                        onPlay={videoPlay}
                      />
                    )}
                  </Fragment>
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentPageHeader;
