import React, { useRef, useEffect } from 'react';
import classes from './Video.module.scss';
import cx from 'classnames';

const Video = ({ src, title, headVideo }: any) => {
    const videoContainer = useRef<any>(null);
    const titleElement = useRef<any>(null);
    const videoElement = useRef<any>(null);
    const videoSourceElement = useRef<any>();
    const videoMainPlay = useRef<boolean>(false);
    const videoHeadPlay = useRef<boolean>(false);

    const topPosition = () => {
        if (videoContainer.current && titleElement.current) {
            titleElement.current.style.top = `${videoContainer.current.offsetHeight - 24}px`;
        }
    }

    const dispatchEvent = () => {
        window.dispatchEvent(new Event('resize'));
    }

    useEffect(() => {
        topPosition();
        if (videoElement.current) {
            videoElement.current.addEventListener('progress', dispatchEvent);
        }

        window.addEventListener('resize', topPosition);

        return () => {
            window.removeEventListener('resize', topPosition);
            if (videoElement.current) {
                videoElement.current.removeEventListener('progress', dispatchEvent);
            }
        }
    }, [])

    useEffect(() => {
        const playVideoEvent = () => {
            const gtmObject: any = {
                'event': 'Video',
                'category': 'Video',
                'action': 'Start video',
                'label': title
            };

            (window as any).dataLayer.push(gtmObject);

            videoElement.current.removeEventListener('play', playVideoEvent, false);
        }

        videoElement.current.addEventListener('play', playVideoEvent, false);

        return () => {
            videoElement.current.removeEventListener('play', playVideoEvent, false)
        }
    }, [videoElement])

    useEffect(() => {
        if (!headVideo) {
            return;
        }

        const startMainVideo = (e: any) => {
            if (!e) {
                e = window.event;
            }

            if (videoMainPlay.current) {
                return;
            }

            videoSourceElement.current.setAttribute('src', src);

            videoElement.current.load();
            videoElement.current.play();
            videoMainPlay.current = true;
        }

        const startHeadVideo = (e: any) => {
            if (!e) {
                e = window.event;
            }

            if (videoHeadPlay.current) {
                return;
            }

            videoSourceElement.current.setAttribute('src', headVideo);

            videoElement.current.load();
            videoElement.current.play();
            videoHeadPlay.current = true;
        }

        videoElement.current.addEventListener('play', startHeadVideo);
        videoElement.current.addEventListener('ended', startMainVideo);

        return () => {
            if (!headVideo) {
                return;
            }

            videoElement.current.addEventListener('play', startHeadVideo);
            videoElement.current.removeEventListener('ended', startMainVideo);
        }

    }, [videoElement.current, videoSourceElement.current])

    return (
        <div ref={videoContainer} className={cx({ [classes.videoPanel]: title })}>
            <video ref={videoElement} className={classes.video} controls>
                <source ref={videoSourceElement} src={src} type='video/mp4' />
            </video>
            {
                title &&
                <div ref={titleElement} className={classes.title}>{title}</div>
            }
        </div>
    )
}

export default Video
