import classes from  './FAQ.module.scss';

import Heading from '../UI/Heading/Heading';
import Button from '../UI/Button/Button';
import Questions from './Questions/Questions';
import Blob from '../UI/Blob/Blob';
import GtmLink from '../UI/GtmLink/GtmLink';

import cx from 'classnames';

function FAQ(props: any) {
    return !props.displayInTwoColumnPage ? (
      <div id={props.linkId} className={classes.lightRedBackground}>
        <div className="container position-relative">
          <Blob className={classes.blobs} type="faqBlob" />
          <div className="row  justify-content-lg-center">
            <div className="col-lg-8 cold-md-12">
              <div className={classes["faq-content"]}>
                <Heading
                  text={props.header}
                  type="heading-2"
                  backgroundColor={props.backgroundRectangleColor.Label}
                  colorOfText="white"
                  isSkewed={true}
                ></Heading>
                <div
                  className={classes["faq-paragraph"]}
                  dangerouslySetInnerHTML={{ __html: props.text }}
                ></div>
                {props.button && (
                  <GtmLink
                    href={props.button.Url}
                    gtmCategory="User Interaction"
                    gtmAction="CTA"
                    gtmLabel={props.button.Name}
                  >
                    <Button
                      text={props.button.Name}
                      styling=""
                      icon="arrow"
                      isDisabled={false}
                    ></Button>
                  </GtmLink>
                )}
                {props?.headerIllustration?.umbracoFile && (
                  <img
                    className={classes["faq-illustration"]}
                    src={props?.headerIllustration?.umbracoFile}
                  ></img>
                )}
              </div>
            </div>
          </div>
          <div className="row justify-content-lg-center">
            <div
              className={cx(
                !props.showSubject
                  ? "col-lg-8 cold-md-12 justify-content-lg-center"
                  : "col-lg-12 cold-md-12"
              )}
            >
              {props.showSubject ? (
                <Questions
                  mobileDropdownPlaceholder={props.mobileDropdownPlaceholder}
                  subjectName={props.subjectNameArea}
                  isWithSubjects={true}
                  questions={props.subjectBlocks}
                ></Questions>
              ) : (
                <Questions
                  mobileDropdownPlaceholder={props.mobileDropdownPlaceholder}
                  subjectName={props.subjectNameArea}
                  isWithSubjects={false}
                  questions={props.subjectBlocks[0]}
                ></Questions>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div id={props.linkId}>
        <Questions
          mobileDropdownPlaceholder={props.mobileDropdownPlaceholder}
          subjectName={props.subjectNameArea}
          isWithSubjects={false}
          questions={props.subjectBlocks[0]}
        ></Questions>
      </div>
    );
}

export default FAQ;