import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export enum ChildStatuses {
    mailNontSend,
    mailSend,
    approved,
    rejected,
    blocked
}

export enum VideoStatuses {
    notuploaded,
    uploaded,
    mailSend,
    approved,
    rejected,
    approvedByModerator,
    rejectedByModerator,
    reported
}

export class PersonalVideoWidgetService {

    static getChildStatus(): Promise<ChildStatuses> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/student/getchildstatus').then((result: AxiosResponse) => {
                if (result.status === 200) {
                    const data = result.data;
                    const enumValue: ChildStatuses = (<any>ChildStatuses)[data];
                    resolve(enumValue);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static GetVideoStatus(type: string): Promise<VideoStatuses> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/student/getvideostatus', { type }).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    const data = result.data;
                    const enumValue: VideoStatuses = (<any>VideoStatuses)[data];
                    resolve(enumValue);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

}
