import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IVideosResponse } from '../../MyShop/services/MyShopService';

export class ParentPermissionService {

    static getVideoUrls(accessToken: string): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/parent/getvideourls', { accessToken }).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static setApprovalStatus(accessToken: string, type: string, approved: boolean) {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters('/umbraco/api/parent/setapprovalstatus', null, { accessToken, type, approved }).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }
}