import React from 'react';
import classes from './OverviewTable.module.scss';
import cx from 'classnames';

const OverviewTable = ({ tableHead, tableBody, className, withHover }: any) => {
    return (
        <table className={cx(className, classes.tableCollapse, { [classes.tableHover]: withHover })}>
            {
                tableHead &&
                <thead className={classes.header}>
                    {tableHead}
                </thead>
            }
            <tbody className={classes.body}>
                {tableBody}
            </tbody>
        </table>
    )
}

export default OverviewTable