import React from 'react';
import classes from  './Cards.module.scss';
import TimeLineCard from './TimeLineCard/TimeLineCard';

import cx from 'classnames';


const Cards = (props: any)  => {
    const items: any = [];
    items.push(props.data.beginItem)
    props.data.timelineItems.map((item:any)=>{
        items.push(item);
    })
    items.push(props.data.endItem);


    return (
        <>
            <div className='d-lg-none'>
                <div className={classes.mobileWrapper}> 
                    {items && items.map((card: any, index: number)=>(
                        <div key={index} id={`container_mobile_${index}`} className={classes.mobileCard}>
                            <TimeLineCard  index={index} data={card.timelinedata[1]}></TimeLineCard>
                        </div>
                    ))}
                </div>
            </div>
            <div className='d-none d-lg-block'>
                <div className={classes.wrapper}>
                    {items && items.map((card: any, index: number)=>{
                        if(index===0){
                            return(<div  key={index} id={`container_${index}`} className={classes.centerCard}>
                                <TimeLineCard index={index} data={card.timelinedata[1]}></TimeLineCard>
                            </div>)
                        }
                        else if(index%2!=0){
                            return (<div  key={index} id={`container_${index}`} className={cx(classes.leftCard, {[classes.secondCard]: index===1}, {[classes.secondCard]: index===3}, {[classes.sixthCard]: index===5} , {[classes.eightCard]: index===7})}>
                                <TimeLineCard isLeft={true}  index={index} data={card.timelinedata[1]}></TimeLineCard>
                            </div>)
                        }
                        else {
                            return (<div  key={index} id={`container_${index}`} className={cx(classes.rightCard, {[classes.thirdCard]: index===2}, {[classes.fiftCard]: index===4}, {[classes.seventhCard]: index===6}, {[classes.secondCard]: index===8})}>
                                <TimeLineCard isLeft={false}  index={index} data={card.timelinedata[1]}></TimeLineCard>
                            </div>)
                        }
                        
                    })}
                </div>
            </div>
        </>
    )
}

export default Cards;
