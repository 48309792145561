import React, { useEffect, useState, useRef, Fragment } from 'react';
import { TeacherOverviewService, ITeacherOverviewItem } from './services/TeacherOverviewService';
import OverviewTable from '../UI/OverviewTable/OverviewTable';
import classes from './TeacherOverview.module.scss';
import Button from '../UI/Button/Button';
import Number from '../UI/Number/Number';
import Loader from '../UI/Loader/Loader';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';
import cx from 'classnames';

const TeacherOverview = ({
  tableNickname,
  tableOrders,
  tableAmount,
  tableLabel,
  labelTotal,
  subTitle,
  claims,
  totalAmountOfMoney,
  totalAmountOfOrders,
  noResultBody,
  noResultImage,
  illustration,
  bottomTextTitle,
  bottomTextBody,
  completeOverviewLabel,
}: any) => {
  const [items, setItems] = useState<ITeacherOverviewItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayKidOverview, setDisplayKidOverview] = useState<boolean>(false);
  const total = useRef(0);
  const totalOrders = useRef(0);
  let groupName = '';
  let schoolName = '';

  if (claims && claims.lenght !== 0) {
    claims.forEach((claim: any) => {
      if (claim.Type === 'KPZ.Hub.Group') {
        groupName = claim.Value;
      }

      if (claim.Type === 'KPZ.Hub.School') {
        schoolName = claim.Value;
      }
    });
  }

  useEffect(() => {
    TeacherOverviewService.getOverview().then((result) => {
      let t = 0;
      let o = 0;
      result.forEach((item) => {
        t = t + item.turnover;
        o = o + item.totalOrders;
      });
      total.current = t;
      totalOrders.current = o;
      setItems(result);
      setIsLoading(false);
    });
  }, [setItems, setIsLoading]);

  return (
    <div className='container'>
      <div className='position-relative'>
        <div className={cx(classes.illustration, 'd-none d-sm-block')}>
          <img src={illustration.umbracoFile} alt='illustration' />
        </div>
        <div className={classes.componentWrapper}>
          <OverviewHeading title={subTitle} text={groupName} />
          <div className={classes.schoolInfo}>
            <span className={classes.schoolInfoItem}>
              <i className='icon-place-board'></i>
              <span>{schoolName}</span>
            </span>
            <span className={classes.schoolInfoItem}>
              <i className='icon-school-board'></i>
              <span>{groupName}</span>
            </span>
          </div>
          <div className={classes.infoBoxes}>
            <div className={classes.infoBox}>
              <div className={classes.infoBoxTitle}>{totalAmountOfMoney}</div>
              {isLoading ? <Loader type='infoBoxes' /> : <Number value={total.current} />}
            </div>
            <div className={cx(classes.infoBox, 'bg-white')}>
              <div className={classes.infoBoxTitleRight}>{totalAmountOfOrders}</div>
              {isLoading ? (
                <Loader type='infoBoxes' />
              ) : (
                <span style={{ color: '#ab221a' }}>{totalOrders.current}</span>
              )}
            </div>
          </div>
          {!displayKidOverview && (
            <Button
              className={classes.buttonKidOverview}
              styling='red btn-hover-fill'
              icon='arrow'
              isDisabled={false}
              text={completeOverviewLabel}
              onClick={() => setDisplayKidOverview(true)}
            />
          )}

          {displayKidOverview && (
            <>
              {isLoading ? (
                <Loader />
              ) : (
                <OverviewTable
                  className={classes.table}
                  withHover={true}
                  tableHead={
                    <tr className='d-none d-lg-table-row'>
                      <th className={cx(classes.numberColumn, classes.noOpacity)}>#</th>
                      <th>{tableNickname}</th>
                      <th>{tableOrders}</th>
                      <th>{tableAmount}</th>
                    </tr>
                  }
                  tableBody={
                    items &&
                    items.length !== 0 &&
                    items.map((item, index) => {
                      let row = (
                        <Fragment key={index}>
                          <tr className='d-none d-lg-table-row'>
                            <td className={classes.numberColumn}>#{index + 1}</td>
                            <td className={classes.nameColumn}>{item.NickName}</td>
                            <td>{item.totalOrders}</td>
                            <td>
                              <div className={classes.columnWithButton}>
                                <Number className={classes.turnOverColumn} value={item.turnover} />
                              </div>
                            </td>
                          </tr>
                          <tr className='d-lg-none'>
                            <td colSpan={4}>
                              <div className={classes.mobileCell}>
                                <div>
                                  <span className='font-weight-bold'>#{index + 1}</span>
                                  <span className='font-weight-bold'>
                                    {tableNickname}
                                    {item.NickName}
                                  </span>
                                </div>
                                <div className={classes.mobileCellAmount}>
                                  <span>{item.totalOrders}</span>
                                  <span>{tableLabel || 'bestellingen'}</span>
                                  <span>
                                    <Number value={item.turnover} />
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );

                      if (items.length - 1 === index && total.current) {
                        row = (
                          <Fragment key={index}>
                            {row}
                            <tr className={classes.totalRow}>
                              <td className={cx('d-none d-lg-table-cell', classes.numberColumn, classes.noOpacity)}>
                                #{index + 1}
                              </td>
                              <td colSpan={2} className={classes.totalLabel}>
                                <span className={cx('d-lg-none', classes.mobileTotalNumber)}>#{index + 1}</span>
                                {labelTotal}
                              </td>
                              <td className={classes.turnOverColumn}>
                                <Number value={total.current} />
                              </td>
                            </tr>
                          </Fragment>
                        );
                      }

                      return row;
                    })
                  }
                />
              )}
              {!isLoading && items && items.length === 0 && (
                <div className={classes.noResult}>
                  {noResultImage && <img alt='NoResult' src={noResultImage.umbracoFile} />}
                  <div dangerouslySetInnerHTML={{ __html: noResultBody }}></div>
                </div>
              )}
              {(bottomTextTitle || bottomTextBody) && (
                <div className={classes.bottomText}>
                  {bottomTextTitle && (
                    <div className={classes.bottomTextTitle}>
                      <i className='icon-information'></i>
                      {bottomTextTitle}
                    </div>
                  )}
                  {bottomTextBody}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherOverview;
