import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export interface IVideosResponse {
    welcomeVideo?: string,
    thankYouVideo?: string
}

export class MyShowService {

    static getUrl(): Promise<string> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/student/getshopurl').then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static getVideos(): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/student/getvideourls').then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }
}