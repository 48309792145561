import {useEffect} from 'react';
import classes from './VideoUploadSteps.module.scss';
import Steps from '../../Funnel/Steps/Steps';
import { Status, VideoUploadService  } from '../services/VideoUploadService';


const VideoUploadSteps = (props: any) => {

    let isRendered: boolean = false;
    let currentStepNumber = props.steps.findIndex((element: any) => element.value==props.currentStep) + 1;
    useEffect(()=>{
        VideoUploadService.getStatus().then((response)=>{
          if ((Status[response]=="1" || Status[response]=="3" || Status[response]=="4") && currentStepNumber===1){
            window.location.href = props.redirects[2]
            isRendered =true;
          }
          if (Status[response]=="4" && (currentStepNumber===3 || currentStepNumber===1)){
            window.location.href = props.redirects[2]
            isRendered =true;
          }
          if ((Status[response]=="0") && currentStepNumber===3){
            window.location.href = props.redirects[0]
            isRendered =true;
          }
          if ((Status[response]=="1" || Status[response]=="3" || Status[response]=="4") && currentStepNumber===3){
            window.location.href = props.redirects[2]
            isRendered =true;
          }
          if ((Status[response]=="2") && currentStepNumber===1){
            window.location.href = props.redirects[1]
            isRendered =true;
          }
          if(!isRendered){
            if (typeof window !== 'undefined') {
              const element = document.getElementsByClassName('isLoaded');
              while (element.length)
                element[0].classList.remove("isLoaded");
            }
          }
        })
      }, [])

    
    return (
        <div className={classes.wrapper}>
            <Steps isWide={true} allSteps={3} stepsNames={props.steps} activeStep={currentStepNumber}></Steps>
        </div>
    )
}

export default VideoUploadSteps;
