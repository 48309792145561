import React, { useState, useEffect, useCallback, useRef } from "react";
import classes from "./NavigationUser.module.scss";
import cx from "classnames";
import Button from "../../UI/Button/Button";
import { UserServices } from "../services/UserServices";
import { PopupService } from "../../Popup/PopupService";

const NavigationUserLink = ({
  redirect,
  link,
  icon,
  activeUrl,
  activeClass,
}: any) => {
  const [openPopUpState, setOpenPopUpState] = useState<boolean>(true);

  useEffect(() => {
    UserServices.getUser().then((result) => {
      if (!result.nickName) {
        setOpenPopUpState(false);
      }
    });
  }, []);

  const openPopup = () => {
    if (redirect === true && !openPopUpState) {
      PopupService.open("1");
    }
  };

  return (
    <li
      onClick={openPopup}
      className={cx({ [activeClass]: activeUrl === link.Url })}
    >
      {icon && icon.umbracoFile && <img src={icon.umbracoFile} alt="icon" />}
      <a {...(openPopUpState ? { href: link.Url } : "")}>{link.Name}</a>
    </li>
  );
};

const NavigationUser = ({
  userInfo,
  portalNavigation,
  userMenuLabel,
  logoutButton,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [markActiveUrl, setMarkActiveUrl] = useState<string>("");
  const ref = useRef<any>();

  const toggelMenu = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const menu = useCallback(() => {
    if (userInfo.role === "teacher") {
      return portalNavigation.teacherLinks.map((link: any, index: number) => (
        <NavigationUserLink
          key={index}
          {...link}
          activeUrl={markActiveUrl}
          activeClass={classes.activeMenu}
        />
      ));
    } else {
      return portalNavigation.studentLinks.map((link: any, index: number) => (
        <NavigationUserLink
          redirect={true}
          key={index}
          {...link}
          activeUrl={markActiveUrl}
          activeClass={classes.activeMenu}
        />
      ));
    }
  }, [
    markActiveUrl,
    portalNavigation.studentLinks,
    portalNavigation.teacherLinks,
    userInfo.role,
  ]);

  useEffect(() => {
    let active = "";

    if (typeof window !== "undefined") {
      active = window.location.pathname;
    }

    setMarkActiveUrl(active);
  }, [menu, setMarkActiveUrl]);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [ref]
  );

  useEffect(() => {
    if (typeof document != undefined && isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [handleClickOutside, isOpen]);

  const logout = () => {
    UserServices.logout().then((result) => {
      window.location.reload();
    });
  };

  return (
    <div ref={ref} className={classes.componentWrapper}>
      <div className={classes.component} onClick={toggelMenu}>
        <div className={classes.circle}>
          <i className="icon-user"></i>
        </div>
        <span>{userInfo.name || userMenuLabel}</span>
        <div className={cx(classes.arrow, { [classes.arrowOpen]: isOpen })}>
          <i className="icon-arrow-down"></i>
        </div>
      </div>
      <div
        className={cx(classes.menuContainer, { [classes.menuIsOpen]: isOpen })}
      >
        <ul className={classes.menuList}>{menu()}</ul>
        <div>
          <Button
            isDisabled={false}
            text={logoutButton}
            styling="red"
            className={classes.button}
            onClick={logout}
          />
        </div>
      </div>
    </div>
  );
};

export default NavigationUser;
