import { useEffect, useState, Fragment} from 'react';
import classes from  './VideoModeratingTable.module.scss';
import { VideoModeratingServices, IVideosResponse } from '../services/VideoModeratingServices';
import OverviewTable from '../../UI/OverviewTable/OverviewTable';
import Loader from '../../UI/Loader/Loader';
import Button from '../../UI/Button/Button';

import cx from 'classnames';

let tableData: IVideosResponse[];
const VideoModeratingTable = (props: any) => {
    const [tableDataSearchResults, setTableDataSearchResults] = useState<IVideosResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [personalCode, setPersonalCode] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    const redirectToDetails = (studentCode: string) => {
        window.location.href = `./videomoderate?studentCode=${studentCode}`;
    }

    useEffect(()=>{
        VideoModeratingServices.getVideos().then((response: any) => {
            tableData=response;
            setTableDataSearchResults(tableData);
            setIsLoading(false);
        })
    }, []) 
    
    const onSearch = () => {
        
        if(email ||personalCode){
            setIsLoading(true);
            const object = {
                personalCode: personalCode,
                email: email
            }
            VideoModeratingServices.searchVideos(object).then((response: any)=>{
                tableData = response.map((data: IVideosResponse)=>{
                    return {
                        videodetails : data,
                        nickname: ""
                    }
                });
                setTableDataSearchResults(tableData);
                setIsLoading(false);
            }).catch((error)=>{
                setIsLoading(false);
                console.log(error);
            })
        }

    }

    const onInputPersonalCodeChange = (event:any) => {
        setPersonalCode(event.target.value);
    }

    const onInputEmailChange = (event:any) => {
        setEmail(event.target.value);
    }

    return (
        <div className="container">
            <div className="d-flex">
                <div className={classes.label}>{props.searchLabel}</div>
                <input disabled={isLoading} onChange={(event)=>onInputPersonalCodeChange(event)} className={classes.input} placeholder={props.searchPlaceholder}></input>
                <input disabled={isLoading} onChange={(event)=>onInputEmailChange(event)} className={classes.input} placeholder={props.searchPlaceholderEmail}></input>
                <Button onClick={onSearch} text={props.searchButton} icon="arrow" styling="" isDisabled={false} className=""></Button>
                <Button onClick={()=>{window.location.href="./randomlist"}} text={props.navigateTo} icon="arrow" styling="" isDisabled={false} className={classes.button}></Button>
            </div>
                        {isLoading ? <Loader/>: <OverviewTable
                                className={classes.table}
                                tableHead={(
                                    <tr className='d-none d-lg-table-row'>
                                        <th className={classes.tableHead}>{props.title}</th>
                                        <th className={classes.tableHead}>{props.personalCode}</th>
                                        <th className={classes.tableHead}>{props.parentEmailColum}</th>
                                        <th className={classes.tableHead}>{props.inappropriateReportsWelcomeColumn}</th>
                                        <th className={classes.tableHead}>{props.inappropriateReportsThankYouColumn}</th>
                                    </tr>
                                )}
                                tableBody={
                                    tableDataSearchResults && tableDataSearchResults.length !== 0 &&
                                    tableDataSearchResults.map((item) => {
                                        let row = (
                                            <Fragment key={item.videodetails.personalCode}>
                                                <tr  onClick={() => redirectToDetails(item.videodetails.personalCode)} className={cx(classes.tableRow,'d-none d-lg-table-row')}>
                                                    <td className={classes.typeColumn}>
                                                        {item.nickname}
                                                    </td>
                                                    <td className={classes.typeColumn}>
                                                        {item.videodetails.personalCode}
                                                    </td>
                                                    <td className={classes.typeColumn}>
                                                        {item.videodetails.videos_ApprovalEmail}
                                                    </td>
                                                    <td className={classes.typeColumn}>
                                                        {item.videodetails.welcomeVideo_InappropriateCount}
                                                    </td>
                                                    <td className={classes.typeColumn}>
                                                        {item.videodetails.thankYouVideo_InappropriateCount}
                                                    </td>
                                                </tr>
                                                <tr  className='d-lg-none'>
                                                    <td colSpan={3}>
                                                        <div className={classes.mobileRow}>
                                                            <div className={classes.typeColumn}>
                                                                {item.nickname}
                                                            </div>
                                                            <div className={classes.typeColumn}>
                                                                {item.videodetails.personalCode}
                                                            </div>
                                                            <div className={classes.typeColumn}>
                                                                {item.videodetails.videos_ApprovalEmail}
                                                            </div>
                                                            <div className={classes.typeColumn}>
                                                                {item.videodetails.welcomeVideo_InappropriateCount}
                                                            </div>
                                                            <div className={classes.typeColumn}>
                                                                {item.videodetails.thankYouVideo_InappropriateCount}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                            ) 
                                    return row
                                    })
                                }
                            />
                        }
        </div>
    )
}

export default VideoModeratingTable;
