import React from 'react';
import classes from './Loader.module.scss';
import cx from 'classnames';

type LoaderProps = {
    type?: string
}

const Loader = ({ type } : LoaderProps) => {
    let styles = '';

    switch (type) {
        case 'infoBoxes':
            styles = classes.infoBoxesLoader
            break;
        case 'video-upload':
            styles = classes.videoUpload
            break;   
        case 'nickname':
            styles = classes.nickname;
            break
        default:
            break;
    }

    return (
        <div className={cx(classes.loader, styles)}></div>
    )
}

export default Loader
