import classes from  './TimeLineCard.module.scss';
import Heading from '../../../UI/Heading/Heading';
import Button from '../../../UI/Button/Button';
import TimeLineCardHeading from '../TimeLineCardHeading/TimeLineCardHeading';
import cx from 'classnames';
import GtmLink from '../../../UI/GtmLink/GtmLink';



const TimeLineCard = (props: any)  => {
    return (
            <div className={cx(classes.card, {[classes.firstCard]: props.index===0})}>
                {props.data.illustration && <img className={classes.illustration} src={props.data.illustration.umbracoFile}></img>}
                {props.data.image && <div className={cx(classes['card-image-wrapper'], {[classes.firstCardImage] : props.index===0})}><img className={classes['card-image']} src={props.data.image.umbracoFile} /> </div>}
                <div className={classes['card-content']}>
                    <TimeLineCardHeading isWithPicture={props.data.image} isFirst={props.index===0} isLeft={props.isLeft} title={props.data.title} subtitle={props.data.subtitle} optionalSubtitle={props.data.optionalSubtitle}></TimeLineCardHeading>
                    <Heading  text={props.data.contentTitle} type="heading-4" backgroundColor="" colorOfText="gray-dark" isSkewed={false} ></Heading>
                    <div className={classes['card-paragraph']} dangerouslySetInnerHTML={{ __html: props.data.text }}></div>
                    {props.data.link  &&
                    <div className='d-flex'>
                        <GtmLink target={true} gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={props.data.link.Name} className={classes.link} href={props.data.link.Url}>
                            <div className={classes['card-button-left']}>
                                <Button text="" styling="noTextArrow" icon="" isDisabled={false}></Button> 
                                <span className={cx( props.index===0 ? classes['card-first-link'] : classes['card-link'])}>
                                    {props.data.link.Name}
                                </span>
                            </div>
                        </GtmLink>
                    </div>}
                </div>
            </div>
    )
}

export default TimeLineCard;
