import React, { useState, useEffect } from 'react';
import classes from './TeachersNavigation.module.scss';
import DropDown from '../UI/DropDown/DropDown';
import cx from 'classnames';
import { components } from 'react-select';
import GtmLink from '../UI/GtmLink/GtmLink';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';

const TeachersNavigation = ({ title, links }: any) => {
  const [activeLink, setActiveLink] = useState<any>();

  useEffect(() => {
    let active;

    if (typeof window !== 'undefined') {
      const path = window.location.pathname;
      links.some((link: any) => {
        if (link.link.Url === path || (link.link.Url.slice(-1) === '/' && path === link.link.Url.slice(0, -1))) {
          active = {
            label: link.link.Name,
            value: link.link.Url,
            iconUrl: link.whiteIcon && link.whiteIcon.umbracoFile,
          };
        }

        return false;
      });
    }

    setActiveLink(active);
  }, [links, setActiveLink]);

  const onChangeMenu = (value: any) => {
    window.location.href = value;
  };

  return (
    <div>
      <div className='container'>
        <div className={classes.header}>
          <OverviewHeading title={title} titleClassName={classes.title} />
        </div>
        <div className={classes.mobileDropDown}>
          <DropDown
            type='teacherNavigation'
            isSearchable={false}
            options={links.map((l: any) => ({
              label: l.link.Name,
              value: l.link.Url,
              iconUrl: l.whiteIcon && l.whiteIcon.umbracoFile,
            }))}
            onChange={(e: any) => onChangeMenu(e.value)}
            value={activeLink}
            placeholder={title}
            components={{
              SingleValue: ({ children, data, ...props }: any) => {
                return (
                  <components.SingleValue data={data} {...props}>
                    {data && data.iconUrl && <img className={classes.iconDropDown} src={data.iconUrl} />}
                    {children}
                  </components.SingleValue>
                );
              },
            }}
          />
        </div>
        <div className={classes.desktopNavigation}>
          {links.map((link: any, index: number) => (
            <GtmLink
              gtmCategory='User Interaction'
              gtmAction='CTA'
              gtmLabel={link.link.Name}
              key={index}
              href={link.link.Url}
              target={link.link.Target}
              className={cx(classes.link, { [classes.active]: activeLink && activeLink.value === link.link.Url })}>
              {link && link.whiteIcon && <img src={link.whiteIcon.umbracoFile} />}
              <span>{link.link.Name}</span>
            </GtmLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeachersNavigation;
