import classes from './ImageVideo.module.scss';

import Blob from '../UI/Blob/Blob';
import YouTube from 'react-youtube';
import Vimeo from '../UI/Vimeo/Vimeo';
import { Fragment } from 'react';

function ImageVideo(props: any) {

  const videoPlay = (data: any) => {
    let title = '';

    if (data.target && data.target.getVideoData) {
      title = data.target.getVideoData().title;
    }

    const gtmObject: any = {
      'event': 'Video',
      'category': 'Video',
      'action': 'Start video',
      'label': title
    };

    if ((window as any).dataLayer) {
      (window as any).dataLayer.push(gtmObject);
    }
  }

  return (
    !props.displayOnTwoColumnPage ?
      <div id={props.linkId} className={classes.Wrapper}>
        <div className={classes.backPanel}></div>
        <div className="container position-relative">
          <Blob className={classes.blob} type='video'></Blob>
          <div className={classes.Content}>
            {
              props.upperIllustration &&
              <img className={classes.UpperIllustration} src={props.upperIllustration.umbracoFile}></img>
            }
            <div className="embed-responsive embed-responsive-16by9">
              {
                props.embedCode ?
                  <Fragment>
                    {
                      props.videoType == "Vimeo" ?
                        <Vimeo className="embed-responsive-item" video={props.embedCode} onPlay={videoPlay} /> :
                        <YouTube className="embed-responsive-item" videoId={props.embedCode} onPlay={videoPlay} />
                    }
                  </Fragment> :
                  <img className="embed-responsive-item" src={props.imageUrl}></img>
              }
            </div>
            {props.text ? <div className={classes.Text}>{props.text}</div> : null}
            {
              props.bottomIllustration &&
              <img className={classes.BottomIllustration} src={props.bottomIllustration.umbracoFile}></img>
            }
          </div>
        </div>
      </div> :
      <div id={props.linkId} className={classes.videoPanel}>
        <div className="embed-responsive embed-responsive-4by3">
          {
            props.embedCode ?
              <Fragment>
                {
                  props.videoType == "Vimeo" ?
                    <Vimeo className="embed-responsive-item" video={props.embedCode} onPlay={videoPlay} /> :
                    <YouTube className="embed-responsive-item" videoId={props.embedCode} onPlay={videoPlay} />
                }
              </Fragment> :
              <img className="embed-responsive-item" src={props.image.umbracoFile}></img>
          }
        </div>
        {
          props.bottomIllustration &&
          <img className={classes.smallVideoIllustration} src={props.bottomIllustration.umbracoFile}></img>
        }
      </div>
  )
}

export default ImageVideo;
