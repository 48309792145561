import classes from  './StraightCardRow.module.scss';

import Card from '../UI/Card/Card';


import cx from 'classnames'; 


function StraightCardRow(props: any) {
    let cardsRow = props.straightCardItems.map((card: any, index: number) => {
        return (
            card.body == "" || card.header== "" || card.Link == null ? null :
            <div key={index} className={cx(classes['card-wrapper'], "col-lg-3", "col-md-6")}>
                <Card styling="card-fixed" link={card.Link.Url} heading={card.header} paragraph={card.body} butonText={card.Link.Name} image={null} type="buttonLink"></Card>
            </div>
            )
    })

    return (
        <div id={props.linkId} className="bg-light-blue">
          <div className="container">
              <div className="row ">
                  {cardsRow}
              </div>
          </div>      
        </div>  
      )
  }
  
export default StraightCardRow;