import classes from './Button.module.scss';

import cx from 'classnames';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  text: string;
  styling?: string;
  icon?: string;
  isDisabled: boolean;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
};

function Button({ type, text, styling, icon, isDisabled, className, isLoading, onClick, ariaLabel }: ButtonProps) {
  let buttonType: string = '';
  let iconType: string = '';

  switch (styling) {
    case 'noTextPlaceBoard':
      buttonType = 'no-text-placeboard';
      break;
    case 'noTextArrow':
      buttonType = 'no-text-arrow';
      break;
    case 'noTextArrowDarkOrange':
      buttonType = 'no-text-arrow-dark-orange';
      break;
    case 'grey':
      buttonType = 'button-grey';
      break;
    case 'red':
      buttonType = 'button-red';
      break;
    case 'dark-red':
      buttonType = 'button-dark-red';
      break;
    case 'yellow':
      buttonType = 'button-yellow';
      break;
    case 'yellow-cyan':
      buttonType = 'button-yellow-cyan';
      break;
    case 'green':
      buttonType = 'button-green';
      break;
    case 'dark-green':
      buttonType = 'button-dark-green';
      break;
    case 'white':
      buttonType = 'button-white';
      break;
    case 'btn-wide':
      buttonType = 'btn-wide';
      break;
    case 'btn-flat':
      buttonType = 'btn-flat';
      break;
    case 'btn-hover-fill':
      buttonType = 'btn-hover-fill';
      break;
    case 'yellow-noarrow':
      buttonType = 'button-yellow-noarrow';
      break;
    default:
      buttonType = '';
  }

  switch (icon) {
    case 'arrow':
      iconType = 'arrow-link';
      break;
    case 'placeboard':
      iconType = 'placeboard-link';
      break;
    case 'check':
      iconType = 'check';
      break;
    case 'whatsapp':
      iconType = 'whatsapp';
      break;
    case 'mail':
      iconType = 'mail';
      break;
    case 'duplicate':
      iconType = 'duplicate';
      break;
    case 'eye':
      iconType = 'eye';
      break;
    case 'eye-off':
      iconType = 'eye-off';
      break;
    default:
      iconType = '';
  }

  return (
    <button
      type={type}
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={isDisabled ? isDisabled : false}
      className={cx(
        classes['button-pill'],
        !isLoading ? classes[iconType] : classes['btn-loader'],
        classes[buttonType],
        className
      )}>
      <span>{text}</span>
    </button>
  );
}

export default Button;
