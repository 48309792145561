import { useState, useEffect } from 'react';
import Select from 'react-select'
import { useFormContext, useController } from 'react-hook-form';
import classes from './AutoCompleteDropDown.module.scss';

function AutoCompleteDropDown(props: any) {
  const [search, setSearch] = useState([]);
  const { field } = useController({ name: 'school' });
  const [value, setValue] = useState(field.value);

  const customStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    control: (provided: any, state: any) => ({
      ...provided,
      boxShadow: "none",
      borderColor: state.isFocused ? 'rgb(0, 159, 218) !important' : 'none'
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: () => ({
      fontSize: 16,
      fontWeight: 700,
      opacity: 0.5
    }),
    option: (provided: any, state: any) => ({
      padding: "16px 25px",
      color: "#252525",
      fontWeight: 700
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "8px",
      marginTop: "3px",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)",
    }),
    singleValue: () => ({
      color: "#252525",
      fontWeight: 700
    })
  }

  useEffect(() => {
    let dataArray: any = [];
    props.data.map((value: any) => { dataArray.push({ value, label: value }) })
    setSearch(dataArray)
  }, [props])

  const suggestionSelected = (value: any) => {
    field.onChange(value.value)
    props.isOptionSelected(value.value);
    setValue(value.value)
  };

  return (
    <div className={classes.Wrapper}>
      <div className="form-group">
        <label>{props.dropDownLabel}</label>
        {
          typeof window !== "undefined" &&
          <Select
            classNamePrefix='react-select'
            className='basic-single'
            menuPortalTarget={document.body}
            styles={customStyles}
            placeholder={!!props.dropdownPlaceholder1 ? props.dropdownPlaceholder1 : "Typ hier je plaatsnaam"}
            options={search}
            onChange={e => { suggestionSelected(e) }} />
        }
      </div>
    </div>
  )
}

export default AutoCompleteDropDown;
