import { useEffect, useState } from 'react';
import classes from  './VideoModerate.module.scss';
import { VideoModeratingServices, IVideosResponse } from '../services/VideoModeratingServices';
import Button from '../../UI/Button/Button';
import DropDown from '../../UI/DropDown/DropDown';


const STATUSES = ["NotSet", "ParentDisapproved", "ModDisapproved", "ParentsApproved", "ModApproved"];

function VideoModerate(props: any) {
    const [childData, setChildData] = useState<IVideosResponse | any>();

    useEffect(() => {
        let studentCode: string = '';

        if (typeof window !== "undefined") {
            const params = new URLSearchParams(window.location.search);
            studentCode = params.get('studentCode') as string;
        }

        if (studentCode) {
            const payload = {
                personalCode : studentCode
            }
            VideoModeratingServices.getVideosForChild(payload).then(result => {
                setChildData(result);
            })
        }
    }, [])

    const onChangeVideoStatus = (isThankYou: boolean, value: string) => {
        let studentCode: string = '';

            if (typeof window !== "undefined") {
                const params = new URLSearchParams(window.location.search);
                studentCode = params.get('studentCode') as string;
            }

            const payload = {
                personalCode: studentCode,
                status: value,
                type: isThankYou? "ThankYou" : "Welcome"
            }

            VideoModeratingServices.setVideoStatus(payload).then((result) => {
                window.location.reload();
            })
    }

    
    const onChangeGeneralSubject = (value: string) => {
        let studentCode: string = '';
        if (typeof window !== "undefined") {
            const params = new URLSearchParams(window.location.search);
            studentCode = params.get('studentCode') as string;
        }

        const payload = {
            personalCode: studentCode,
            status: value
        }
        
        VideoModeratingServices.setGeneralApproval(payload).then((result) => {
            window.location.reload();
        })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Button onClick={() => window.history.back()} text="Go Back" styling="" icon="arrow" isDisabled={false}></Button>
                    <div className={classes.title}>{props.title}</div>
                    <DropDown 
                        options={STATUSES.filter((result: any)=>(result!="ModApproved")).map((s: any) => ({ label: s, value: s }))} 
                        placeholder={props.setGeneralSatusButton}
                        isSearchable={false}
                        onChange={(e: any) => onChangeGeneralSubject(e.value)}
                    />
                    <div className={classes.field}>{childData?.videodetails.videos_ApprovalStatus}</div>
                    <div className={classes.field}>{props.personalCode}: <span>{childData?.videodetails.personalCode}</span></div>
                    <div className={classes.field}>{props.parentEmail}: <span>{childData?.videodetails.videos_ApprovalEmail}</span></div>
                    <div className={classes.field}>{props.inappropriateReports}: <span>{childData?.videodetails.welcomeVideo_InappropriateCount}</span></div>
                </div>
            </div>
            <div className="row">
                {childData?.videodetails.welcomeVideo &&<div className="col-lg-6 col-md-6 col-12">
                     <video className={classes.videoWrapper} controls>
                        <source src={childData?.videodetails.welcomeVideo} type="video/mp4"/>
                    </video>
                </div>}
                {childData?.videodetails.welcomeVideo &&<div className="col-lg-6 col-md-6 col-12">
                    <DropDown 
                        options={STATUSES.map((s: any) => ({ label: s, value: s }))} 
                        placeholder={props.setWelcomVideostausButton}
                        isSearchable={false}
                        onChange={(e: any) => onChangeVideoStatus(false, e.value)}
                    />
                    <div className={classes.field}>{childData?.videodetails.welcomeVideo_ApprovalStatus}</div>
                    <div className={classes.field}>{childData?.videodetails.welcomeVideo_InappropriateCount}</div>  
                </div>}
                {childData?.videodetails.thankYouVideo && <div className="col-lg-6 col-md-6 col-12">
                     <video  className={classes.videoWrapper} controls>
                        <source src={childData?.videodetails.thankYouVideo} type="video/mp4"/>
                    </video>
                </div>}
                {childData?.videodetails.thankYouVideo && <div className="col-lg-6 col-md-6 col-12">
                    <DropDown 
                        options={STATUSES.map((s: any) => ({ label: s, value: s }))} 
                        placeholder={props.setThankYouVideostausButton}
                        isSearchable={false}
                        onChange={(e: any) => onChangeVideoStatus(true, e.value)}
                    />
                    <div className={classes.field}>{childData?.videodetails.thankYouVideo_ApprovalStatus}</div>
                    <div className={classes.field}>{childData?.videodetails.thankYouVideo_InappropriateCount}</div>  
                </div>}
            </div>
        </div>
        )
}

export default VideoModerate;
