import classes from  './UploadingVideo.module.scss';
import {useState} from 'react';
import VideoUpload from './VideoUpload/VideoUpload';
import Button from '../../UI/Button/Button';
import Blob from '../../UI/Blob/Blob';

function UploadingVideo(props: any) {
  const [welcomeApproved, setWelcomeApproved] = useState<boolean>(false);
  const [thankYouApproved, setThankYouApproved] = useState<boolean>(false);

  const isWelcomeApproved = () => {
    setWelcomeApproved(true);
  }
  const isThankYouApproved = () => {
    setThankYouApproved(true);
  }

  return (
    <div className={classes.wrapper}>
        <Blob className={classes.blob} type='video-upload'></Blob>
        <div className={classes.title}>{props.title}</div>
        <div className={classes.content} dangerouslySetInnerHTML={{ __html: props.text }}></div>
        <VideoUpload 
          rejectedTitle={props.moderatorRejectedTitle} 
          rejectedText={props.moderatorRejectedText}
          waitingForApprovalTitle={props.waitingForApprovalResultTitle}
          waitingForApprovalText={props.waitingForApprovalResultText}
          approvedVideoText={props.approvedVideoText}
          parentRejectedTitle={props.parentRejectedVideoTitle}
          parentRejectedText={props.parentRejectedVideoText}
          moderatorRejectedText={props.moderatorRejectedText}
          moderatorRejectedTitle={props.moderatorRejectedTitle}
          error={props.wrongSizeAndTypeOfVideoError}
          wrongEmailMessage={props.wrongEmailMessage}
          isWelcome={true}
          isApproved = {() => isWelcomeApproved()} 
          title={props.welcomeVideoTitle} 
          textButton={props.uploadVideoButton} 
          subtitle={props.uploadVideoSubtitle}
          buttonName={props.sendEmailButtonText}>
        </VideoUpload>
        <VideoUpload 
          rejectedTitle={props.moderatorRejectedTitle} 
          rejectedText={props.moderatorRejectedText}
          waitingForApprovalTitle={props.waitingForApprovalResultTitle}
          waitingForApprovalText={props.waitingForApprovalResultText}
          approvedVideoText={props.approvedVideoText}
          parentRejectedTitle={props.parentRejectedVideoTitle}
          parentRejectedText={props.parentRejectedVideoText}
          moderatorRejectedText={props.moderatorRejectedText}
          moderatorRejectedTitle={props.moderatorRejectedTitle}
          error={props.wrongSizeAndTypeOfVideoError}
          wrongEmailMessage={props.wrongEmailMessage}
          isWelcome={false}
          isApproved = {() => isThankYouApproved()} 
          title={props.thankYouVideoTitle} 
          textButton={props.uploadVideoButton} 
          subtitle={props.uploadVideoSubtitle}
          buttonName={props.sendEmailButtonText}>
        </VideoUpload>
          {welcomeApproved && thankYouApproved && <a href={props.myVideoPageButton.Url}><Button text={props.myVideoPageButton.Name} icon="arrow" styling="" isDisabled={false} className={classes.pageButton}></Button></a>}
    </div>
  );
}

export default UploadingVideo;
