import React, { Fragment, useEffect, useState } from 'react';
import { KidSalesService, IKidDetails } from './services/KidSalesService';
import classes from './KidSales.module.scss';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';
import OverviewTable from '../UI/OverviewTable/OverviewTable';
import Number from '../UI/Number/Number';
import Loader from '../UI/Loader/Loader';
import cx from 'classnames';
import GtmLink from '../UI/GtmLink/GtmLink';

const KidSales = ({
    backButton,
    subTitle,
    kidsNameEmotion,
    tableAmount,
    tableTypeOfSale,
    digitalOrder,
    labelTotal,
    illustration,
    paperOrder
}: any) => {
    const [kidDetails, setKidDetails] = useState<IKidDetails>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        let studentCode: string = '';

        if (typeof window !== "undefined") {
            const params = new URLSearchParams(window.location.search);
            studentCode = params.get('studentCode') as string;
        }

        if (studentCode) {
            KidSalesService.getDetails(studentCode).then(result => {
                setKidDetails(result);
                setIsLoading(false);
            })
        }
    }, [])

    return (
        <div className='container'>
            <div className='position-relative'>
            <div className={cx(classes.illustration, 'd-none d-sm-block')}>
                <img src={illustration.umbracoFile} alt='illustration' />
            </div>
            <div className={classes.componentWrapper}>
                {
                    backButton &&
                    <GtmLink gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={backButton.Name} className={classes.backButtonStyle} href={backButton.Url} target={backButton.Target}><i className='icon-place-board-left'></i><span>{backButton.Name}</span></GtmLink>
                }
                {
                    isLoading ?
                        <Loader /> :
                        <Fragment>
                            <OverviewHeading title={subTitle} text={
                                <div className={classes.kidName}>
                                    <img src={kidsNameEmotion.umbracoFile} alt='image' />
                                    <span>{kidDetails?.name}</span>
                                    <span className={classes.kidNameCode}>({kidDetails?.code})</span>
                                </div>
                            } />
                            <OverviewTable
                                className={classes.table}
                                tableHead={(
                                    <tr className='d-none d-lg-table-row'>
                                        <th className={cx(classes.numberColumn, classes.noOpacity)}>#</th>
                                        <th>{tableTypeOfSale}</th>
                                        <th>{tableAmount}</th>
                                    </tr>
                                )}
                                tableBody={
                                    kidDetails && kidDetails.orders && kidDetails.orders.length !== 0 &&
                                    kidDetails.orders.map((order, index) => {
                                        let row = (
                                            <Fragment key={index}>
                                                <tr className='d-none d-lg-table-row'>
                                                    <td className={cx(classes.numberColumn, 'text-dark')}>#{index + 1}</td>
                                                    <td className={classes.typeColumn}>
                                                        {
                                                            order.IsPaperOrder ?
                                                            <div className={classes.typePaperWithIcon}>{paperOrder}</div> :
                                                            <div className={classes.typeWithIcon}>{digitalOrder}</div>
                                                        }
                                                    </td>
                                                    <td className={classes.turnOverColumn}>
                                                        <Number
                                                            value={order.turnover}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr className='d-lg-none'>
                                                    <td colSpan={3}>
                                                        <div className={cx(classes.mobileRow, { [classes.mobilePaperRow]: order.IsPaperOrder })}>
                                                            <div>
                                                                <div className={classes.rowNumberMobile}>#{index + 1}</div>
                                                                <div className={classes.typeColumn}>{order.IsPaperOrder ? paperOrder : digitalOrder}</div>
                                                                <div className={classes.turnOverColumn}>
                                                                    {tableAmount}:
                                                                    <Number
                                                                        value={order.turnover}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )

                                        if (kidDetails.orders.length - 1 === index) {
                                            row = (
                                                <Fragment key={index}>
                                                    {row}
                                                    <tr className={classes.totalRow}>
                                                        <td className={cx('d-none d-lg-table-cell', classes.numberColumn, classes.noOpacity)}>#{index + 1}</td>
                                                        <td className={classes.totalLabel}>
                                                            <span className={cx('d-lg-none', classes.mobileTotalNumber)}>#{index + 1}</span>
                                                            {labelTotal}
                                                        </td>
                                                        <td className={classes.turnOverColumn}>
                                                            <Number
                                                                value={kidDetails.turnover}
                                                            />
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            )
                                        }

                                        return row;
                                    })
                                }
                            />
                        </Fragment>
                }
            </div>
            </div>
        </div>
       
    )
}

export default KidSales
