import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export interface IVideosResponse {
    videodetails : {
        personalCode: string,
        videos_ApprovalStatus: string,
        videos_ApprovalDate: Date,
        welcomeVideo: string,
        thankYouVideo: string,
        videos_ApprovalEmail: string,
        welcomeVideo_InappropriateCount: number,
        welcomeVideo_ApprovalStatus: string,
        thankYouVideo_InappropriateCount: number,
        thankYouVideo_ApprovalStatus: string
    },
    nickname:string
}

export class VideoModeratingServices {

    static getVideos(): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/common/getinappropriatevideos').then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static getVideosForChild(payLoad: any): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get(`/umbraco/api/common/getvideourls`, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static moderateVideo(payLoad: any): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters(`/umbraco/api/common/SetModeratorApprovalStatus`,null, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    
    static rejectVideo(payLoad: any): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters(`/umbraco/api/common/rejectgeneralapproval`,null, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

        
    static searchVideos(payLoad: any): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters(`/umbraco/api/common/searchvideos`, null, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static getRandomVideos(): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/common/getrandomvideos').then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

            
    static setGeneralApproval(payLoad: any): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters(`/umbraco/api/common/setgeneralapproval`, null, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static setVideoStatus(payLoad: any): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters(`/umbraco/api/common/setmoderatorvideostatus`, null, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

}