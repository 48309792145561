import { useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form";

import Button from '../../../UI/Button/Button';
import ReactSelect from '../../Shared/Select/Select';

import { FunnelService } from '../../services/FunnelService';

import classes from './StepTwoKid.module.scss';


import cx from 'classnames';


function StepTwoKid(props:any) {
  const [groups, setGroups] = useState(null);  


  const methods = useForm();

  useEffect (()=>{
    const schoolId = localStorage?.getItem('schoolId');
    FunnelService.getGroups(schoolId)
    .then((response:any)=>{
      setGroups(response);
    }).catch(function (error) {
      console.log(error);
    })
  }, [])

  const onSubmit = (data: any) => {
    localStorage?.setItem('group', data.select.value);
    
      const gtmObject: any = {
          'event': 'User Interaction',
          'category': 'User Interaction',
          'action': 'CTA',
          'label': props.button.Name
      };

      if ((window as any).dataLayer) {          
          (window as any).dataLayer.push(gtmObject);
          window.location.href = props.nextStep.Url;
          return;
      }

    window.location.href = props.nextStep.Url;
  };
  return (
    <FormProvider {...methods} >
        <form className={classes.Wrapper} onSubmit={methods.handleSubmit(onSubmit)}> 
           <div className={cx("form-group", classes.AutoComplete)} >

            <label>{props.autoCompleteDropDown}</label>
            <ReactSelect isSchool={false} options={groups}/>        
            </div>
            <div className={classes.Button}><Button text={props.button.Name} icon="arrow" styling="btn-wide" isDisabled={false} className=""></Button></div>
        </form>
    </FormProvider>
  )
}

export default StepTwoKid;
