import React, { useEffect } from 'react';
import classes from './GameSubmission.module.scss';
import OverviewHeading from '../UI/OverviewHeading/OverviewHeading';
import { setTimeout } from 'timers';



const GameSubmission = (props: any) => {
    useEffect(() => {
        const form = document.querySelector('form') as HTMLFormElement;

        const triggerGTM = (e: any) => {
            const button = form.querySelector('[type="submit"]');
            button?.classList.add('btn-loader');

            if ((window as any).dataLayer) {
                const gtmObject: any = {
                    'event': 'Form',
                    'category': 'Form',
                    'action': 'Submit',
                    'label': props.gtmFormName,
                };

                (window as any).dataLayer.push(gtmObject);
            }

            setTimeout(() => {
                let errorField = form.querySelector('.field-validation-error');

                if (errorField !== null) {
                    button?.classList.remove('btn-loader');
                }
            }, 500);
        };

        if (form) {
            form.addEventListener('submit', triggerGTM)
        }

        return () => {
            if (form) {
                form.removeEventListener('submit', triggerGTM)
            }
        }
    }, [])

    return (
        <div className="container" id={props.linkId}>
            <div className={classes.wrapper}>
                <OverviewHeading text={props.title} title={props.subtitle} titleClassName={classes.title} textClassName={classes.textHeading}></OverviewHeading>
            </div>
            <div className={classes.text} dangerouslySetInnerHTML={{__html: props.text}}></div>
        </div>
    )
}

export default GameSubmission;
