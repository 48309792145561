import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IVideosResponse } from '../../MyShop/services/MyShopService';

export class ReportAsInappropriateService {

    static getVideoUrls(object: any): Promise<IVideosResponse> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/parent/getvideourls', object).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static setReportedStatus(object: any) {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters('/umbraco/api/common/IncreaseInAppropriateCount', null, object).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }
}