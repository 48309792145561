import React from 'react';
import classes from './HomePageHeader.module.scss';
import HomePageHeaderButton from './HomePageHeaderButton/HomePageHeaderButton';
import Blob from '../UI/Blob/Blob';

import cx from 'classnames';

const HomePageHeader = (props: any) => {
  return (
    <div id={props.linkId} className='container'>
      <div className='d-lg-none'>
        <div className={classes.wrapper}>
          <div className={classes.imageWrapper}>
            <Blob type='homepage-header' className={classes.blob} />
            {props.illustration1 && <img className={classes.illustration1} src={props.illustration1.umbracoFile}></img>}
            {props.illustration2 && <img className={classes.illustration2} src={props.illustration2.umbracoFile}></img>}
            {props.illustration3 && <img className={classes.illustration3} src={props.illustration3.umbracoFile}></img>}
            <a
              {...(props.imageLink && {
                href: props.imageLink.Url,
                target: props.imageLink.Target,
              })}>
              {props.image && <img className={classes.image} src={props.image.umbracoFile}></img>}
            </a>
          </div>
          <div className={classes.title}>{props.title}</div>
          <div className={classes.buttons}>
            {props.buttonNestedContent.map((button: any, index: number) => (
              <HomePageHeaderButton
                key={index}
                title={button.title}
                subtitle={button.subtitle}
                icon={button.icon}
                link={button.link}></HomePageHeaderButton>
            ))}
          </div>
        </div>
      </div>
      <div className='d-none d-lg-block'>
        <div className={cx('row', classes.marginBottom)}>
          <div className='col-md-5 cold-lg-5'>
            <div className={classes.title}>{props.title}</div>
            <div className={classes.buttons}>
              {props.buttonNestedContent.map((button: any, index: number) => (
                <HomePageHeaderButton
                  key={index}
                  title={button.title}
                  subtitle={button.subtitle}
                  icon={button.icon}
                  link={button.link}></HomePageHeaderButton>
              ))}
            </div>
          </div>
          <div className='col-md-5 cold-lg-5 offset-md-1'>
            <div className={classes.imageWrapper}>
              <Blob type='video-carousel' className={classes.blob} />
              {props.illustration1 && (
                <img className={classes.illustration1} src={props.illustration1.umbracoFile}></img>
              )}
              {props.illustration2 && (
                <img className={classes.illustration2} src={props.illustration2.umbracoFile}></img>
              )}
              {props.illustration3 && (
                <img className={classes.illustration3} src={props.illustration3.umbracoFile}></img>
              )}
              <a
                {...(props.imageLink && {
                  href: props.imageLink.Url,
                  target: props.imageLink.Target,
                })}>
                {props.image && <img className={classes.image} src={props.image.umbracoFile}></img>}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageHeader;
