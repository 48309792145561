import { useState } from 'react';

export class PopupService {
  private static isOpen: boolean;
  private static arrFunc: any[] = [];

  static open(id: string) {
    this.isOpen = true;

    if (this.arrFunc.length !== 0) {
      this.arrFunc.forEach((fn: (id: string) => void) => fn(id));
    }
  }

  static getIsOpen() {
    return this.isOpen;
  }

  static onOpen(func: any) {
    this.arrFunc.push(func);
  }
}
