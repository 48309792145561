import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export interface ICity {
    city: string;
}

export interface ISchool {
    id: number;
    name: string;
    groups: any;
}

export class FunnelService {

    static getCities(): Promise<ICity[]> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/common/getcities').then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static getSchools(city: string): Promise<ISchool[]> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get(`/umbraco/api/common/getschools?city=${city}`).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static login(payLoad: any): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.post(`/umbraco/api/authentication/login`, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static getGroups(schoolId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get(`/umbraco/api/common/getgroups?schoolId=${schoolId}`).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    

}