import { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import Button from '../../../UI/Button/Button';
import ReactSelect from '../../Shared/Select/Select';
import MultiInput from '../../Shared/MultiInput/MultiInput';

import { FunnelService } from '../../services/FunnelService';

import classes from './TeacherEnd.module.scss';

import cx from 'classnames';

function TeacherEnd(props: any) {
  const [groups, setGroups] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeWrong, setIsCodeWrong] = useState(false);
  const [staySignedIn, setStaySignedIn] = useState<boolean>(true);
  const methods = useForm();
  const watchAllFields = methods.watch();

  const headers = {
    'Access-Control-Allow-Origin': '*',
  };

  useEffect(() => {
    let flag: boolean = false;
    if (watchAllFields.multiInput == undefined) {
      setIsDisabled(true);
    } else {
      watchAllFields.multiInput.map((input: any, index: number) => {
        if (index <= 4) {
          if (input.value === '' || watchAllFields.select === undefined) {
            flag = true;
          }
        }
      });
    }
    setIsDisabled(flag);
  }, [watchAllFields]);

  useEffect(() => {
    const schoolId = localStorage?.getItem('schoolId');
    localStorage?.removeItem('schoolId');
    FunnelService.getGroups(schoolId)
      .then((response: any) => {
        setGroups(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    setIsDisabled(true);
    let agentCode: string = '';
    data.multiInput.map((singleValue: any) => {
      agentCode += singleValue.value;
    });
    const city = localStorage?.getItem('city');
    const school = localStorage?.getItem('schoolName');
    const location = localStorage?.getItem('location');

    let payLoad = {
      agentCode: agentCode,
      city: city,
      school: school,
      groupname: data.select.value,
      location: '',
      keepSignedIn: staySignedIn,
    };

    if (location && location !== 'undefined') {
      payLoad.location = location;
    }

    FunnelService.login(payLoad).then((response: any) => {
      if (response.isSuccessful) {
        localStorage?.removeItem('city');
        localStorage?.removeItem('schoolName');
        setIsDisabled(false);
        setIsLoading(false);

        const gtmObject: any = {
          event: 'Login',
          category: 'Login',
          action: 'Leraren',
          label: agentCode,
        };

        if ((window as any).dataLayer) {
          (window as any).dataLayer.push(gtmObject);
          window.location.href = props.nextStep.Url;
          return;
        }

        window.location.href = props.nextStep.Url;
      } else {
        setIsLoading(false);
        setIsCodeWrong(true);
      }
    });
  };

  const restMultiInput = () => {
    setIsCodeWrong(false);
  };
  const onCheckboxClick = useCallback(() => {
    setStaySignedIn(!staySignedIn);
  }, [staySignedIn]);

  return (
    <FormProvider {...methods}>
      <form className={classes.Wrapper} onSubmit={methods.handleSubmit(onSubmit)}>
        <div className='form-group'>
          <label>{props.autoCompleteDropDown}</label>
          <ReactSelect isSchool={false} options={groups} />
        </div>
        <div className={cx(classes.FormControlMargin)}>
          <label>{props.dropDownLabel}</label>
        </div>
        <MultiInput restMultiInput={() => restMultiInput()} isCodeWrong={isCodeWrong}></MultiInput>
        {isCodeWrong && <div className={classes.errorMessage}>{props.errorMessage}</div>}
        <div className={classes.Description}>{props.dropDownDescription}</div>
        <div className={classes.Button}>
          <Button
            type='submit'
            text={props.button.Name}
            icon='arrow'
            styling='btn-wide'
            isDisabled={isDisabled && !isLoading}
            isLoading={isLoading}></Button>
        </div>
        <div className={classes.checkboxWrapper}>
          <input
            className={classes.checkbox}
            type='checkbox'
            id='remember-login'
            name='remember-login'
            onClick={onCheckboxClick}
            defaultChecked={staySignedIn}
          />
          <label htmlFor='remember-login'>{props?.keepSignedInLabel}</label>
        </div>
      </form>
    </FormProvider>
  );
}

export default TeacherEnd;
