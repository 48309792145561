import React, { Fragment, useEffect, useRef } from 'react';

const Vimeo = ({ video, onPlay, props }: any) => {
    const iframe = useRef<any>();

    useEffect(() => {
        if (!iframe.current) {
            return;
        }

        const eventListener = (event: any) => {
            if (event.data) {
                try {
                    
                    if (event.data.event && event.data.event === 'play') {
                        onPlay();
                    }

                    const data = JSON.parse(event.data);

                    if (data.event === 'ready') {
                        const message = {
                            method: 'addEventListener',
                            value: 'play'
                        }

                        iframe.current.contentWindow.postMessage(message, 'https://player.vimeo.com')
                    }

                } catch {

                }
            }
        }

        window.addEventListener('message', eventListener);

        return () => {
            window.removeEventListener('message', eventListener);
        }
    }, [iframe.current, video])

    if (typeof window === 'undefined') {
        return <Fragment></Fragment>
    }

    return (
        <iframe ref={iframe} src={`https://player.vimeo.com/video/${video}?embedparameter=value`} {...props}>
        </iframe>
    )
}

export default Vimeo
