import classes from './Questions.module.scss';

import {useState} from 'react';

import Question from './Question/Question';
import DropDown from '../../UI/DropDown/DropDown';

import cx from 'classnames';

function Questions(props: any) {
    let updatedQuestions;
    let updatedSubjects: any;
    let subjectItems;

    if(props.isWithSubjects){
        updatedQuestions = props.questions[0].fAQCard.map((obj:any, index: number)=> ({ ...obj, isOpened: false, key: index }));;
        updatedSubjects = props.questions.map((obj:any, index: number)=> {
            if(index==0){
                return {...obj, isActive: true, key:index};
            }
            return {...obj, isActive: false, key:index};
        });
    }
    else {
        updatedQuestions = props.questions.fAQCard.map((obj:any, index: number)=> ({ ...obj, isOpened: false, key: index }));
    }

    const [questions, setQuestions] = useState(updatedQuestions);
    const [subjects, setSubjects] = useState(updatedSubjects);

    const handleEvent =  (key:number) =>{
        updatedQuestions = questions.map((question: any, index: number)=>{
            if(question.key==key){
                return {...question, isOpened: true};
            }
            return {...question, isOpened: false};
        });
        setQuestions(updatedQuestions);
    }

    const onChangeSubject = (key: number) => {
        let newQuestions;
        let newSubjects;
        let subject = subjects.find((subject: any) => subject.key === key);
        newSubjects = subjects.map((subject: any, index: number)=>{
            if(subject.key==key){
                return {...subject, isActive: true};
            }
            return {...subject, isActive: false};
        });
        newQuestions = subject.fAQCard.map((obj:any, index: number)=> ({ ...obj, isOpened: false, key: index }));
        setQuestions(newQuestions);
        setSubjects(newSubjects);
    }
    
    let questionItems = questions.map((question:any, index: number)=>{
        return <Question key={index} isOpened={question.isOpened} onClickEvent={() => handleEvent(index)} heading={question.header} body={question.body} link={question.link}></Question>
    });

    if(subjects) {subjectItems = subjects.map((subject:any, index: number)=>{
        if(subject.isActive == true){
         return <div className={classes['subject-selected']} key={index} onClick={()=>onChangeSubject(subject.key)}><i className='icon-place-board'></i>{subject.subject}</div>
        }
        else {
            return <div className={classes['subject']}  key={index} onClick={()=>onChangeSubject(subject.key)}><i className='icon-place-board'></i>{subject.subject}</div>
            }
        });
    }

    
    return (
        <div className={cx(subjectItems?classes['questions-wrapper']: classes.questions)}>
            {subjectItems?<div className={classes['questions-subjects']}>
                <div className={classes['questions-subjects-wrapper']}>
                 <h4 className={classes['subject-heading']}>{props.subjectName}</h4>
                 <div className='d-none d-lg-block'>
                    {subjectItems}
                 </div>
                 <div className='d-lg-none'>
                    <DropDown 
                        options={subjects.map((s: any) => ({ label: s.subject, value: s.key }))} 
                        placeholder={props.mobileDropdownPlaceholder}
                        isSearchable={false}
                        onChange={(e: any) => onChangeSubject(e.value)}
                    />
                 </div>
                </div>
            </div>: null}
            <div className={cx(classes[subjectItems ? 'questions-questions': ''])}>{questionItems}</div>
        </div>
    )
}    

export default Questions;