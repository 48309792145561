import { useEffect, useRef, useState } from 'react';
import Button from '../UI/Button/Button';
import GtmLink from '../UI/GtmLink/GtmLink';
import Heading from '../UI/Heading/Heading';
import { StepCard } from './StepCard/StepCard';
import classes from './Steps.module.scss';
import cx from 'classnames';

interface Props {
  bottomIllustration: {
    umbracoBytes: number;
    umbracoExtension: string;
    umbracoFile: string;
  };
  cards: {
    anchorTitle: string;
    button: {
      Name: string;
      Target: null;
      Type: number;
      Udi: string;
      Url: string;
    };
    cardImage: {
      umbracoFile: string;
      umbracoWidth: number;
      umbracoHeight: number;
      umbracoBytes: number;
      umbracoExtension: string;
    } | null;
    description: string;
    subtitle: string;
    title: string;
  }[];
  introductionSection: string;
  primaryButton: {
    Name: string;
    Target: null;
    Type: number;
    Udi: string;
    Url: string;
  };
  rightIllustration: {
    umbracoBytes: number;
    umbracoExtension: string;
    umbracoFile: string;
  };
  title: string;
  topIllustration: {
    umbracoBytes: number;
    umbracoExtension: string;
    umbracoFile: string;
  };
  color: {
    Color: string;
    Label: string;
  };
}

const Steps = (props: Props) => {
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflowX = 'clip';
    document.getElementsByTagName('html')[0].style.scrollBehavior = 'smooth';
    return () => {
      document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
      document.getElementsByTagName('html')[0].style.scrollBehavior = 'unset';
    };
  }, []);

  return (
    <div>
      <div className={classes.customContainer}>
        <div className={classes.wrapper}>
          <div className={classes.grid}>
            <div className={classes.left}>
              <img className={classes.illustrationTop} src={props?.topIllustration?.umbracoFile} alt=''></img>
              <img className={classes.illustrationBottom} src={props?.bottomIllustration?.umbracoFile} alt=''></img>
              <img className={classes.illustrationRight} src={props?.rightIllustration?.umbracoFile} alt=''></img>
              <div className={classes.background}></div>
              <div className={classes.description}>
                <Heading
                  text={props?.title}
                  type='heading-2'
                  backgroundColor={props?.color?.Label}
                  colorOfText='white'
                  isSkewed={true}></Heading>
                <div className={classes['card-paragraph']}>{props?.introductionSection}</div>
                <ul className={classes.dateList}>
                  {props?.cards.map((card, index) => {
                    return (
                      <li key={`list-${index}-${card.anchorTitle}`}>
                        <GtmLink href={`#${card.anchorTitle}`} gtmCategory='User Interaction' gtmLabel='label'>
                          <span className={classes.linkArrow}>{card?.anchorTitle}</span>
                        </GtmLink>
                      </li>
                    );
                  })}
                </ul>
                {props?.primaryButton && (
                  <GtmLink
                    href={props?.primaryButton.Url}
                    gtmCategory='User Interaction'
                    gtmAction='CTA'
                    gtmLabel={props.primaryButton.Name}>
                    <Button
                      text={props.primaryButton.Name}
                      isDisabled={false}
                      styling='yellow'
                      className={classes.descriptionButton}></Button>
                  </GtmLink>
                )}
              </div>
            </div>
            <div className={classes.right}>
              {props?.cards.map((card, index) => {
                return (
                  <div
                    className={cx(classes.stepCard, {
                      [classes.noImage]: !card.cardImage,
                    })}
                    key={`stepcard-${index}`}>
                    <StepCard {...card} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
