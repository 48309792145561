import classes from  './NavigationLink.module.scss';
import cx from 'classnames';


type NavigationLinkProps = {
    name: string,
    link: string,
    active: boolean,
    target: string
}

function NavigationLink({name, link, active, target}: NavigationLinkProps) {
  return (
        <li>
            <div className={classes['menu-item']}>
                <a className={cx(classes['navigation-link'], { [classes.active]: active })} href={link} target={target}>{name}</a>
            </div>
        </li>
    )
}

export default NavigationLink;
