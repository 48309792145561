import React, { useEffect, useState } from 'react';
import OverviewHeading from '../../UI/OverviewHeading/OverviewHeading';
import StatusMessage from '../../ParentPermission/StatusMessage/StatusMessage';
import classes from './OrderCampaignMaterialsSuccess.module.scss';
import OrderDetails from '../OrderDetails/OrderDetails';

const OrderCampaignMaterialsSuccess = ({
    title,
    subtitle,
    successText,
    successTitle,
    claims,
    detailsTitle,
    schoolLabel,
    groupLabel,
    wrongDetailsText,
    materialsTitle
}: any) => {
    const [details, setDetails] = useState<any>();
    const [formData, setFormData] = useState<{ name: string, value: string, required: boolean }[]>([]);

    useEffect(() => {
        let d: any = {};

        claims.forEach((claim: any) => {
            if (claim.Type.indexOf('Group') !== -1 && claim.Type.indexOf('GroupCode') === -1) {
                d.group = claim.Value;
            }

            if (claim.Type.indexOf('School') !== -1) {
                d.school = claim.Value;
            }
        });

        setDetails(d);

    }, [claims])

    useEffect(() => {
        let formData = sessionStorage.getItem('formData');

        if (!formData) {
            return;
        }

        sessionStorage.removeItem('formData');
        let form = JSON.parse(formData) as { name: string, value: string, required: boolean }[];
        setFormData(form);
    }, [])

    return (
        <div>
            <OverviewHeading title={subtitle} text={title} />
            <div className='component-panel panel-position'>
                <StatusMessage title={successTitle} text={successText} className={classes.status} />
                <div className={classes.details}>
                    <h3>{detailsTitle}</h3>
                    {
                        details &&
                        <OrderDetails
                            className={classes.detailsPadding}
                            items={[{
                                name: schoolLabel,
                                value: details.school
                            }, {
                                name: groupLabel,
                                value: details.group
                            },
                            ...formData.filter(f => f.required).map(f => ({ name: f.name, value: f.value }))]}
                            wrongDetailsText={wrongDetailsText}
                        />
                    }
                </div>
                <div className={classes.details}>
                    <h3>{materialsTitle}</h3>
                    {
                        formData &&
                        <OrderDetails
                            className={classes.detailsPadding}
                            showMaterials={true}
                            items={formData.filter(f => !f.required).map(f => ({ name: f.name, value: f.value }))}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default OrderCampaignMaterialsSuccess
