import React from 'react'
import classes from './LargeImage.module.scss'
import cx from 'classnames';

const LargeImage = ({image, imageLink, imageUrl}: any) => {
    return (
        <div className={classes.imageContainer}>
            <a {...(imageLink !== null && {href:imageLink.Url})} target="_blank">
                <img className="rounded img-fluid" style={{maxHeight: image.umbracoHeight, maxWidth: image.umbracoWidth}} height='auto' width='80%' src={image.umbracoFile} alt="" />
            </a>
        </div>
    )
}

export default LargeImage