import React from 'react';
import classes from './KidsPreview.module.scss';
import cx from 'classnames';
import Loader from '../../UI/Loader/Loader';
import Number from '../../UI/Number/Number';
import Blob from '../../UI/Blob/Blob';
import Button from '../../UI/Button/Button';
import GtmLink from '../GtmLink/GtmLink';

const KidsPreview = ({
    isLoading,
    total,
    totalAmountOfMoney,
    totalAmountOfOrders,
    itemsLength,
    illustration,
    salesLink,
    showArrowButton,
    scrollToForm,
    scrollToFormCallback
}: any) => {
    return (
        <div className={classes.componentWrapper}>
            <Blob className={classes.blobs} type='mySalesBlob'></Blob>
            <div className={classes.infoBoxes}>
                <div className={cx(classes.infoBox, classes.infoBoxNegativeTransform, 'text-white', classes.blueBox, { [classes.withButtonArrow]: showArrowButton })}>
                    <div className={cx(classes.infoBoxTitle, classes.colorWhite)}>{totalAmountOfMoney}</div>
                    {
                        isLoading ?
                            <Loader type='infoBoxes' /> :
                            <Number
                                className='text-nowrap'
                                value={total}
                            />
                    }
                    {
                        salesLink && showArrowButton &&
                        <div className={classes.buttonContainer}>
                            <GtmLink gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={salesLink.Name} href={salesLink.Url}>
                                <Button text='' isDisabled={false} styling='white' className={classes.buttonWithArrow} />
                            </GtmLink>
                        </div>
                    }
                </div>
                <div className={cx(classes.infoBox, classes.infoBoxPositiveTransform)}>
                    <div className={classes.infoBoxTitle}>{totalAmountOfOrders}</div>
                    {
                        isLoading ? <Loader type='infoBoxes' /> : itemsLength
                    }
                    <img className={classes.Illustration} src={illustration}></img>
                    {
                        salesLink &&
                        <div className={classes.buttonContainer}>
                            <GtmLink gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={salesLink.Name} href={`${salesLink.Url}?paperSale`}>
                                <Button text={salesLink.Name} isDisabled={false} className={classes.button} />
                            </GtmLink>
                        </div>
                    }
                    {
                        scrollToForm && scrollToFormCallback &&
                        <div className={classes.buttonContainer}>
                            <Button text={scrollToForm} isDisabled={false} className={classes.button} onClick={scrollToFormCallback} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default KidsPreview
