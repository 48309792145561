import { useEffect, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";

import Button from "../../../UI/Button/Button";
import { FunnelService, ICity, ISchool } from "../../services/FunnelService";
import AutoCompleteDropDown from "../AutoCompleteDropDown/AutoCompleteDropDown";
import ReactSelect from "../Select/Select";
import classes from "./StepOne.module.scss";

import cx from "classnames";

function StepOne(props: any) {
  const [cities, setCities] = useState<ICity[]>([]);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [isSchoolSelected, setIsSchoolSelected] = useState(false);
  const [isAutoCompleteDisabled, setisAutoCompleteDisabled] = useState(true);

  const methods = useForm();
  const watchAllFields = methods.watch();

  useEffect(() => {
    FunnelService.getCities().then((response: any) => {
      setCities(response);
      setisAutoCompleteDisabled(false);
    });
  }, []);

  const onSubmit = (data: any) => {
    const payLoad = {
      City: "Amsterdam",
      SchoolId: 10016,
      LastStep: 1,
    };

    localStorage.setItem("schoolId", data.select.id);
    localStorage.setItem("schoolName", data.select.value);
    localStorage.setItem("city", data.school);
    localStorage.setItem("location", data.select.location);

    const gtmObject: any = {
      event: "User Interaction",
      category: "User Interaction",
      action: "CTA",
      label: props.button.Name,
    };

    if ((window as any).dataLayer) {
      (window as any).dataLayer.push(gtmObject);
      window.location.href = props.nextStep.Url;
      return;
    }

    window.location.href = props.nextStep.Url;
  };

  const isOptionSelected = (city: string) => {
    if (city && city != "none") {
      FunnelService.getSchools(city).then((response) => {
        setSchools(response);
      });
    } else {
      setSchools([]);
    }
  };

  useEffect(() => {
    if (watchAllFields.select == undefined) {
      setIsSchoolSelected(false);
    } else {
      setIsSchoolSelected(true);
    }
    if (schools == null) {
      setIsSchoolSelected(false);
    }
  }, [watchAllFields]);

  return (
    <FormProvider {...methods}>
      <form
        className={classes.Wrapper}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <AutoCompleteDropDown
          isOptionSelected={isOptionSelected}
          isDisabled={isAutoCompleteDisabled}
          data={cities}
          dropDownLabel={props.autoCompleteDropDown}
          dropdownPlaceholder1={props.dropdownPlaceholder1}
        ></AutoCompleteDropDown>
        <div className={cx(classes.FormControlMargin, "form-group")}>
          <label>{props.dropDownLabel}</label>
          <ReactSelect
            isSchool={true}
            options={schools}
            dropdownPlaceholder2={props.dropdownPlaceholder2}
          />
          <div className={classes.Description}>{props.dropDownDescription}</div>
        </div>
        <div className={classes.Button}>
          <Button
            text={props.button.Name}
            icon="arrow"
            styling="btn-wide"
            isDisabled={!isSchoolSelected}
            className=""
          ></Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default StepOne;
