import { useState, useEffect, Fragment } from 'react';
import classes from './Navigation.module.scss';

import NavigationLink from './NavigationLink/NavigationLink';
import NavigationButton from './NavigationButton/NavigationButton';
import NavigationUser from './NavigationUser/NavigationUser';

import cx from 'classnames';
import { UserServices } from './services/UserServices';

const Navigation = ({
    logo,
    type,
    teachersUrl,
    studentsUrl,
    loginUrl,
    mobileLoginLabel,
    mobileMenuLabel,
    studentsUrlTitle,
    teachersUrlTitle,
    menu,
    buttons,
    links,
    claims,
    portalNavigation,
    userMenuLabel,
    logoutButton
}: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [markActiveUrl, setMarkActiveUrl] = useState<string>('');
    const [userInfo, setUserInfo] = useState<any>();

    const toggleMenu = () => {
        setIsOpen(isOpen => !isOpen);
    }

    const getHomeUrl = () => {
        let url = '';

        switch (type) {
            case 'Students':
                url = studentsUrl;
                break;
            case 'Teachers':
                url = teachersUrl;
                break;
            default:
                break;
        }

        return url;
    }

    useEffect(() => {
        let active = '';

        if (typeof window !== 'undefined') {
            active = window.location.pathname;
        }

        setMarkActiveUrl(active);

    }, [menu, setMarkActiveUrl])

    useEffect(() => {
        let userInfo: any = { isLogin: false };

        claims.forEach((claim: any) => {
            if (claim.Type.indexOf('name') !== -1) {
                userInfo.name = claim.Value;
            }

            if (claim.Type.indexOf('role') !== -1) {
                userInfo.role = claim.Value;
                userInfo.isLogin = true;
            }
        });

        if (userInfo.role === 'student') {
            UserServices.GetAgentDetails().then(result => {
                userInfo = { ...userInfo, name: result.nickName};
                setUserInfo(userInfo);
            }).catch(() => setUserInfo(userInfo));

            UserServices.onUserNicknameChange.push(user => {
                userInfo = { ...userInfo, name: user.nickName};
                setUserInfo(userInfo);
            })
        } else {
            setUserInfo(userInfo);
        }

    }, [claims])

    const getButtons = () => {
        return buttons && buttons.length !== 0 && buttons.map((button: any, index: number) => {
            if (userInfo && userInfo.isLogin && button.isLoginButton) {
                return <Fragment key={index}></Fragment>
            }

            return (
                <NavigationButton key={index} href={button.link.Url} target={button.link.Target} text={button.link.Name} styling={button.color.Label} />
            )
        })
    }

    const getMenu = () => {
        return (
            <ul className={classes.menuList}>
                {
                    menu.map((item: any, index: number) => (
                        <NavigationLink key={index} name={item.Name} link={item.Url} target={item.Target} active={markActiveUrl === item.Url} />
                    ))
                }
            </ul>
        )
    }

    return (
        <div className={classes.component}>
            <div className='container'>
                <nav>
                    <div className='d-lg-none'>
                        <div className={cx(classes.navigation, { [classes.menuOpen]: isOpen })}>
                            <div className={classes.mobileNavigation}>
                                <div>
                                    <a href={getHomeUrl()}>
                                        <img className={classes.logo} src={logo.umbracoFile} alt='Logo' />
                                    </a>
                                </div>
                                <div className={classes.mobileButtons}>
                                    {
                                        userInfo && !userInfo.isLogin &&
                                        <a className={classes.mobileLinks} href={loginUrl.Url}>
                                            <i className='icon-user'></i>
                                            <span>{mobileLoginLabel}</span>
                                        </a>
                                    }
                                    {
                                        userInfo && userInfo.isLogin &&
                                        <NavigationUser
                                            userInfo={userInfo}
                                            portalNavigation={portalNavigation}
                                            userMenuLabel={userMenuLabel}
                                            logoutButton={logoutButton}
                                        />
                                    }
                                    <a className={classes.mobileLinks} onClick={toggleMenu}>
                                        <i className={cx({ 'icon-close': isOpen, 'icon-navicon': !isOpen })}></i>
                                        <span>{mobileMenuLabel}</span>
                                    </a>
                                </div>
                            </div>
                            <div className={classes.mobileCollapse}>
                                <div className={classes.tabsNavigation}>
                                    <div className={classes.tabs}>
                                        <a className={cx(classes.tab, { [classes.activeTab]: type === 'Students' })} href={studentsUrl}>{studentsUrlTitle}</a>
                                        <a className={cx(classes.tab, { [classes.activeTab]: type === 'Teachers' })} href={teachersUrl}>{teachersUrlTitle}</a>
                                    </div>
                                    <div className={classes.tabContent}>
                                        {
                                            menu && menu.length !== 0 &&
                                            getMenu()
                                        }
                                        {
                                            buttons && buttons.length !== 0 &&
                                            <div className={classes.buttonContainer}>
                                                {getButtons()}
                                            </div>
                                        }
                                    </div>
                                    {
                                        links && links.length !== 0 &&
                                        <div className={classes.linkContainer}>
                                            {
                                                links.map((link: any, index: number) => (
                                                    <div key={index} className={cx(classes.link, { [classes.linkActive]: markActiveUrl === link.Url })}>
                                                        <a href={link.Url} target={link.Target}>{link.Name}</a>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-none d-lg-block'>
                        <div className={classes.navigation}>
                            <div className={classes.tabsDesptop}>
                                <div className={classes.tabs}>
                                    <a className={cx(classes.tab, { [classes.activeTab]: type === 'Students' })} href={studentsUrl}><span>{studentsUrlTitle}</span></a>
                                    <a className={cx(classes.tab, { [classes.activeTab]: type === 'Teachers' })} href={teachersUrl}><span>{teachersUrlTitle}</span></a>
                                </div>
                                {
                                    links && links.length !== 0 &&
                                    <div className={classes.linkContainer}>
                                        {
                                            links.map((link: any, index: number) => (
                                                <div key={index} className={cx(classes.link, { [classes.linkActive]: markActiveUrl === link.Url })}>
                                                    <a href={link.Url} target={link.Target}>{link.Name}</a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                            <div className={classes.desktopNavigation}>
                                <div>
                                    <a href={getHomeUrl()}>
                                        <img className={classes.logo} src={logo.umbracoFile} alt='Logo' />
                                    </a>
                                </div>
                                <div className={classes.desktopMenu}>
                                    {
                                        menu && menu.length !== 0 &&
                                        getMenu()
                                    }
                                    <div className={classes.buttonContainer}>
                                        {userInfo && getButtons()}
                                        {
                                            userInfo && userInfo.isLogin &&
                                            <NavigationUser
                                                userInfo={userInfo}
                                                portalNavigation={portalNavigation}
                                                userMenuLabel={userMenuLabel}
                                                logoutButton={logoutButton}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Navigation