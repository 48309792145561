import React, { useRef, useEffect, useState } from 'react';
import classes from './OverviewHeading.module.scss';
import cx from 'classnames';

type OverviewHeadingProps = {
    title: string,
    text?: any,
    textClassName? : string,
    titleClassName?: string,
}

const OverviewHeading = ({ title, text, textClassName, titleClassName } : OverviewHeadingProps) => {
    const titleRef = useRef<any>();
    const [padding, setPadding] = useState(10);

    useEffect(() => {
        const setPosition = () => {
            if (titleRef.current) {
                let height = titleRef.current.clientHeight;

                if (height <= 40 ) {
                    setPadding(10);
                } else {
                    let offset = height - 40;
                    setPadding(10 + offset);
                }
            }
        }
        
        setPosition();
        window.addEventListener('resize', setPosition)

        return () => {
            window.removeEventListener('resize', setPosition)
        }

    }, [titleRef.current, setPadding])

    return (
        (text || title) && <div className={classes.overviewHeading}>
            <div ref={titleRef} className={cx(classes.overviewHeadingTitle, titleClassName)}>{title}</div>
            {
                text &&
                <div style={ {paddingTop: padding} } className={cx(classes.overviewHeadingBody, textClassName)} dangerouslySetInnerHTML={{__html: text}}></div>
            }
        </div>
    )
}

export default OverviewHeading
