import { useState, useEffect } from 'react';
import classes from './Select.module.scss';
import { useController, Controller, useFormContext } from 'react-hook-form';

import Select from 'react-select';

  const customStyles = {
    control: (provided: any, state:any)  => ({
      ...provided,
      boxShadow: "none",
      borderColor: state.isFocused ? 'rgb(0, 159, 218) !important': 'none',
      'min-height': '56px',
      'height': 'auto'
    }),
    indicatorSeparator: () => ({display:'none'}),
    placeholder: () => ({
        fontSize: 16,
        fontWeight: 700,
        opacity: 0.5
    }),
    option: (provided: any, state:any) => ({
        padding: "16px 25px",
        color: "#252525",
        fontWeight: 700
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "8px",
      marginTop: "3px",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)",
    }),
    singleValue: ()=> ({
        color: "#252525",
        fontWeight: 700,
        width: '85%'
    })
  }

const ReactSelect = (props: any) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const { control, setValue: clearValue } = useFormContext();

  useEffect(()=>{
    if(props.options && props.options.length !== 0) {
      const newOptions = props.options.map((school:any) =>{
          return { value: school.name, label: `${school.name} ${school?.location ? '-' : ''} ${school?.location ?? ''}`, id: school.schoolNumber, location: school?.location}

      })
      setOptions(newOptions);
      setIsDisabled(false);
    }
    else {
      setOptions([]);
      setIsDisabled(true);
      setValue(null);
      clearValue("select", null)
    }
  }, [props.options])

  const onChangeHandler = (e: any) => {
    setValue(e);
  }

  return(
    <div className={classes.Wrapper}>
      {
        typeof window !== "undefined" &&
      <Controller
          control={control}
          rules={{ required: true }}
          name="select"        
          render = {
              ({ field })=> <Select
              {...field}
              className="basic-single"
              styles={customStyles}
              onChange={(e) => {field.onChange(e); onChangeHandler(e) }}
              value={value}
              classNamePrefix="react-select"
              options={options}
              placeholder={props.isSchool ? (!!props.dropdownPlaceholder2 ? props.dropdownPlaceholder2 : "Kies jouw school"): "Kies jouw groep"}
              isDisabled={isDisabled}
              menuPlacement='auto'
          ></Select>}/>
      }
    </div>
)}
export default ReactSelect;