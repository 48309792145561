import React from "react";
import classes from './HomeHeader.module.scss'
import imgBorder from '../../assets/images/border.png'
import { isMobile } from "react-device-detect";

const HomeHeader = ({logo}:any) => {
    return(
        <div className={classes.container}>
            <div className={classes.logo}>
                <img width='100%' src={logo.umbracoFile} alt="" />

            </div>
            <div className={classes.borderContainer}>
                {/* <img className={classes.imgBorder} src={imgBorder} alt="" /> */}
                {/* <img className={classes.imgBorderSecond} src={imgBorder} alt="" /> */}
            </div>
        </div>
    )
}

export default HomeHeader