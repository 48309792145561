import React from 'react'
import Button from '../../UI/Button/Button';
import classes from './NavigationButton.module.scss';
import GtmLink from '../../UI/GtmLink/GtmLink';

const NavigationButton = ({
    href,
    text,
    styling,
    target
}: any) => {
    const getStyle = (styling: any): string => {
        let style = '';

        switch (styling) {
            case 'yellow':
                style = 'yellow-noarrow';
                break;
            default:
                break;
        }

        return style;
    }

    return (
        <div className={classes.linkWrapper}>
            <GtmLink href={href} target={target} gtmCategory='User Interaction' gtmAction='CTA' gtmLabel={text}>
                <Button 
                    isDisabled={false}
                    text={text}
                    styling={getStyle(styling)}
                    className={classes.button}
                />
            </GtmLink>
        </div>
    )
}

export default NavigationButton
