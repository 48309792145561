import React from 'react';
import { ProgressPlugin } from 'webpack';
import './App.scss';

function App(props: any) {
  return (
    <div className="App">
    <header className="App-header">
      <div>{props.title}</div>
    </header>
  </div>
  );
}

export default App;
