import React from 'react';
import classes from './PaperSale.module.scss';
import OverviewHeading from '../../UI/OverviewHeading/OverviewHeading';
import Input from '../Input/OrderInput';
import Button from '../../UI/Button/Button';
import cx from 'classnames';
import { useForm } from 'react-hook-form';

const PaperSale = ({
    elementRef,
    paperSaleTitle,
    paperSaleSubtitle,
    paperSaleLabelPersonName,
    paperSalePlaceholderPersonName,
    paperSalesLabelAmount,
    paperSaleButtonText, 
    onSubmit
}: any) => {
    const { control, handleSubmit, reset } = useForm();

    const formSubmit = (result: any) => {
        onSubmit(result);
        reset({
            person: '',
            amount: 0
        });
    }

    return (
        <div ref={elementRef} className={classes.paparSale}>
            {
                paperSaleTitle &&
                <OverviewHeading title={paperSaleTitle} titleClassName={classes.paperSaleTitle} />
            }
            {
                paperSaleSubtitle &&
                <div className='row'>
                    <div className={`col-lg-8 ${classes.paperSaleSubtitle}`}>{paperSaleSubtitle}</div>
                </div>
            }
            <div className={classes.formContainer}>
                <form onSubmit={handleSubmit(result => formSubmit(result))}>
                    <div className={cx('row', classes.rowMargin)}>
                        <div className={cx('col-lg-4', classes.columnPadding)}>
                            <Input
                                control={control}
                                name='person'
                                inputClass={classes.inputPerson}
                                maxLength={30}
                                label={paperSaleLabelPersonName}
                                placeholder={paperSalePlaceholderPersonName}
                                defaultValue=''
                                rules={{
                                    required: true
                                }}
                            />
                        </div>
                        <div className={cx('col-lg-4', classes.columnPadding)}>
                            <Input
                                control={control}
                                name='amount'
                                isAllowed={(values : any) => {
                                    const { formattedValue, floatValue } = values;
                                    return formattedValue === "" || floatValue <= 9999.99;
                                }}
                                withFormating={true}
                                inputClass={classes.inputSales}
                                label={paperSalesLabelAmount}
                                fixedDecimalScale={true}
                                selectOnClick={true}
                                defaultValue={0}
                                rules={{
                                    required: true,
                                    min: 0.01
                                }}
                            />
                        </div>
                        <div className={cx('col-lg-3', classes.columnPadding)}>
                            <div className={classes.buttonContainer}>
                                <Button styling='btn-wide' icon='arrow' text={paperSaleButtonText} isDisabled={false} />
                            </div>
                        </div>
                    </div>
                </form>
                <div id="input_error_message" className={classes.inputErrorMessage}></div>
            </div>
        </div>
    )
}

export default PaperSale
