import axios, { AxiosError, AxiosResponse } from "axios";

export default class AxiosInstance {
    private static _axios = axios.create();

    static get(url: string, params?: any): Promise<any> {
        return this._axios.get(url, { params }).catch((error: AxiosError) => {
            return Promise.reject(error);
        });
    }

    static post(url: string, payLoad: any): Promise<any> {
        return this._axios.post(url, payLoad).catch((error: AxiosError) => {
            return Promise.reject(error);
        });
    }

    static postWithParameters(url: string, body?:any, payload?: any, headers?: any): Promise<any> {
        return this._axios.post(url, body, { params: payload, headers} ).catch((error: AxiosError) => {
            return Promise.reject(error);
        });
    }

    static delete(url: string, params?: any): Promise<any> {
        return this._axios.delete(url, { params }).catch((error: AxiosError) => {
            return Promise.reject(error);
        });
    }

    static setResponseInterceptors(success: (response: AxiosResponse) => any, error: (error: AxiosError) => any) {
        this._axios.interceptors.response.use(success, error);
    }
}