import React, { useEffect, useState } from 'react';
import classes from './OrderCampaignMaterials.module.scss';
import Button from '../UI/Button/Button';
import OrderDetails from './OrderDetails/OrderDetails';

const OrderCampaignMaterials = ({
    title,
    titleSectionTwo,
    claims,
    schoolLabel,
    groupLabel,
    wrongDetailsText,
    titleSectionThree,
    sendText,
    button
}: any) => {
    const [details, setDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        let d: any = {};

        claims.forEach((claim: any) => {
            if (claim.Type.indexOf('Group') !== -1 && claim.Type.indexOf('GroupCode') === -1) {
                d.group = claim.Value;
            }

            if (claim.Type.indexOf('School') !== -1) {
                d.school = claim.Value;
            }

            if (claim.Type.indexOf('name') !== -1 && claim.Type.indexOf('nameidentifier') === -1) {
                d.name = claim.Value;
            }
        });

        setDetails(d);

    }, [claims])

    const formSubmit = () => {
        let button = document.querySelector('input[type="submit"]') as HTMLButtonElement;
        let forms = document.getElementsByTagName('form');
        const formData = [];

        if (forms && forms.length !== 0) {
            let form = forms[0];
            for (let i = 0; i < form.elements.length; i++) {
                let label = forms[0].elements[i].attributes.getNamedItem('data-label');
                let name = '';
                let value = (forms[0].elements[i] as any).value;;
                let required = false;

                if (label && value) {
                    name = label.value;

                    let isRequired = forms[0].elements[i].attributes.getNamedItem('data-val-required');
                    if (isRequired) {
                        required = true
                    }

                    formData.push({ name, value, required });
                }
            }

            sessionStorage.setItem('formData', JSON.stringify(formData));
        }

        let otherDetails = document.querySelector('input[data-alias="otherDetails"]') as HTMLInputElement;

        if (otherDetails) {
            otherDetails.value = JSON.stringify(details);
        }

        if ((window as any).dataLayer) { 
            const gtmObject: any = {
                'event': 'Form',
                'category': 'Form',
                'action': 'Submit',
                'label': title
            };

            (window as any).dataLayer.push(gtmObject);
        }

        if (button) {
            button.click();

            let hasError = document.querySelectorAll('.input-validation-error');
            if (hasError && hasError.length !== 0) {
                hasError[0].scrollIntoView(false);
                return;
            }

            setIsLoading(true);
        }
    }

    return (
        <div>
            {
                titleSectionTwo &&
                <div className="section-title">
                    <span className="section-title-number">2</span>
                    <span className="section-title-text">{titleSectionTwo}</span>
                </div>
            }
            {
                details &&
                <OrderDetails
                    items={[{
                        name: schoolLabel,
                        value: details.school
                    }, {
                        name: groupLabel,
                        value: details.group
                    }]}
                    wrongDetailsText={wrongDetailsText}
                />
            }
            {
                titleSectionThree &&
                <div className="section-title">
                    <span className="section-title-number">3</span>
                    <span className="section-title-text">{titleSectionThree}</span>
                </div>
            }
            <div className={classes.panel}>
                {
                    sendText &&
                    <div className={classes.sendText}>{sendText}</div>
                }
                <Button isDisabled={isLoading} text={button} icon='arrow' onClick={formSubmit} isLoading={isLoading} />
            </div>
        </div>
    )
}

export default OrderCampaignMaterials
