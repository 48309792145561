import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { ITeacherOverviewItem } from '../../TeacherOverview/services/TeacherOverviewService';

export interface IKidDetails {
    code: string,
    name: string,
    turnover: number,
    orders: ITeacherOverviewItem[]
}

export class KidSalesService {

    static getDetails(studentCode: string): Promise<IKidDetails> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/teacher/getstudentorders', { studentCode }).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

}