import React from 'react';
import classes from  './Blob.module.scss';
import cx from 'classnames';

type BlobsProps = {
    className: string,
    type: string
}


const Blob = ( {className, type}: BlobsProps ) => {
    return (
        <div  className={cx(classes[type], className)}></div>
    )
}

export default Blob
