import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export enum Status {
    mailNontSend,
    mailSend,
    approved,
    rejected,
    blocked
}

export enum VideoStatuses
{
    notuploaded,
    uploaded,
    mailSend,
    approved,
    rejected,
    reported,
    rejectedByModerator
}

export class VideoUploadService {

    static sendEmail(payLoad: any): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters(`/umbraco/api/student/SetApprovalemailgeneralpermission`,null,  payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static getStatusOfVideo(payLoad: any): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get(`/umbraco/api/student/getvideodetail`, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }
    
    static getStatus(): Promise<Status> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get(`/umbraco/api/student/getchildstatus`).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }
    
    static postVideo(body: any, payLoad: any): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters(`/umbraco/api/student/uploadvideo`,body, payLoad,  {
                'Content-Type': 'multipart/form-data'
            }).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static getEmail(): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get(`/umbraco/api/student/getVideosApprovalEmail`).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static postEmail(payLoad: any, url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.postWithParameters(`/umbraco/api/student/setApprovalEmail${url}`,null, payLoad).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    } 

    static deleteVideo(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.delete(`/umbraco/api/student/deleteVideo`, params).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    } 

}