import React, { useEffect, useState } from 'react';
import classes from './Footer.module.scss';
import DropDown from '../UI/DropDown/DropDown';
import cx from 'classnames';

const FooterLinks = ({ index, links }: any) => {
    return (
        <ul className={classes.linksList}>
            {
                links && links.length !== 0 && links[index] && links[index].footerLink && links[index].footerLink.length !== 0 &&
                links[index].footerLink.map((link: any, i: number) => (
                    <li key={i}>
                        <a href={link.Url} target={link.Target}>
                            <i className='icon-right'></i>
                            <span>{link.Name}</span>
                        </a>
                    </li>
                ))
            }
        </ul>
    )
}

const Footer = ({
    logo,
    menu,
    links,
    socialNetworkLabel,
    socialNetwork,
    disclaimerLine,
    logoHomeLink
}: any) => {
    const [activeMenu, setActiveMenu] = useState<any>();

    const onChangeMenu = (value: any) => {
        window.location.href = value;
    }

    useEffect(() => {
        let active;

        if (typeof window !== 'undefined') {
            menu.some((item: any) => {
                if (item.Url === window.location.pathname) {
                    active = { label: item.Name, value: item.Url };
                    return true;
                }

                return false;
            })
        }

        setActiveMenu(active);

    }, [menu, setActiveMenu])

    return (
        <div className={classes.footer}>
            <div className='container'>
                <div className={classes.footerWraper}>
                    <div className={classes.footerNavigation}>
                        <div>
                            <a href={logoHomeLink.Url} target={logoHomeLink.Target}>
                                <img className={classes.logo} src={logo.umbracoFile} />
                            </a>
                        </div>
                        <div className={classes.menuContainer}>
                            <div className='d-lg-none'>
                                <DropDown
                                    type='footer'
                                    isSearchable={false}
                                    options={menu.map((l: any) => ({ label: l.Name, value: l.Url }))}
                                    onChange={(e: any) => onChangeMenu(e.value)}
                                    value={activeMenu}
                                    placeholder="Selecteer"
                                />
                            </div>
                            <div className={classes.menuDesktop}>
                                {
                                    menu.map((link: any, index: number) => (
                                        <div key={index} className={cx(classes.menuDesktopLink, { [classes.menuDesktopLinkActive]: link.Url === activeMenu?.value })}>
                                            <a href={link.Url} target={link.Target}>
                                                {link.Name}
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className={classes.linksContainer}>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <FooterLinks links={links} index={0} />
                            </div>
                            <div className='col-lg-3'>
                                <FooterLinks links={links} index={1} />
                            </div>
                            <div className='col-lg-3'>
                                <FooterLinks links={links} index={2} />
                            </div>
                            <div className='col-lg-3'>
                                {
                                    socialNetwork && socialNetwork.length !== 0 &&
                                    <div>
                                        <div className={classes.socialNetworkTitle}>{socialNetworkLabel}</div>
                                        <div className={classes.socialNetwork}>
                                            {
                                                socialNetwork.map((network: any, index: number) => (
                                                    <a key={index} href={network.link.Url} target={network.link.Target}>
                                                        {
                                                            network.icon &&
                                                            <img src={network.icon.umbracoFile} alt={network.link.name} />
                                                        }
                                                    </a>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        disclaimerLine &&
                        <div className={classes.disclaimerLine}>{disclaimerLine}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Footer
