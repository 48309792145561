import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import App from './App';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import SideBlockPragraph from './components/SideBlockParagraph/SideBlockParagraph';
import StraightCardRow from './components/StraightCardRow/StraightCardRow';
import ParagraphWithCards from './components/ParagraphWithCards/ParagraphWithCards';
import FAQ from './components/FAQ/FAQ';
import ImageVideo from './components/ImageVideo/ImageVideo';
import FunnelWrapper from './components/Funnel/FunnelWrapper';
import TeacherOverview from './components/TeacherOverview/TeacherOverview';
import KidOverview from './components/KidOverview/KidOverview';
import KidSales from './components/KidSales/KidSales';
import MyShop from './components/MyShop/MyShop';
import Steps from './components/Steps/Steps';
import Introduction from './components/VideoUpload/Introduction/Introduction';
import GeneralPermission from './components/VideoUpload/GeneralPermission/GeneralPermission';
import UploadingVideo from './components/VideoUpload/UploadingVideo/UploadingVideo';
import GlobalErrorHandler from './components/GlobalErrorHandler/GlobalErrorHandler';
import GlobalInformation from './components/GlobalInformation/GlobalInformation';
import KidsResult from './components/KidsResult/KidsResult';
import VideoUploadSteps from './components/VideoUpload/VideoUploadSteps/VideoUploadSteps';
import ParentPermission from './components/ParentPermission/ParentPermission';
import PersonalVideoWidget from './components/PersonalVideoWidget/PersonalVideoWidget';
import VideoModeratingTable from './components/VideoModerate/VideoModeratingTable/VideoModeratingTable';
import VideoTablesBase from './components/VideoModerate/VideoTablesBase/VideoTablesBase';
import VideoModerate from './components/VideoModerate/VideoModerate/VideoModerate';
import KidsNavigation from './components/KidsNavigation/KidsNavigation';
import TeachersNavigation from './components/TeachersNavigation/TeachersNavigation';
import VideoCarousel from './components/VideoCarousel/VideoCarousel';
import TimeLine from './components/TimeLine/TimeLine';
import ContentPageHeader from './components/ContentPageHeader/ContentPageHeader';
import HomePageHeader from './components/HomePageHeader/HomePageHeader';
import OverviewHeading from './components/UI/OverviewHeading/OverviewHeading';
import OrderCampaignMaterials from './components/OrderCampaignMaterials/OrderCampaignMaterials';
import OrderCampaignMaterialsSuccess from './components/OrderCampaignMaterials/Success/OrderCampaignMaterialsSuccess';
import GameSubmission from './components/GameSubmission/GameSubmission';
import ReportAsIanppropriate from './components/ReportAsInappropriate/ReportAsIanppropriate';
import HomePageOverlay from './components/HomePageOverlay/HomePageOverlay'
import HomeHeader from './components/HomeHeader/HomeHeader'
import LargeImage from './components/LargeImage/LargeImage'
import Popup from './components/Popup/Popup'
import Divider from './components/Divider/Divider'

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

(global as any).App = App;
(global as any).Navigation = Navigation;
(global as any).Footer = Footer;
(global as any).SideBlockPragraph = SideBlockPragraph;
(global as any).StraightCardRow = StraightCardRow;
(global as any).ParagraphWithCards = ParagraphWithCards;
(global as any).FAQ = FAQ;
(global as any).ImageVideo = ImageVideo;
(global as any).FunnelWrapper = FunnelWrapper;
(global as any).TeacherOverview = TeacherOverview;
(global as any).KidOverview = KidOverview;
(global as any).KidSales = KidSales;
(global as any).MyShop = MyShop;
(global as any).uitlegKaarten = Steps;
(global as any).Introduction = Introduction;
(global as any).GeneralPermission = GeneralPermission;
(global as any).UploadingVideo = UploadingVideo;
(global as any).GlobalErrorHandler = GlobalErrorHandler;
(global as any).Popup = Popup;
(global as any).KidsResult = KidsResult;
(global as any).VideoUploadSteps = VideoUploadSteps;
(global as any).ParentPermission = ParentPermission;
(global as any).PersonalVideoWidget = PersonalVideoWidget;
(global as any).KidsNavigation = KidsNavigation;
(global as any).VideoModeratingTable = VideoModeratingTable;
(global as any).VideoModerate = VideoModerate;
(global as any).TeachersNavigation = TeachersNavigation;
(global as any).VideoCarousel = VideoCarousel;
(global as any).TimeLine = TimeLine;
(global as any).HomePageHeader = HomePageHeader;
(global as any).ContentPageHeader = ContentPageHeader;
(global as any).OverviewHeading = OverviewHeading;
(global as any).OrderCampaignMaterials = OrderCampaignMaterials;
(global as any).OrderCampaignMaterialsSuccess = OrderCampaignMaterialsSuccess;
(global as any).GameSubmission = GameSubmission;
(global as any).ReportAsIanppropriate = ReportAsIanppropriate;
(global as any).VideoTablesBase = VideoTablesBase;
(global as any).GlobalInformation = GlobalInformation;
(global as any).HomePageOverlay = HomePageOverlay;
(global as any).HomeHeader = HomeHeader;
(global as any).LargeImage = LargeImage;
(global as any).Divider = Divider;