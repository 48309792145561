import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export interface ITeacherOverviewItem {
  customerName: string;
  totalOrders: number;
  turnover: number;
  NickName: string;
  IsPaperOrder: boolean;
}

export class TeacherOverviewService {
  static getOverview(): Promise<ITeacherOverviewItem[]> {
    return new Promise((resolve, reject) => {
      AxiosInstance.get('/umbraco/api/teacher/getallorders')
        .then((result: AxiosResponse) => {
          if (result.status === 200) {
            resolve(result.data);
          }
        })
        .catch((error: AxiosError) => reject(error));
    });
  }
}
