import classes from './VideoUpload.module.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { VideoStatuses, VideoUploadService } from '../../services/VideoUploadService';
import Button from '../../../UI/Button/Button';
import Loader from '../../../UI/Loader/Loader';

import cx from 'classnames';

function VideoUpload(props: any) {
  const [typeOfStatus, setTypeOfStatus] = useState<any>();
  const [fileUrl, setFileUrl] = useState<any>();
  const [file, setFile] = useState<any>();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsloading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isFileDeleting, setIsFileDeleting] = useState(false);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [waitingForApproval, setWaitingForApproval] = useState<string>("");
  const { handleSubmit, formState: { errors }, register } = useForm();
  const videoRef = useRef<any>();

  useEffect(() => {
    const object = {
      Type: props.isWelcome ? "Welcome" : "ThankYou"
    }
    VideoUploadService.getStatusOfVideo(object).then((response) => {
      setTypeOfStatus(VideoStatuses[response.status]);
      if (props.isWelcome) {
        setFileUrl(response.welcomeVideoUrl);
      }
      else {
        setFileUrl(response.thankYouVideoUrl);
      }
      const newText = props.waitingForApprovalText.replace("<parentemail>", response)
      setWaitingForApproval(newText);
      setEmail(response.email);
      if (VideoStatuses[response.status] == '3') {
        props.isApproved();
      }
    })


  }, []);

  const onChangeHandler = (event: any) => {
    setIsErrorVisible(false);
    const file=event.target.files[0];
    const fileSize= parseFloat((file.size/1024/1024).toFixed(2));
    const fileExtension = file.name.substring(file.name.lastIndexOf('.')+1)
    setIsloading(true);
    const object = {
      videoType: props.isWelcome ? "Welcome" : "ThankYou"
    }
    let formData = new FormData();
    if(fileSize<=0 || fileSize > 25 || (fileExtension!="mp4" && fileExtension!="mpv" && fileExtension!="gif" )) {
      setIsErrorVisible(true);
      setIsloading(false);
      return;
    }
    formData.append("file", file);
    VideoUploadService.postVideo(formData, object).then(response => {
      VideoUploadService.getEmail().then((response) => {
        if (response === !null) {
          setEmail(response);
        }
      })
      setFile(file);
      setFileUrl(URL.createObjectURL(file))
      setTypeOfStatus(VideoStatuses["uploaded"])
      setIsloading(false);
    })
  }

  const sendVideoHandler = (event: any) => {
    setIsVideoUploading(true);
    const object = {
      email: email ? email : event.email
    }

    const url: string = props.isWelcome ? "Welcome" : "Thankyou";
    VideoUploadService.postEmail(object, url).then(() => {
      const newText = props.waitingForApprovalText.replace("<parentemail>", email ? email : event.email)
      setWaitingForApproval(newText);
      setTypeOfStatus(VideoStatuses["mailSend"])
      setIsVideoUploading(false);

      const gtmObject: any = {
        'event': 'User Interaction',
        'category': 'User Interaction',
        'action': 'CTA',
        'label': props.buttonName,
      };

      (window as any).dataLayer.push(gtmObject);
    })

  }

  const onDeleteVideo = () => {
    setIsFileDeleting(true);
    const object = {
      videoType: props.isWelcome ? "Welcome" : "ThankYou"
    }
    VideoUploadService.deleteVideo(object).then(() => {
      setFileUrl(null);
      setFile(null);
      setTypeOfStatus(VideoStatuses["notuploaded"]);
      setIsFileDeleting(false);

      const gtmObject: any = {
        'event': 'User Interaction',
        'category': 'User Interaction',
        'action': 'CTA',
        'label': `Delete ${object.videoType}`,
      };

      (window as any).dataLayer.push(gtmObject);
    })
  }
  const onInputChange = (event: any) => {
    setEmail(event.target.value);
  }

  const buttonAction = () => {
    const gtmObject: any = {
      'event': 'User Interaction',
      'category': 'User Interaction',
      'action': 'CTA',
      'label': props.textButton,
    };

    (window as any).dataLayer.push(gtmObject);
  }

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const playVideoEvent = () => {
      const gtmObject: any = {
        'event': 'Video',
        'category': 'Video',
        'action': 'Start video',
        'label': props.title
      };

      (window as any).dataLayer.push(gtmObject);

      videoRef.current.removeEventListener('play', playVideoEvent, false);
    }

    videoRef.current.addEventListener('play', playVideoEvent, false);

    return () => {
      if(videoRef.current){
        videoRef.current.removeEventListener('play', playVideoEvent, false)
      }
    }
  }, [videoRef.current])

  return (
    <div className={classes.videoWrapper}>
      <div className={classes.videoTitle}>{props.title}</div>
      {fileUrl && !(typeOfStatus === 6) ?
        (typeOfStatus === 1 || typeOfStatus === 2 || typeOfStatus == 3) &&
        <React.Fragment>
          <form onSubmit={handleSubmit((event) => sendVideoHandler(event))}>
            <div className={classes.videoWrapper}>
              {!isFileDeleting && <div onClick={onDeleteVideo} className={classes.deleteVideo}><i className={`icon-grabage  ${classes.deleteButton}`} /></div>}
              <video ref={videoRef} className={classes.video} controls>
                <source src={fileUrl} type="video/mp4" />
              </video>
              {isFileDeleting && <Loader type="video-upload" />}
            </div>
            {typeOfStatus === 1 && <React.Fragment> <div className={classes.uploadVideoText}>Voor je de video kunt gebruiken, moeten je ouders de video nog goedkeuren.</div>
              <div className={classes.emailForm}>
                <div className={classes.inputWrapper}>
                  <input
                    value={email}
                    {...register("email",{
                      required: true,
                      pattern: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,24}$/
                    })}
                    onChange={(event) => onInputChange(event)}
                    className={classes.input}
                    placeholder={props.eMailPlaceholder}>
                  </input>
                </div>
                <Button text={props.buttonName} styling="" icon="" isDisabled={isVideoUploading} isLoading={isVideoUploading}></Button>
              </div></React.Fragment>}
              {(errors.email?.type === 'required' || errors.email?.type === 'pattern') && <div className={classes.error}>{props.wrongEmailMessage}</div>}
          </form>
        </React.Fragment> :
        (typeOfStatus === 0 || typeOfStatus === 6 || typeOfStatus === 4 || typeOfStatus == 5) && <React.Fragment>
          <input disabled={isLoading} onChange={(event) => onChangeHandler(event)} type="file" id={props.isWelcome ? "actual-button" : "actual-button-1"} name="file" className="d-none" />
          <label onClick={buttonAction} className={cx(classes.label, { [classes['label-loader']]: isLoading })} htmlFor={props.isWelcome ? "actual-button" : "actual-button-1"}>{props.textButton}</label>
          {isErrorVisible && <div className={classes.error}>{props.error}</div>}
          <div className={classes.subtitle}>{props.subtitle}</div>
        </React.Fragment>
      }
      {typeOfStatus === 2 && <div className={classes.waiting}>
        <div className={classes.titleText}>{props.waitingForApprovalTitle}</div>
        <div className={classes.paragraph} dangerouslySetInnerHTML={{ __html: waitingForApproval }}></div>
      </div>}
      {typeOfStatus === 3 && <div className={classes.approved}>
        <div className={classes.paragraph}>{props.approvedVideoText}</div>
      </div>}
      {typeOfStatus === 4 && <div className={classes.rejected}>
        <div className={classes.titleText}>{props.parentRejectedTitle}</div>
        <div className={classes.paragraph}>{props.parentRejectedText}</div>
      </div>}
      {typeOfStatus === 5 && <div className={classes.reported}>
        <div className={classes.titleText}>{props.rejectedTitle}</div>
        <div className={classes.paragraph}>{props.rejectedText}</div>
      </div>}
      {typeOfStatus === 6 && <div className={classes.rejected}>
        <div className={classes.titleText}>{props.moderatorRejectedTitle}</div>
        <div className={classes.paragraph}>{props.moderatorRejectedText}</div>
      </div>}
    </div>
  );
}

export default VideoUpload;
