import cx from 'classnames';
import classes from './StepCardHeading.module.scss';


const StepCardHeading = (props: any)  => {
    return (
           <div className={cx(classes.wrapper, {[classes.wrapperWithOptionalSubtitle]: props.optionalSubtitle}, props.isLeft ? classes.leftHeading: classes.rightHeading, {[classes.withPicture]: props.isWithPicture})}>
               {props.optionalSubtitle && <div className={classes.optionaSubtitle}>{props.optionalSubtitle}</div>}
               {props.title && <div className={cx(classes.title, {[classes.first]: props.isFirst})}>{props.title}</div>}
               {props.subtitle && <div className={cx(classes.title, {[classes.first]: props.isFirst})}>{props.subtitle}</div>}
           </div>
    )
}

export default StepCardHeading;
