import React from 'react';
import classes from './List.module.scss';

import Heading from '../../UI/Heading/Heading';
import Button from '../../UI/Button/Button';
import GtmLink from '../../UI/GtmLink/GtmLink';

import cx from 'classnames';

type List = {
  items: any;
  type: string;
  image: any;
  sideblockLink: any;
  illustration: any;
  linkListHeader: any;
  button?: any;
};

function List({ items, type, image, sideblockLink, illustration, linkListHeader, button }: List) {
  let linkItems = null;
  let imageElement = null;
  switch (type) {
    case 'orderedList':
      linkItems = items.map((item: any, index: number) => {
        return (
          <li key={index} className='d-flex'>
            <span className={cx(classes['circle-wrapper'], classes[`circle-wrapper-${index + 1}`])}>{index + 1}</span>
            <div className={classes.word}>{item}</div>
          </li>
        );
      });
      break;
    case 'unorderedList':
      linkItems = items.map((item: any, index: number) => {
        return (
          <li key={index} className='d-flex'>
            <span className={cx(classes['circle-wrapper'], classes['circle-wrapper-icon'])}>
              <i></i>
            </span>
            {item}
          </li>
        );
      });
      break;
    case 'linkList':
      linkItems = items.map((item: any, index: number) => {
        return (
          <li key={index} className='d-flex'>
            <GtmLink
              gtmCategory='User Interaction'
              gtmAction='CTA'
              gtmLabel={item.link.Name}
              href={item.link.Url}
              className={classes.link}>
              <span className={classes['icon-text']}>{item.link.Name}</span>
            </GtmLink>
          </li>
        );
      });
      break;
    case 'imageOnly':
      imageElement = (
        <GtmLink
          href={button?.Url}
          target={button?.Target}
          gtmCategory='User Interaction'
          gtmAction='CTA'
          tabIndex={-1}
          aria-hidden={true}>
          <img className={classes.image} src={image.umbracoFile} alt=''></img>
        </GtmLink>
      );
  }

  return (
    <React.Fragment>
      <div className={cx(imageElement ? classes['list-image'] : classes['list-wrapper'], 'position-relative')}>
        {illustration ? (
          <img className={classes['list-illustration']} src={illustration.umbracoFile} alt=''></img>
        ) : null}
        {linkItems ? (
          <div className={classes['list-content']}>
            <Heading
              text={linkListHeader}
              type='heading-4'
              backgroundColor=''
              colorOfText='gray-dark'
              isSkewed={false}></Heading>
            <ol className={classes['order-list']}>{linkItems}</ol>
            {sideblockLink ? (
              <GtmLink
                gtmCategory='User Interaction'
                gtmAction='CTA'
                gtmLabel={sideblockLink.Name}
                href={sideblockLink.Url}>
                <div className={classes['button-link']}>
                  <Button text='' styling='noTextArrow' icon='arrow' isDisabled={false}></Button>
                  <span className={classes['link-text']}>{sideblockLink.Name}</span>
                </div>
              </GtmLink>
            ) : null}
          </div>
        ) : (
          imageElement
        )}
      </div>
    </React.Fragment>
  );
}

export default List;
