import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import ActionButton from '../../UI/ActionButton/ActionButton';
import Number from '../../UI/Number/Number';
import Input from '../Input/OrderInput';
import { useForm } from 'react-hook-form';
import Button from '../../UI/Button/Button';
import { IEditPaperOrder } from '../services/KidOverviewService';


const OrderRow = ({ item, index, digitalOrder, paperOrder, deleteOrder, submit, onEdit, classes }: any) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const { control, handleSubmit, reset } = useForm();

    const editModeToggel = () => {
        reset({
            customerName: item.customerName,
            customerNameMobile: item.customerName,
            turnover: item.turnover,
            turnoverMobile: item.turnover,
        })
        setEditMode(editMode => !editMode);
    }

    const editSubmit = (value: any, isMobile: boolean) => {
        const editRow: IEditPaperOrder = {
            id: item.id,
            customer: isMobile ? value.customerNameMobile : value.customerName,
            personalCode: item.agentCode,
            profit: isMobile ? value.turnoverMobile : value.turnover
        }
        onEdit(editRow);
    }

    const submitTrigger = (isMobile: boolean) => {
        handleSubmit((value) => editSubmit(value, isMobile))();
    }

    return (
        <Fragment>
            <tr className={cx(classes.generalRow, 'd-none', 'd-lg-table-row')}>
                <td className={classes.numberColumn}>#{index + 1}</td>
                <td className={cx(classes.nameColumn, { [classes.editColumn]: editMode })}>
                    {
                        editMode ? 
                        <Input control={control} maxLength={30} name='customerName'  defaultValue={''} inputClass={classes.editInputBorder} /> : 
                        item.IsPaperOrder ? item.customerName : item.customerNameWithStars
                    }
                </td>
                <td>
                    {
                        !item.IsPaperOrder ?
                            <div className={classes.typeWithIcon}>
                                {digitalOrder}
                            </div>
                            :
                            <div className={classes.typePaperWithIcon}>
                                {paperOrder}
                            </div>
                    }
                </td>
                <td className={cx(classes.columnAmount, { [classes.editColumn]: editMode })}>
                    <div>
                        {
                            editMode ?
                                <Input
                                    isAllowed={(values : any) => {
                                        const { formattedValue, floatValue } = values;
                                        return formattedValue === "" || floatValue <= 9999.99;
                                    }}
                                    control={control}
                                    name='turnover'
                                    withFormating={true}
                                    defaultValue={0}
                                    fixedDecimalScale={true}
                                    prefix={'€ '}
                                    selectOnClick={true}
                                    inputClass={cx(classes.inputSales, classes.editInputBorder)}
                                />
                                :
                                <Number
                                    className={classes.turnOverColumn}
                                    value={item.turnover}
                                />
                        }
                    </div>
                </td>
                <td className={classes.columnAmount}>
                    {
                        item.IsPaperOrder &&
                        <Fragment>
                            {
                                editMode ?
                                    <Button onClick={() => submitTrigger(false)} className={classes.submitButton} text={submit} isDisabled={false} /> :
                                    <div className={classes.actionButtons}>
                                        <ActionButton onClick={editModeToggel} type='edit'><i className={`icon-edit ${classes.editButton}`}></i></ActionButton>
                                        <ActionButton onClick={() => deleteOrder(item.id)} type='delete'><i className={`icon-grabage ${classes.deleteButton}`}></i></ActionButton>
                                    </div>
                            }
                        </Fragment>
                    }
                </td>
            </tr>
            <tr className='d-lg-none'>
                <td colSpan={5}>
                    <div className={classes.mobileCell}>
                        <div className='flex-grow-1'>
                            <div>
                                <span className='font-weight-bold'>#{index + 1}</span>
                                {
                                    editMode ?
                                        <div className={classes.mobileFieldMargin}>
                                            <Input maxLength={30} control={control} name='customerNameMobile' defaultValue={''} inputClass={classes.editInputBorder} />
                                        </div> :
                                        <span className='font-weight-bold'>{item.IsPaperOrder ? item.customerName : item.customerNameWithStars}</span>
                                }
                            </div>
                            <div className={classes.mobileCellMargin}>
                                {
                                    !item.IsPaperOrder ?
                                        <div className={classes.typeWithIcon}>{digitalOrder}</div> :
                                        <div className={classes.typePaperWithIcon}>{paperOrder}</div>
                                }
                            </div>
                            <div className={classes.mobileCellAmount}>
                                {
                                    editMode ?
                                        <div className={classes.mobileFieldMargin}>
                                            <Input
                                                isAllowed={(values : any) => {
                                                    const { formattedValue, floatValue } = values;
                                                    return formattedValue === "" || floatValue <= 9999.99;
                                                }}                                          
                                                control={control}
                                                name='turnoverMobile'
                                                withFormating={true}
                                                defaultValue={0}
                                                fixedDecimalScale={true}
                                                prefix={'€ '}
                                                selectOnClick={true}
                                                inputClass={cx(classes.inputSales, classes.editInputBorder)}
                                                rules={{
                                                    required: true
                                                }}
                                            />
                                        </div>
                                        :
                                        <span>
                                            <Number
                                                value={item.turnover}
                                            />
                                        </span>
                                }
                            </div>
                            {
                                editMode &&
                                <div>
                                    <Button onClick={() => submitTrigger(true)} className={classes.submitButton} text={submit} isDisabled={false} />
                                </div>
                            }
                        </div>
                        {
                            item.IsPaperOrder && !editMode &&
                            <div className={classes.actionButtons}>
                                <ActionButton onClick={() => deleteOrder(item.id)} type='delete'><i className={`icon-grabage ${classes.deleteButton}`}></i></ActionButton>
                                <ActionButton type='edit' onClick={editModeToggel}><i className={`icon-edit ${classes.editButton}`}></i></ActionButton>
                            </div>
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    )
}

export default OrderRow
