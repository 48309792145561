import AxiosInstance from '../../../apis/axios';
import { AxiosResponse, AxiosError } from 'axios';

export interface IUser {
    accessToken: string,
    code: string,
    docent: boolean,
    groupCode: string,
    nickName: string
    personalCode: string
}

export class UserServices {

    private static _user: IUser;

    static onUserNicknameChange: Array<(user: IUser) => void> = [];

    static logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosInstance.post('/umbraco/api/authentication/logout', null).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

    static getUser(): Promise<IUser> {
        return new Promise((resolve) => {
            if (this._user) {
                resolve(this._user);
                return;
            }

            const interval = setInterval(() => {
                if (this._user) {
                    resolve(this._user);
                    clearInterval(interval);
                }
            }, 200)
        });
    }

    static setUserNickName(nickname: string) {
        this._user.nickName = nickname;

        if (this.onUserNicknameChange.length !== 0) {
            this.onUserNicknameChange.forEach((fn: (user: IUser) => void) => fn(this._user));
        }
    }

    static GetAgentDetails(): Promise<IUser> {
        return new Promise((resolve, reject) => {
            AxiosInstance.get('/umbraco/api/student/getagentdetails?timestamp='+new Date().getTime()).then((result: AxiosResponse) => {
                if (result.status === 200) {
                    this._user = result.data;
                    resolve(result.data);
                }
            }).catch((error: AxiosError) => reject(error))
        });
    }

}