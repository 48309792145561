import React from 'react';
import NumberFormat from 'react-number-format';

const Number = (props: any) => {
    return (
        <NumberFormat
            {...props}
            displayType={'text'}
            thousandSeparator={'.'}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'€ '}
            decimalSeparator={','}
        />
    )
}

export default Number
